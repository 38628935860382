import { Button, Modal } from '@mui/material'
import React, { useLayoutEffect, useState } from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const containerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "1rem",
  borderRadius: "1rem",
  height: "fit-content",
  width: "95%",
  maxWidth: "400px",
  display: "flex",
  flexDirection: "column",
  gap: "3rem"
};

const SuccessModal = ({ style, confirmButton = false, defaultTitle, title, confirmText = "Ok", isOpen, onClose, onConfirm }) => {
  const [titleText, setTitleText] = useState(defaultTitle);

  useLayoutEffect(() => {
    title && setTitleText(title);
  }, [title])

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  defaultTitle = handleTranslate("DEFAULT_TITLE");

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div style={{ ...style, ...containerStyle }}>
        <CheckCircleOutlineIcon sx={{ width: "80px", height: "80px", color: "green", margin: "0 auto" }} fontSize='large' />
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>{handleTranslate("SUCCESS")}!</h2>
        <p style={{ textAlign: "center" }}>{titleText}</p>
        {confirmButton && <Button fullWidth sx={{ margin: "0 auto" }} onClick={onConfirm}>{confirmText}</Button>}
      </div>
    </Modal>
  )
}

export default SuccessModal;