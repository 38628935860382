import { Autocomplete, Checkbox, styled, TextField } from "@mui/material";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const CustomAutocomplete = styled(Autocomplete)(({ error }) => error && ({
  "& .MuiTextField-root": {
    "label.Mui-focused": { color: "red" },
  },
  "& .MuiOutlinedInput-root": {
    "fieldset": {
      borderColor: "red"
    },
    "&:hover fieldset": {
      borderColor: "red"
    },
    "&.Mui-focused fieldset": { borderColor: "red" },
  }
}));

const SensorAutocomplete = ({ style, sensorsId, installed = false, uninstalled = false, defaultValue, value, onChange, required = false, multiple = false, limitTags = undefined, disabled = false, isUserTenant = true, error = false }) => {
  const sensors = useSelector((state) => state.sensors);
  const options = useMemo(() => {
    if (!isUserTenant) {
      return sensorsId?.map((cu) => cu.id);
    } else if (sensorsId) {
      return sensorsId;
    } else if (installed) {
      return sensors.installed;
    } else if (uninstalled) {
      return sensors.uninstalled;
    } else {
      return Object.keys(sensors.list);
    }
  }, [isUserTenant, sensorsId, installed, uninstalled]);
  const [val, setValue] = useState(defaultValue !== undefined ? defaultValue : multiple ? [] : null)

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const getSensorLabel = (option) => {
    if (isUserTenant) return sensors.list[option]?.serial || "";
    else return sensorsId.find((sensor) => sensor.id === option).serial || "";
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <CustomAutocomplete
      id="sensor-autocomlete"
      error={error}
      disabled={disabled}
      sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      limitTags={limitTags}
      onChange={(_, selected) => handleChange(selected)}
      options={options}
      value={val}
      getOptionLabel={(option) => getSensorLabel(option)}
      renderInput={(params) => <TextField {...params} label={required ? `${handleTranslate('SENSORS', true)}*` : `${handleTranslate('SENSORS', true)}`} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && <Checkbox checked={selected} />}
          {getSensorLabel(option)}
        </li>
      )}
    />
  )
}

export default SensorAutocomplete;