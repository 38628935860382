import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Configurazione",
    STATION_CREATION_SUCCESS : "Stazione creata con successo.",
    CONTROL_UNIT_CREATION_SUCCESS : "Centralina creata con successo.",
    SENSOR_CREATION_SUCCESS : "Sensore creato con successo.",
    STATION_UPDATE_SUCCESS : "Stazione aggioranata con successo.",
    CONTROL_UNIT_UPDATE_SUCCESS : "Centralina aggioranata con successo.",
    SENSOR_UPDATE_SUCCESS : "Sensore aggioranato con successo.",
    EXIT_WARNING : "Se esci senza salvare le modifiche andranno perse.",
    NO_INSTALLED_DEVICE: "Nessuna unità installata",
    INSTALLATION_DATE: "Data di installazione",
    CONFIGURE: "Configura",
    INSTALL : "Installa",
    UNINSTALL : "Disinstalla",
    REMOVE: "Rimuovi",
    POPUP_WARNING_DELETE_STATION : "Sei sicuro di voler eliminare la stazione",
    POPUP_ERROR_DELETE_STATION_1 : "Impossibile eliminare la stazione",
    POPUP_ERROR_DELETE_STATION_2 : "Ci sono unità installate su di essa",
    POPUP_WARNING_DELETE_CONTROL_UNIT : "Sei sicuro di voler eliminare la centralina",
    POPUP_ERROR_DELETE_CONTROL_UNIT_1 : "Impossibile eliminare la centralina",
    POPUP_ERROR_DELETE_CONTROL_UNIT_2 : "La centralina è installata su una stazione o ci sono sensori installati su di essa",
    POPUP_WARNING_UNINSTALL_CONTROL_UNIT : "Sicuro di voler disinstallare la centralina",
    POPUP_WARNING_DELETE_SENSOR : "Sei sicuro di voler eliminare il sensore",
    POPUP_ERROR_DELETE_SENSOR_1 : "Impossibile eliminare il sensore",
    POPUP_ERROR_DELETE_SENSOR_2 : "Il sensore è installato su una unità",
    POPUP_WARNING_UNINSTALL_SENSOR : "Sicuro di voler disinstallare il sensore",
    POPUP_WARNING_REMOVE_MEASURE : "Sicuro di voler rimuovere la misura",
    DEVICE_ALREADY_INSTALLED : "Questo device è già installato su questa unità",
    NEW_STATION: "Nuova stazione",
    NEW_CONTOROL_UNIT: "Nuova centralina",
    NEW_SENSOR: "Nuovo sensore",
    NEW_MEASURE: "Nuova misura",
    ADD_ON: "Aggiungi su",
    SELECT_UNIT: "Seleziona una unità",
};

const CONFIGURATION_IT = createLanguageObject(IT, viewNames.CONFIGURATION_VIEW);

export default CONFIGURATION_IT;