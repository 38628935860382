import { useEffect, useLayoutEffect, useState } from "react";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import LocationSelect from "./LocationSelect";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { sharingComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const LocationInput = ({ defaultPosition = null, position = undefined, positionName, lat, lon, latError = false, lonError = false, onSave, onChangePosition, onChangePositionName, onChangeLat, onChangeLon, saveButton = true, locationSelect = true, hidePositionName = false, disabled = false }) => {
    const [pos, setPosition] = useState(defaultPosition);
    const [name, setPositionName] = useState(positionName !== undefined ? positionName : "");
    const [latitude, setLatitude] = useState(lat !== undefined ? lat : "");
    const [longitude, setLongitude] = useState(lon !== undefined ? lon : "");
    const [latitudeError, setLatError] = useState(latError);
    const [longitudeError, setLonError] = useState(lonError);

    const [isAddingLocation, setAddingLocation] = useState(!locationSelect);
    const [canSave, setCanSave] = useState(false);

    const { t } = useTranslation("evocs");
    const handleTranslate = (textId, general = false) => {
        if (general) return translate(t, textId);
        return translate(t, textId, sharingComponentNames.LOCATION_INPUT_COMPONENT);
    };

    useLayoutEffect(() => {
        if (name && latitude && longitude) setCanSave(true);
        else setCanSave(false);
    }, [name, latitude, longitude])

    useLayoutEffect(() => {
        position !== undefined && setPosition(position);
    }, [position]);

    useLayoutEffect(() => {
        positionName !== undefined && setPositionName(positionName);
    }, [positionName]);

    useLayoutEffect(() => {
        lat !== undefined && setLatitude(lat);
    }, [lat]);

    useLayoutEffect(() => {
        lon !== undefined && setLongitude(lon);
    }, [lon]);

    useLayoutEffect(() => {
        setLatError(latError);
    }, [latError]);

    useLayoutEffect(() => {
        setLonError(lonError);
    }, [lonError]);


    const handleChangePosition = (posValue) => {
        setPosition(posValue);
        onChangePosition && onChangePosition(posValue);
    };

    const handleChangePositionName = (nameValue) => {
        setPositionName(nameValue);
        onChangePositionName && onChangePositionName(nameValue);
    };

    const handleChangeLatitude = (latValue) => {
        setLatitude(Number(latValue) || latValue);
        onChangeLat && onChangeLat(Number(latValue) || latValue);
    };

    const handleChangeLongitude = (lonValue) => {
        setLongitude(Number(lonValue) || lonValue);
        onChangeLon && onChangeLon(Number(lonValue) || lonValue);
    };


    const handleAddNewLocation = () => {
        setAddingLocation((prev) => !prev);
    };

    const handleSave = () => {
        if (typeof latitude !== "number") {
            setLatError(true);
            return;
        } else setLatError(false);
        if (typeof longitude !== "number") {
            setLonError(true);
            return;
        } else setLonError(false);;
        setAddingLocation(false);
        setCanSave(false);
        if (onSave) onSave();
        else {
            setPositionName("");
            setLatitude(""),
                setLongitude("");
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {locationSelect &&
                <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                    <LocationSelect disabled={disabled} value={pos} onChange={(value) => handleChangePosition(value)} />
                    <Tooltip title={handleTranslate('ADD_NEW_LOCATION')} disableHoverListener={disabled}>
                        <span>
                            <IconButton sx={{ color: isAddingLocation && "black" }} disabled={disabled} onClick={handleAddNewLocation}><AddLocationAltIcon fontSize="large" /></IconButton>
                        </span>
                    </Tooltip>
                </div>
            }
            {isAddingLocation &&
                <>
                    {!hidePositionName &&
                        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                            <span style={{ minWidth: "100px" }}> {handleTranslate('LOCATION_NAME')} </span>
                            <TextField
                                disabled={disabled}
                                required
                                className="rounded-2xl"
                                value={name}
                                onChange={(e) => handleChangePositionName(e.target.value)}
                                placeholder={handleTranslate('ENTER_LOCATION_NAME')}
                            />
                        </div>
                    }
                    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                        <span style={{ minWidth: "100px" }}> {handleTranslate('MEASURE_LAT', true)}</span>
                        <TextField
                            disabled={disabled}
                            error={latitudeError}
                            required
                            className="rounded-2xl"
                            value={latitude}
                            onChange={(e) => handleChangeLatitude(e.target.value)}
                            placeholder={handleTranslate('ENTER_LATITUDE')}
                            helperText={latitudeError && handleTranslate('ENTER_VALID_COORDINATES')}
                        />
                    </div>
                    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                        <span style={{ minWidth: "100px" }}> {handleTranslate('MEASURE_LON', true)} </span>
                        <TextField
                            disabled={disabled}
                            error={longitudeError}
                            required
                            className="rounded-2xl"
                            value={longitude}
                            onChange={(e) => handleChangeLongitude(e.target.value)}
                            placeholder={handleTranslate('ENTER_LONGITUDE')}
                            helperText={longitudeError && handleTranslate('ENTER_VALID_COORDINATES')}
                        />
                    </div>
                    {saveButton && <Button sx={{ width: "fit-content" }} disabled={!canSave} onClick={handleSave}> {handleTranslate('SAVE', true)} </Button>}
                </>
            }

        </div>

    );
}

export default LocationInput;