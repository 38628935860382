import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const LocationSelect = ({ value = undefined, defaultValue = undefined, onChange, required = false, multiple = false, disabled = false }) => {
    // const [options, setOptions] = useState(locations ? Object.keys(locations) : [])
    const locations = useSelector((state) => state.configuration.constants.base_location);
    const options = useMemo(() => {
        if (!locations) return [];
        return Object.keys(locations);
    }, [locations]);
    const [val, setValue] = useState(defaultValue ? defaultValue : multiple ? [] : null);

    useEffect(() => {
        value !== undefined && setValue(value);
    }, [value])

    const handleChange = (newValue) => {
        if (onChange) onChange(newValue);
        else setValue(newValue);
    };


    const { t } = useTranslation("evocs");
    const handleTranslate = (textId, general = false) => {
        if (general) return translate(t, textId);
        return translate(t, textId, viewNames.DASHBOARD_VIEW);
    };


    return (
        <Autocomplete
            sx={{ width: "300px" }}
            disabled={disabled}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            onChange={(_, select) => handleChange(select)}
            options={options}
            value={val}
            getOptionLabel={(selected) => multiple ? selected.map((locationId) => locations[locationId]?.name).join(", ") : locations[selected]?.name}
            renderInput={(params) => <TextField {...params} label={required ? `${handleTranslate('POSITION', true)}*` : `${handleTranslate('POSITION', true)}*`} />}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {multiple && <Checkbox checked={selected} />}
                    {locations[option]?.name}
                </li>
            )}
        />
    )
}

export default LocationSelect;