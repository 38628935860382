import { Grid, Paper } from "@mui/material";
import { getMaxMinData } from "app/store/evocsSlice/dataset/datasetActions";
import {
  getLastStationDetections,
  getStationMeasures,
} from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LoadingGif from "../FileManager/LoadingGif";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { formatDate } from "../StationHandling/utils";
import { TIME_FORMAT } from "../StationHandling/utils";
import SelectSomething from "../SharingComponents/SelectSomething";
import SwitchDisplayStack from "./SwitchDisplayStack";
import TableComponent from "../SharingComponents/TableComponent";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";


const MeasureCard = ({
  measure = null,
  min = null,
  max = null,
  average = null,
  median = null,
  last = null,
}) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
     if (general) return translate(t, textId);
     return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  return (
    <Paper sx={{ padding: "1rem", height: "100%" }}>
      <h3 style={{ marginBottom: "1rem"}}> {handleTranslate(`MEASURE_${measure.toUpperCase()}`, true)} </h3>
      {min || max || average || median || last ? (
        <Grid container spacing={1}>
          {min !== null && (
            <Grid item xs={12} sm={6}>
              <span>
                <b>Min:</b> {min}
              </span>
            </Grid>
          )}
          {max !== null && (
            <Grid item xs={12} sm={6}>
              <span>
                <b>Max:</b> {max}
              </span>
            </Grid>
          )}
          {average !== null && (
            <Grid item xs={12}>
              <span>
                <b> {handleTranslate('AVERAGE', true)}:</b> {average}
              </span>
            </Grid>
          )}
          {median !== null && (
            <Grid item xs={12}>
              <span>
                <b> {handleTranslate('MEDIAN', true)}:</b> {median}
              </span>
            </Grid>
          )}
          {last !== null && (
            <Grid item xs={12}>
              <span>
                <b>{handleTranslate('LAST_RELEVATION', true)}:</b> {last}
              </span>
            </Grid>
          )}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloudOffIcon fontSize="medium" />
          <span style={{ fontSize: "1.5rem" }}> {handleTranslate('NO_DATA', true)}</span>
        </div>
      )}
    </Paper>
  );
};

const StationInfoChip = ({ stationId }) => {
  const user = useSelector((state) => state.user);
  const stations = useSelector((state) => state.stationsMongo.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector(
    (state) => state.configuration.constants.measure
  );
  const sensorsType = useSelector(
    (state) => state.configuration.constants.sensor_type
  );
  const [loadingMinMax, setLoadingMinMax] = useState(false);
  const [maxMinMeasures, setMaxMinMeasures] = useState({});

  const [tableData, setTableData] = useState([]);
  const [tabularDisplay, setTabularDisplay] = useState(true);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const columns = {
    measure: handleTranslate("VALUE", true),
    min: "Min",
    max: "Max",
    avg: handleTranslate("AVERAGE", true),
    category: handleTranslate("CATEGORY", true),
    last: handleTranslate("LAST_RELEVATION", true),
  };

  useEffect(() => {
    if (!stationId) return;
    setLoadingMinMax(true);
    let maxMin = {};
    const end = new Date().valueOf();
    const start = end - 3600000 * 24;
    const calls = [
      getMaxMinData(start, end, [stationId]).then((data) => {
        Object.keys(data).forEach((sensorId) => {
          Object.keys(data[sensorId]).forEach((measureId) => {
            const typeId = sensors[sensorId].sensor_type;
            maxMin[typeId] = {
              ...maxMin[typeId],
              [measureId]: {
                ...maxMin[typeId]?.[measureId],
                ...data[sensorId][measureId],
              },
            };
          });
        });
      }),
      getLastStationDetections([stationId], user.tenantId).then((data) => {
        const station = data[stationId];
        Object.keys(station).forEach((sensorId) => {
          const time = formatDate(
            new Date(station[sensorId].time * 1000),
            TIME_FORMAT.COMPLETE_DATE
          );
          delete station[sensorId].time;
          Object.keys(station[sensorId]).forEach((measureId) => {
            const typeId = sensors[sensorId].sensor_type;
            maxMin[typeId] = {
              ...maxMin[typeId],
              [measureId]: {
                ...maxMin[typeId]?.[measureId],
                last: `${station[sensorId][measureId]} (${time})`,
              },
            };
          });
        });
      }),
    ];

    Promise.all(calls).then(() => {
      setMaxMinMeasures(maxMin);
      setLoadingMinMax(false);
    });
  }, [stationId]);

  useEffect(() => {
    if (!tabularDisplay) return;
    const data = [];
    Object.keys(maxMinMeasures).forEach((typeId) =>
      Object.keys(maxMinMeasures[typeId]).forEach((measureId) => {
        data.push({
          measure: handleTranslate(`MEASURE_${measures[measureId]?.measure.toUpperCase()}`, true),
          min: maxMinMeasures[typeId][measureId].min,
          max: maxMinMeasures[typeId][measureId].max,
          avg: maxMinMeasures[typeId][measureId].avg,
          category: handleTranslate(`SENSOR_TYPE_${sensorsType[typeId]["sensor_type"].toUpperCase()}`, true),
          last: maxMinMeasures[typeId][measureId].last,
        });
      })
    );
    setTableData(data);
  }, [tabularDisplay, maxMinMeasures, t]);

  const stationMeasures = useMemo(() => {
    if (!stationId) return [];
    return getStationMeasures(stationId);
  }, [stationId]);


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ marginBottom: "1rem" }}>
          {stations[stationId]?.serial ? stations[stationId]?.serial : ""}
        </h2>
        <SwitchDisplayStack
          tabular={tabularDisplay}
          onChange={setTabularDisplay}
        />
      </div>
      {stationId ? (
        stationMeasures.length > 0 ? (
          loadingMinMax ? (
            <LoadingGif />
          ) : !tabularDisplay ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
                paddingBottom: "2rem",
              }}
            >
              {Object.keys(maxMinMeasures).map((typeId) => (
                <div key={typeId}>

                  <h3 style={{ marginBottom: "1rem", marginTop: "1rem" }}><strong>{handleTranslate(`SENSOR_TYPE_${sensorsType[typeId]["sensor_type"].toUpperCase()}`, true)}</strong></h3>
                  <Grid container spacing={1}>
                    {Object.keys(maxMinMeasures[typeId]).map((measureId) => (
                      <Grid key={measureId} item xs={12} sm={6} md={4} lg={3}>
                        <MeasureCard
                          measure={measures[measureId]["measure"]}
                          min={maxMinMeasures[typeId][measureId]?.min}
                          max={maxMinMeasures[typeId][measureId]?.max}
                          average={maxMinMeasures[typeId][measureId]?.avg}
                          median={maxMinMeasures[typeId][measureId]?.median}
                          last={maxMinMeasures[typeId][measureId]?.last}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          ) : (
            <TableComponent
              style={{ overflowY: "visible" }}
              disabledSearch
              data={tableData}
              columns={columns}
              rowsForPage={10}
              paper={false}
            />
          )
        ) : (
          <span>{handleTranslate("NO_SENSORS_INSTALLED", true)}</span>
        )
      ) : (
        <SelectSomething text={handleTranslate("SELECT_STATION", true)} />
      )}
    </div>
  );
};

export default StationInfoChip;
