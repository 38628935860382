import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createSensor } from "app/store/evocsSlice/sensors/sensorsActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import SensorTypeSelect from "../SharingComponents/Inputs/SensorTypeSelect";
import MeasureAutocomplete from "../SharingComponents/Inputs/MeasureAutocomplete";
import SensorParentSelect from "../SharingComponents/Inputs/SensorParentSelect";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import ThresholdCard from "../SharingComponents/ThresholdCard";
import LoadingGif from "../FileManager/LoadingGif";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";



const ThresholdsConfiguration = ({ thresholds, disabled = false, measuresOptions, measuresValue, onChangeOptions, onChangeValues }) => {
  const [thresholdsValues, setThresholds] = useState({});
  const [options, setOptions] = useState([]);
  const [measures, setMeasures] = useState([]);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  useEffect(() => {
    thresholds && setThresholds(thresholds);
  }, [thresholds])

  useEffect(() => {
    measuresValue && setMeasures(measuresValue);
  }, [measuresValue])

  useEffect(() => {
    handleChangeOptions(measuresValue?.filter((measure) => measuresOptions?.includes(measure)), false);
    measuresOptions && setOptions(measuresOptions);
  }, [measuresOptions])

  useEffect(() => {
    const newThresholds = { ...thresholdsValues };
    const filterMeasures = Object.keys(newThresholds).filter((measureId) => !measures.includes(measureId));
    if (filterMeasures.length > 0) {
      filterMeasures.forEach((measureId) => delete newThresholds[measureId]);
      setThresholds(newThresholds);
      onChangeValues && onChangeValues(newThresholds);
    }
  }, [measures])


  const handleChangeOptions = (value, changed = true) => {
    setMeasures(value);
    onChangeOptions && onChangeOptions(value, changed);
  };

  const handleChangeValues = (measureId, key, value) => {
    const newThresholds = { ...thresholdsValues, [measureId]: { ...thresholdsValues[measureId], [key]: value } };
    setThresholds(newThresholds);
    onChangeValues && onChangeValues(newThresholds);
  };


  return (
    <AccordionComponent title={handleTranslate('THRESHOLDS_SETTINGS')}>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginBottom: "1rem" }}>
        <label style={{ minWidth: "100px" }}>{handleTranslate('SET_THRESHOLDS_ON')}</label>
        <MeasureAutocomplete multiple limitTags={2} disabled={disabled} measuresId={options} value={measures} onChange={handleChangeOptions} />
      </div>
      <Grid container spacing={1}>
        {measures?.map((measureId, index) => (
          <Grid key={measureId} item xs={12} sm={6} md={4}>
            <ThresholdCard enableUpdate={!disabled} withUpdateIcons={false} defaultLocked={false} measureId={measureId} min={thresholdsValues[measureId]?.min} max={thresholdsValues[measureId]?.max} onChange={handleChangeValues} />
          </Grid>
        ))}
      </Grid>
    </AccordionComponent>
  )
}

const requiredInputs = ["serial", "owner", "available", "type", "measures"];

const FormCreation = ({ onPending, onSuccess, onChangeInputs }) => {
  const [inputs, setInputs] = useState({
    serial: "",
    owner: "",
    available: false,
    type: "",
    parentType: "",
    cuParent: null,
    stationParent: null,
    measures: "",
    thresholdMeasures: [],
    thresholdValues: {}
  });
  const [changedInputs, setChangedInputs] = useState([]);
  const [disableConfirm, setDisableConfirm] = useState(true);


  useEffect(() => {
    const disabled = Object.keys(inputs).some((key) => (requiredInputs.includes(key) && (inputs[key] === "" || inputs[key]?.length === 0)));
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs]);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setChangedInputs(prev => [...prev, key]);
  };

  const handleConfirm = () => {
    onPending();
    let cuParent = null, stationParent = null;
    if (inputs.parentType === "cu") cuParent = inputs.cuParent;
    else stationParent = inputs.stationParent;
    createSensor(inputs.serial, inputs.owner, inputs.type, inputs.measures, inputs.available, stationParent, cuParent, inputs.thresholdValues).then(() => onSuccess());
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <div style={{ display: "flex", gap: "5rem", alignItems: "center", justifyContent: "space-between" }}>
        <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_SENSOR")} </h2>
        <SwitchComponent label={handleTranslate("ACTIVE", true)} defaultChecked={inputs.available} checked={inputs.available} onChange={(value) => handleChangeInput("available", value)} />
      </div>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={handleTranslate("SERIAL", true)}
        required
        className="rounded-2xl"
        value={inputs.serial}
        onChange={(e) => handleChangeInput("serial", e.target.value)}
      />
      <TenantSelect required value={inputs.owner} onChange={(value) => handleChangeInput("owner", value)} />
      <SensorTypeSelect required value={inputs.type} onChange={(value) => handleChangeInput("type", value)} />
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <span style={{ minWidth: "100px" }}> {handleTranslate("INSTALL_ON", true)} </span>
        <SensorParentSelect typeValue={inputs.parentType} stationValue={inputs.stationParent} cuValue={inputs.cuParent}
          onChangeStation={(value) => handleChangeInput("stationParent", value)} onChangeCu={(value) => handleChangeInput("cuParent", value)} onChangeType={(value) => handleChangeInput("parentType", value)} />
      </div>
      <MeasureAutocomplete required multiple values={inputs.measures} onChange={(value) => handleChangeInput("measures", value)} />
      <ThresholdsConfiguration thresholds={inputs.thresholdValues} measuresOptions={inputs.measures} measuresValue={inputs.thresholdMeasures}
        onChangeOptions={(value, changed) => handleChangeInput("thresholdMeasures", value, changed)} onChangeValues={(value) => handleChangeInput("thresholdValues", value)} />
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={disableConfirm} onClick={handleConfirm}>{handleTranslate('CONFIRM', true)}</Button>
    </div>
  );
}

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};


const CreateSensorModal = ({ isOpen = false, onSuccess, onClose }) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);

  useEffect(() => {
    !isOpen && setChangedInputs([]);
  }, [isOpen])

  const handleSuccess = () => {
    setUpdateLoading(false);
    onSuccess && onSuccess();
  }

  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  }

  return (
    <Modal open={isOpen} disablePortal>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormCreation onChangeInputs={setChangedInputs} onPending={() => setUpdateLoading(true)} onSuccess={handleSuccess} />
          {updateLoading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

export default CreateSensorModal;
