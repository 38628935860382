import { styled, TextField } from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
// import 'dayjs/locale/de';
// import 'dayjs/locale/en-gb';
// import 'dayjs/locale/zh-cn';

const DatePicker = styled(DesktopDateTimePicker)((props) => {
  return props.error && {
    "& .MuiOutlinedInput-root": {
      "fieldset": {
        borderColor: "red"
      },
      "&:hover fieldset": { 
        borderColor: "red"
      },
      "&.Mui-focused fieldset": { borderColor: "red" },
    }
  }
});


const DateTimeInput = ({value, onChange, error=false, minDate, onError}) => {
  const [date, setDate] = useState(value ? dayjs(value) : null);

  useEffect(() => {
    setDate(value ? dayjs(value) : null)
  }, [value])

  const handleChangeInput = (newValue) => {
    setDate(newValue);
    onChange && onChange(newValue);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='it'>
      <DatePicker 
      error={error}
      value={date} 
      // views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
      onChange={handleChangeInput}
      onError={onError}
      minDateTime={minDate ? dayjs(minDate): null}
      />
    </LocalizationProvider>
  )
}

export default DateTimeInput