import { Button, Grid, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import CreateTenantModal from "../Modals/CreateTenantModal";
import { deleteTenant, getTenantsProPic, updateTenant } from "app/store/evocsSlice/tenants/tenantsActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import DeleteModal from "../Modals/WarningModal";
import EditCard from "../SharingComponents/EditCard";
import AvatarImage from "../SharingComponents/Inputs/AvatarImage";
import LoadingGif from "../FileManager/LoadingGif";
import SuccessModal from "../Modals/SuccessModal";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";

const SingleTenant = ({ tenantId, onClose, onChange, onDelete, disableEdit, disableDelete }) => {
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const [edit, setEdit] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [isDeletedModalOpen, setIsDeletedModalOpen] = useState(false);
  const tenantsSelectList = useMemo(() => Object.keys(tenants).filter((id) => id !== tenantId), [])
  const [defaultTenantLogo, setDefaultTenantLogo] = useState("");
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [tenant, setTenant] = useState()

  const getDefaultTenant = useCallback(async () => {
    let image;
    if (!defaultTenantLogo || defaultTenantLogo === "") {
      setLoadingLogo(true);
      image = await getTenantsProPic([tenantId]).then((res) => {
        setLoadingLogo(false);
        if (res?.proPic !== "") {
          return "data:image/png;base64," + res.proPic
        } else {
          return "";
        }
      });
    } else {
      image = defaultTenantLogo;
    }
    return {
      name: tenants[tenantId]?.name || "",
      headquarter: tenants[tenantId]?.sede || "",
      sharingTenants: tenants[tenantId]?.sharingTenants || [],
      image: image
    }
  }, [tenantId, defaultTenantLogo])

  useEffect(() => {
    getDefaultTenant().then((res) => {
      setTenant(res);
      setDefaultTenantLogo(res.image)
    });
  }, [tenantId])


  const handleChange = (key, value) => {
    const newTenant = { ...tenant, [key]: value };
    setTenant(newTenant);
    onChange && onChange(newTenant);
    setDisableSave(false);
  };

  const handleSave = () => {
    const imageWithoutPrefix = tenantLogo.substring(tenantLogo.indexOf(',') + 1);
    updateTenant(tenantId, tenant.name, tenant.headquarter, tenant.sharingTenants, imageWithoutPrefix).then(() => {
      setEdit(false);
    })
    setDisableSave(true);
  };

  const handleDelete = () => {
    deleteTenant(tenantId).then(() => {
      onDelete && onDelete(tenantId)
    })
  };

  const handleCancelChanges = () => {
    setDisableSave(true);
    getDefaultTenant().then((res) => {
      setTenant(res);
    });
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <>
      <EditCard title={tenants[tenantId]?.name} disableSave={disableSave} disableEdit={disableEdit} disableDelete={disableDelete} onSave={handleSave} isClosable={user.selectedTenant === tilebytesTenantId} onChangeEdit={setEdit} onDelete={() => setIsDeletedModalOpen(true)} onClose={() => onClose(tenantId)} onCancelChanges={handleCancelChanges}>
        {!loadingLogo ?
          <div style={{ display: "flex", gap: "1.5rem", justifyContent: "space-around" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem", maxWidth: "50%" }}>
              <TextField sx={{ width: '100%' }} disabled={!edit} label={handleTranslate("NAME", true)} variant="outlined" value={tenant.name} onChange={(e) => handleChange("name", e.target.value)} />
              <TextField sx={{ width: '100%' }} disabled={!edit} label={handleTranslate("HEADQUARTER", true)} variant="outlined" value={tenant.headquarter} onChange={(e) => handleChange("headquarter", e.target.value)} />
              {user.tenantId === tilebytesTenantId && <TenantSelect multiple disabled={!edit} tenantsList={tenantsSelectList} value={tenant.sharingTenants} onChange={(value) => handleChange("sharingTenants", value)} />}
            </div>
            <div>
              {!loadingLogo ?
                <AvatarImage style={{ objectFit: "contain" }} disabled={!edit} value={tenant.image} onChange={(value) => handleChange("image", value)} /> :
                <LoadingGif disableLogo />}
            </div>
          </div>
          :
          <LoadingGif />
        }
      </EditCard>
      <DeleteModal isOpen={isDeletedModalOpen} onClose={() => setIsDeletedModalOpen(false)} onConfirm={handleDelete} defaultTitle={handleTranslate("WARNING_DELETE_TENANT")} />
    </>
  )
}

const TenantsInfo = ({ enabledActions, selectedTenants, onCloseTenant, onDeleteTenant }) => {

  const canEdit = () => {
    if (enabledActions.includes("manage-tenant") || enabledActions.includes("update-info-tenant")) return true;
    return false;
  }

  return (
    <Grid container spacing={1}>
      {selectedTenants.map((tenantId, index) => (
        <Grid key={tenantId} item xs={12} md={6}>
          <SingleTenant key={tenantId} disableEdit={!canEdit()} disableDelete={!enabledActions.includes("manage-tenant")} tenantId={tenantId} onClose={onCloseTenant} onDelete={onDeleteTenant} />
        </Grid>
      ))}
    </Grid>
  );
}

const TenantsConfiguration = ({ enabledActions, selectedTenants, handleCloseTenant }) => {
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);


  const handleDeleteTenant = (tenantId) => {
    handleCloseTenant(tenantId)
  };

  const handleConfirmCreateTenant = () => {
    setIsModalOpen(false);
    setSuccessModal(true);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  return (
    <div>
      <Box sx={{ width: "100%", padding: "1rem" }}>
        {user.selectedTenant === tilebytesTenantId && enabledActions.includes("manage-tenant") &&
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <Button sx={{ marginLeft: "auto" }} onClick={() => setIsModalOpen(true)}>+ {handleTranslate('NEW_TENANT')} </Button>
          </div>
        }
        <TenantsInfo enabledActions={enabledActions} selectedTenants={selectedTenants} onCloseTenant={handleCloseTenant} onDeleteTenant={handleDeleteTenant} />
      </Box>
      <CreateTenantModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleConfirmCreateTenant} />
      <SuccessModal title={"Tenant creato con successo!"} isOpen={successModal} onClose={() => setSuccessModal(false)} />
    </div>
  )
}

export default TenantsConfiguration;