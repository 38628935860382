import { viewNames } from "app/configs/routesNames";
import store from "../..";
import { filtersSliceActions } from "./filtersSlice";
import { DATA_TYPE_KEY, OPEN_DATA_FILTERS } from "src/utilities/utils";

export const getFilters = async(tenantId=null) => {
    const data = await store.dispatch(filtersSliceActions.getFilters(tenantId)).then(({payload, error}) => {
        if(error) return {};
        return payload;
    });

    return data;
};

// selectedId: lista o singolo elemento selezionato
// listIds: le opzioni del filtro
export const setFilter = (viewName, filterId, selectedId=undefined, listIds=null) => {
    const viewId = getViewIdByName(viewName);
    const selectedTenant = store.getState().user.selectedTenant;
    if(!store.getState().filters?.[selectedTenant]?.[viewId]?.hasOwnProperty(filterId)) return;
    store.dispatch(filtersSliceActions.setFilter({tenantId: selectedTenant, viewId, filterId, selectedId, listIds}));
};

export const setTimeRangeFilter = (viewName, filterId, intervalId=undefined, startDate=undefined, endDate=undefined) => {
    const viewId = getViewIdByName(viewName);
    const selectedTenant = store.getState().user.selectedTenant;
    if(!store.getState().filters?.[selectedTenant]?.[viewId]?.hasOwnProperty(filterId)) return;
    store.dispatch(filtersSliceActions.setTimeRangeFilter({tenantId: selectedTenant, viewId, filterId, intervalId, startDate, endDate}));
};

export const updateTenantFilters = async(tenantId, viewId, filters) => {
    // filters = { filterId: {selected, default} }
    if(!tenantId || !viewId || !filters) throw new Error("missing parameters.");
    const filtersState = store.getState().filters[tenantId][viewId];
    let dictFilters = {};
    Object.keys(filters).forEach((filterId) => {
        dictFilters[filterId] = [];
        filtersState[filterId].list.forEach((element) => {
            dictFilters[filterId].push({
                ...element, 
                active: filters[filterId].selected.includes(element._id) ? 1 : 0, 
                default: filters[filterId].default === element._id ? 1 : 0
            })
        });
    });
    const data = store.dispatch(filtersSliceActions.updateTenantFilters({tenantId, viewId, dictFilters})).then(({payload, error}) => {
        if(error) throw error;

        return payload;
    });
    return data;
};

// Attiva o dsattiva l'intero filtro in una view
export const activeTenantFilters = async(tenantId, viewId, filters) => {
    if(!tenantId || !viewId || !filters) throw new Error("missing parameters.");
    const filtersCategory = store.getState().configuration.constants.filterCategory;
    let dictFilters = {};
    Object.keys(filtersCategory).forEach((filterId) => {
        dictFilters[filterId] = filters.includes(filterId) ? 1 : 0;
    });
    const data = store.dispatch(filtersSliceActions.activeTenantFilters({tenantId, viewId, dictFilters})).then(({payload, error}) => {
        if(error) throw error;

        return payload;
    });
    return data;
};

const getViewIdByName = (viewName) => {
    const views = store.getState()?.configuration?.constants?.view_name;
    if (!views) return null;
    return Object.keys(views).find((id) => views[id]?.view_name === viewName);
};

export const setTimeIntervalValue = (viewName, intervalId=undefined, startDate=undefined, endDate=undefined) => {
    if(!store.getState().filters[viewName]?.hasOwnProperty("timeInterval")) return;
    store.dispatch(filtersSliceActions.setTimeIntervalValue({viewName, intervalId, startDate, endDate}));
};

export const setStationSelect = (viewName, stationId=undefined, stationList=undefined) => {
    if(!store.getState().filters[viewName]?.hasOwnProperty("stationSelect")) return;
    store.dispatch(filtersSliceActions.setStationSelect({ viewName, stationId, stationList}));
};

export const setMeasureSelect = (viewName, measureId=undefined, measureList=undefined) => {
    if(!store.getState().filters[viewName]?.hasOwnProperty("measureSelect")) return;
    store.dispatch(filtersSliceActions.setMeasureSelect({viewName, measureId, measureList}));
};

export const setOpenDataSourceSelect = (viewName, sourceId=null) => {
    store.dispatch(filtersSliceActions.setOpenDataSourceSelect({viewName, sourceId}));
};

export const setOdourLevelSelect = (viewName, odourLevelId=null) => {
    store.dispatch(filtersSliceActions.setOdourLevelSelect({viewName, odourLevelId}));
};

export const setOdourTypeSelect = (viewName, odourTypeId=null) => {
    store.dispatch(filtersSliceActions.setOdourTypeSelect({viewName, odourTypeId}));
};

export const setSelectedDataTypeFilter = (selected, key) => {
    store.dispatch(filtersSliceActions.setSelectedDataTypeFilter({viewName: viewNames.OPEN_DATA_VIEW, selected: selected, key: key}));
    setSelectedSourceFilter(OPEN_DATA_FILTERS.source.TUTTI, DATA_TYPE_KEY.TUTTI);
};

export const setSelectedSourceFilter = (selected, dataTypeKEY) => {
    store.dispatch(filtersSliceActions.setSelectedSourceFilter({viewName: viewNames.OPEN_DATA_VIEW, selected: selected, dataTypeKEY: dataTypeKEY}))
    if(dataTypeKEY === DATA_TYPE_KEY.TUTTI) return;
    store.dispatch(filtersSliceActions.setSelectedDataTypeFilter({viewName: viewNames.OPEN_DATA_VIEW, selected: OPEN_DATA_FILTERS.dataType[dataTypeKEY], key: dataTypeKEY}));
};

export const setSelectedMeasureFilter = (selected) => {
    store.dispatch(filtersSliceActions.setSelectedMeasureFilter({viewName: viewNames.OPEN_DATA_VIEW, selected: selected}))
};

export const setFilteredStations = (stations) => {
    store.dispatch(filtersSliceActions.setFilteredStations({viewName: viewNames.STATIONS_VIEW, stations: stations}));
};

export const setStationFilterList = (list) => {
    store.dispatch(filtersSliceActions.setStationFilterList({viewName: viewNames.STATIONS_VIEW, list: list}));
};

export const setSelectedStationFilter = (selected, viewName) => {
    store.dispatch(filtersSliceActions.setSelectedStationFilter({viewName: viewName, selected: selected}));
};

export const setSelectedStationTypeFilter = (selected) => {
    store.dispatch(filtersSliceActions.setSelectedStationTypeFilter({viewName: viewNames.STATIONS_VIEW, selected: selected}));
};

export const setSelectedProjectTypeFilter = (selected) => {
    store.dispatch(filtersSliceActions.setSelectedProjectTypeFilter({viewName: viewNames.STATIONS_VIEW, selected: selected}));
};

export const setStationDataTypeFilterList = (labels) => {
    store.dispatch(filtersSliceActions.setStationDataTypeFilterList({viewName: viewNames.STATIONS_VIEW, list: labels}));
};

export const setSelectedStationDataTypeFilter = (selected) => {
    store.dispatch(filtersSliceActions.setSelectedStationDataTypeFilter({viewName: viewNames.STATIONS_VIEW, selected: selected}));
};

export const setSelectedCuFilter = (selected, viewName) => {
    store.dispatch(filtersSliceActions.setSelectedCuFilter({viewName: viewName, selected: selected}));
};

export const setSelectedSensorFilter = (selected, viewName) => {
    store.dispatch(filtersSliceActions.setSelectedSensorFilter({viewName: viewName, selected: selected}));
};

export const resetAllFilters = (viewNames=[]) => {
    const views = store.getState().configuration.constants.view_name;
    if(viewNames?.length === 0) viewNames = Object.keys(views).map((id) => views[id].view_name);
    viewNames.forEach((view) => {
        setTimeIntervalValue(view, null, null, null)
        setStationSelect(view, null, []);
        setMeasureSelect(view, null, []);
    })
};


