import { Button, IconButton, Modal, TextField } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useState } from "react";
import { createTenant } from "app/store/evocsSlice/tenants/tenantsActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const containerStyle = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#2d3740",
  padding: "1rem",
  borderRadius: "1rem",
  height: "80vh",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column"
}

const inputs = {
  tenantImage: {
    value: "",
    required: false,
  },
  name: {
    value: "",
    required: true,
  },
  headquarter: {
    value: "",
    required: true,
  },
  tenants: {
    value: [],
    required: false,
  },
};

const FormCreation = ({ onConfirm }) => {
  const [allInputs, setAllInputs] = useState(inputs);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };


  useEffect(() => {
    const checkRequired = () => {
      let check = false;
      Object.keys(allInputs).every((key, index) => {
        if (allInputs[key].required && allInputs[key].value === "") { check = true; return false; };
        return true;
      })
      setButtonDisabled(check);
    }
    checkRequired();
  }, [allInputs]);

  const handleChangeInput = (key, value) => {
    setAllInputs({ ...allInputs, [key]: { ...allInputs[key], value: value } })
  };

  const handleConfirm = () => {
    const tenantImage = allInputs.tenantImage.value;
    const imageWithoutPrefix = tenantImage.substring(tenantImage.indexOf(',') + 1);
    createTenant(allInputs.name.value, allInputs.headquarter.value, null, null, allInputs.tenants.value, imageWithoutPrefix).then((res) => {
      if (!res) return;
      onConfirm();
    });
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        handleChangeInput("tenantImage", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_TENANT")} </h2>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}>
        <label>Immagine tenant: </label>
        <TextField sx={{ width: "100%", maxWidth: "300px" }} type="file" onChange={handleChangeImage} />
      </div>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        required={allInputs.name.required}
        value={allInputs.name.value}
        onChange={(e) => handleChangeInput("name", e.target.value)}
        label="Nome"
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        required={allInputs.headquarter.required}
        value={allInputs.headquarter.value}
        onChange={(e) => handleChangeInput("headquarter", e.target.value)}
        label={handleTranslate("HEADQUARTER", true)}
      />
      <div style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}>
        <label>Può condividere con: </label>
        <TenantSelect multiple value={allInputs.tenants.value} onChange={(value) => handleChangeInput("tenants", value)} />
      </div>
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const CreateTenantModal = ({ isOpen = false, onClose, onConfirm }) => {
  return (
    <Modal open={isOpen}>
      <div style={containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormCreation onConfirm={onConfirm} />
        </div>
      </div>
    </Modal>
  )
}

export default CreateTenantModal