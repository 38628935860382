import { getApiTimestamp } from "src/utilities/utils";
import store from "../..";
import { cuSliceActions } from "../controlUnits/cuSlice";
import { stationsMongoSliceActions } from "../stationsMongo/stationsMongoSlice";
import { sensorsSliceActions } from "./sensorSlice";
import { setStationState } from "../stationsMongo/stationsMongoActions";
import { setCuState } from "../controlUnits/cuActions";

export const setSensorsLoading = (loading=true) => {
    store.dispatch(sensorsSliceActions.setLoading(loading));
}

export const getSensorInfo = async(tenantIds) => {
    const data = store.dispatch(sensorsSliceActions.getSensorInfo({tenantIds: tenantIds})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const createSensor = async(serial, owner, sensorType, measures, available=false, stationId=null, cuId=null, thresholds=null) => {
    const availableNumber = available ? 1 : 0;
    const data = await store.dispatch(sensorsSliceActions.createSensor({serial, owner, sensorType, measures, available: availableNumber, stationId, cuId, thresholds})).then(({payload, error}) => {
        if(error) return null;
        return payload;
    })
    if(stationId) store.dispatch(stationsMongoSliceActions.installSensor({stationId, sensorIds: [data.sensorId]}));
    else if(cuId) store.dispatch(cuSliceActions.installSensor({cuId, sensorIds: [data.sensorId]}));
    return data;
};


// export const updateSensor = async(sensorId, serial, owner, sensorType, measures, available=undefined, stationId=undefined, cuId=undefined, uninstall=undefined, thresholds) => {
//     const sensors = store.getState().sensors.list;
//     const parent = sensors[sensorId].deviceParent;
//     const availableNumber = available === undefined ? undefined : (available ? 1 : 0);

//     const data = await store.dispatch(sensorsSliceActions.updateSensor({sensorId, serial, owner, sensorType, measures, available: availableNumber, stationId, cuId, uninstall, thresholds, operationTime: getApiTimestamp(new Date())})).then(({payload, error}) => {
//         if(error) throw error;

//         return payload;
//     });

//     if(available !== undefined) {
//         const devices = data[sensorId];
//         devices.forEach((element) => {
//             const deviceId = element._id;
//             const typeDevice = element.typeDevice;
//             delete element._id; delete element.typeDevice;
//             switch(typeDevice) {
//                 case "station":
//                     activateStation(deviceId, available);
//                     break;
//                 case "cu":
//                     activateCu(deviceId, available);
//                     break;
//                 case "sensor":
//                     activateSensor(deviceId, available);
//                     break;
//             }
//         });
//     }

//     if(uninstall) {
//         if(parent.type === "station") store.dispatch(stationsMongoSliceActions.uninstallSensor({stationId: parent.device, sensorId}));
//         else store.dispatch(cuSliceActions.uninstallSensor({cuId: parent.device, sensorId}));
//         store.dispatch(sensorsSliceActions.addUninstalledSensor(sensorId));
//     }
//     else if(stationId || cuId){
//         if(parent) {
//             if(parent.type === "station") store.dispatch(stationsMongoSliceActions.uninstallSensor({stationId: parent.device, sensorId}));
//             else store.dispatch(cuSliceActions.uninstallSensor({cuId: parent.device, sensorId}));
//             store.dispatch(sensorsSliceActions.addUninstalledSensor(sensorId));
//         }
//         if(stationId) store.dispatch(stationsMongoSliceActions.installSensor({stationId, sensorIds: [sensorId]}));
//         else if(cuId) store.dispatch(cuSliceActions.installSensor({cuId, sensorIds: [sensorId]}));
//         store.dispatch(sensorsSliceActions.addInstalledSensor(sensorId));
//     }
//     return data;  
// };

export const updateSensor = async(sensorId, serial, owner, sensorType, measures, available=undefined, stationId=undefined, cuId=undefined, uninstall=undefined, thresholds) => {
    const availableNumber = available === undefined ? undefined : (available ? 1 : 0);

    const data = await store.dispatch(sensorsSliceActions.updateSensor({sensorId, serial, owner, sensorType, measures, available: availableNumber, stationId, cuId, uninstall, thresholds, operationTime: getApiTimestamp(new Date())})).then(({payload, error}) => {
        if(error) throw error;

        return payload;
    });

    data[sensorId]?.forEach((element) => {
        const deviceId = element._id;
        const typeDevice = element.typeDevice;
        delete element._id; delete element.typeDevice;
        switch(typeDevice) {
            case "station":
                setStationState(deviceId, element);
                break;
            case "cu":
                setCuState(deviceId, element);
                break;
            case "sensor":
                setSensorState(deviceId, element);
                break;
        }
    });
    return data;  
};

export const setSensorState = (sensorId, state) => {
    store.dispatch(sensorsSliceActions.setSensorState({id: sensorId, sensor: state}));
}

export const deleteSensor = async(sensorId, parentId, parentType) => {
    await store.dispatch(sensorsSliceActions.deleteSensor(sensorId)).then(({payload, error}) => {if(error) throw error;});
    // if(!parentId || !parentType) return;
    // if(parentType === "station") store.dispatch(stationsMongoSliceActions.uninstallSensor({stationId: parentId, sensorId}));
    // else store.dispatch(cuSliceActions.uninstallSensor({cuId: parentId, sensorId}));
};

export const installSensor = async(sensorIds, deviceId, deviceType) => {
    const startDate = getApiTimestamp(new Date());
    const data = await store.dispatch(sensorsSliceActions.installSensor({sensorIds, deviceId, start: startDate, deviceType})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    if(deviceType === "station") store.dispatch(stationsMongoSliceActions.installSensor({stationId: deviceId, sensorIds}));
    else store.dispatch(cuSliceActions.installSensor({cuId: deviceId, sensorIds: sensorIds}));

    return data;
};

export const uninstallSensor = async(sensorId, end, parentId, parentType) => {
    const data = await store.dispatch(sensorsSliceActions.uninstallSensor({sensorId, end: getApiTimestamp(end), parentType})).then(({payload, error}) => { 
        if(error) throw error;
        return payload;
    });
    if(parentType === "station") store.dispatch(stationsMongoSliceActions.uninstallSensor({stationId: parentId, sensorId}));
    else store.dispatch(cuSliceActions.uninstallSensor({cuId: parentId, sensorId}));
    return data;
};

export const moveSensor = async(sensorId, sourceId, sourceType, destinationId, destinationType, start) => {
    const data = await store.dispatch(sensorsSliceActions.moveSensor({sensorId, sourceType, destinationId, destinationType, start: getApiTimestamp(start)})).then(({payload, error}) => { 
        if(error) throw error; 
        return payload;
    });

    if(sourceType === "station") store.dispatch(stationsMongoSliceActions.uninstallSensor({stationId: sourceId, sensorId}));
    else store.dispatch(cuSliceActions.uninstallSensor({cuId: sourceId, sensorId}));

    if(destinationType === "station") store.dispatch(stationsMongoSliceActions.installSensor({stationId: destinationId, sensorIds: [sensorId]}));
    else store.dispatch(cuSliceActions.installSensor({cuId: destinationId, sensorIds: [sensorId]}));

    return data;
};

export const getSensorHistory = async(sensorId) => {
    const deviceDict = {[sensorId]: "sensor"}
    const data = await store.dispatch(sensorsSliceActions.getHistory(deviceDict)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};


export const updateSensorHistory = async(sensorId, deviceId, deviceType, startDate, endDate) => {
    const data = await store.dispatch(sensorsSliceActions.updateHistory({sensorId, deviceId, deviceType, startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
}

export const downloadSensorHistory = async(sensorId, form="csv") => {
    const data = await store.dispatch(sensorsSliceActions.downloadHistory({sensorId, form})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const shareSingleSensor = async(sensorId, tenatList) => {
    if (!sensorId || !tenatList) throw new Error("Missing parameters");
    const dictShare = {[sensorId]: tenatList}

    const data = await store.dispatch(sensorsSliceActions.createShareSensor(dictShare)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    const stations=[], cus=[], sensors=[];
    data?.forEach((element) => {
        const newElement = {...element};
        const elementId = newElement._id;
        delete newElement._id;
        delete newElement.typeDevice;
        switch(element.typeDevice) {
            case "station":
                stations.push({id: elementId, station: newElement});
                break;
            case "cu":
                cus.push({id: elementId, cu: newElement});
                break;
            case "sensor":
                sensors.push({id: elementId, sensor: newElement});
                break;
        }
    });
    if(stations.length > 0) stations.forEach((element) => store.dispatch(stationsMongoSliceActions.setStationState(element)));
    if(cus.length > 0) cus.forEach((element) => store.dispatch(cuSliceActions.setCuState(element)));
    if(sensors.length > 0) sensors.forEach((element) => store.dispatch(sensorsSliceActions.setSensorState(element)));
    return data;
};

export const shareMultipleSensor = async(sharingList) => {
    if (!sharingList) throw new Error("Missing parameters");
    const data = await store.dispatch(sensorsSliceActions.createShareSensor(sharingList)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const updateShareSensor = async(sensorId, tenantsList) => {
    const data = await store.dispatch(sensorsSliceActions.updateShareSensor({sensorId, tenantsList})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    const stations=[], cus=[], sensors=[];
    data?.forEach((element) => {
        const newElement = {...element};
        const elementId = newElement._id;
        delete newElement._id;
        delete newElement.typeDevice;
        switch(element.typeDevice) {
            case "station":
                stations.push({id: elementId, station: newElement});
                break;
            case "cu":
                cus.push({id: elementId, cu: newElement});
                break;
            case "sensor":
                sensors.push({id: elementId, sensor: newElement});
                break;
        }
    });
    if(stations.length > 0) stations.forEach((element) => store.dispatch(stationsMongoSliceActions.setStationState(element)));
    if(cus.length > 0) cus.forEach((element) => store.dispatch(cuSliceActions.setCuState(element)));
    if(sensors.length > 0) sensors.forEach((element) => store.dispatch(sensorsSliceActions.setSensorState(element)));
    return data;
};

export const activateSensor = (sensorId, active=true) => {
    if(active) store.dispatch(sensorsSliceActions.activateSensor(sensorId));
    else store.dispatch(sensorsSliceActions.deactivateSensor(sensorId));
}


export const readAliveSensors = async(tenantId, stationIds=[], cuIds=[], sensorIds=[], available=false, interval=null) => {
    const data = await store.dispatch(sensorsSliceActions.readAliveSensors({tenantId, stationIds, cuIds, sensorIds, available, interval})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
} 

export const getSensorsThreshold = async (tenantId, sensorIds=[]) => {
    const data = await store.dispatch(sensorsSliceActions.getThreshold({tenantId, sensorIds})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
}

export const updateSensorThreshold = async(tenantId, sensorId, measureId, min=undefined, max=undefined) => {
    const data = await store.dispatch(sensorsSliceActions.updateThreshold({tenantId, sensorId, measureId, min, max})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
}

export const getLastSensorsPosition = async(sensorIds, startDate, endDate) => {
    if(!sensorIds || sensorIds?.length === 0) throw new Error("sensorIds is required");
    const data = await store.dispatch(sensorsSliceActions.getLastPosition({sensorIds, startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    }); 
    return data;
}

export const getCorrelationMatrix = async(sensorId, startDate, endDate) => {
    if(!sensorId) throw new Error("sensorId is required");
    const data = await store.dispatch(sensorsSliceActions.getCorrelationMatrix({sensorId, startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate)})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    }); 
    return data;
}  

export const getSensorsMeasures = (sensorId, onlyGraphMeasures=false) => {
    if(!sensorId) throw new Error("sensorId is required");
    const sensors = store.getState().sensors.list;
    let sensorsMeasures;
    if(Array.isArray(sensorId)) {
        sensorsMeasures = [...new Set(sensorId.map((id) => sensors[id]?.measures).flat())];
    }else {
        sensorsMeasures = sensors[sensorId]?.measures;
    }
    if(onlyGraphMeasures) return sensorsMeasures.filter((id) => !isMeasureGps(id) && !isMeasureMultimedia(id));
    return sensorsMeasures;
}


export const hasSensorWindMeasures = (sensorId) => {
    const sensors = store.getState().sensors.list;
    return sensors[sensorId]?.measures.some((measureId) => isMeasureWind(measureId));
}

export const isSensorGps = (sensorId) => {
    const sensors = store.getState().sensors.list;
    const sensorsType = store.getState().configuration.constants?.sensor_type;
    if(!sensorsType) return false;
    const typeId = sensors[sensorId]?.sensor_type;
    return sensorsType[typeId]?.sensor_type === "gps";
}

export const isSensorMultimedia = (sensorId) => {
    const sensors = store.getState().sensors.list;
    const sensorsType = store.getState().configuration.constants.sensor_type;
    if(!sensorsType) return false;
    const typeId = sensors[sensorId]?.sensor_type;
    const type = sensorsType[typeId]?.sensor_type;
    if(type === "camera") return type;
    else return false;
}

export const isMeasureGps = (measureId) => {
    const measures = store.getState().configuration.constants.measure;
    const gpsMeasuresId = measures && Object.keys(measures).filter((id) => measures[id]?.measure === "lat" || measures[id]?.measure === "lon" || measures[id]?.measure == "alt" || measures[id]?.measure === "satelliti");
    return gpsMeasuresId?.includes(measureId);
}

export const isMeasureWind = (measureId) => {
    const measures = store.getState().configuration.constants.measure;
    const windMeasuresId = Object.keys(measures).filter((id) => measures[id]?.measure === "ws" || measures[id]?.measure === "wd");
    return windMeasuresId.includes(measureId);
}

export const isMeasureMultimedia = (measureId) => {
    const measures = store.getState().configuration.constants.measure;
    if(!measures) return null;
    const multimediaMeasuresId = Object.keys(measures).filter((id) => measures[id]?.measure === "image" || measures[id]?.measure === "video");
    const isMultimedia = multimediaMeasuresId.find((id) => id===measureId);
    if(isMultimedia) return measures[isMultimedia].measure;
    else return null;
}


