import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteStation } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import LoadingGif from "../FileManager/LoadingGif";
import useMobile from "src/app/hooks/useMobile";
import { useSelector } from "react-redux";


const StationListItemMenu = ({ style, enabledActions, station, onClickItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClickItem = (itemAction, actionCallback = null) => {
    handleClose();
    onClickItem && onClickItem(itemAction, {...station, type: "station"}, actionCallback);
  };

  const stopPropagation = (e) => {
    e.stopPropagation()
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <div style={{ ...style, width: "fit-content" }} onClick={stopPropagation}>
      <IconButton sx={style} onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClickItem("config-station")}> {handleTranslate("CONFIGURE")} </MenuItem>
        {enabledActions.includes("manage-device") && <MenuItem sx={{ color: "red" }} onClick={() => handleClickItem("delete", () => deleteStation(station.id))}> {handleTranslate("DELETE", true)} </MenuItem>}
      </Menu>
    </div>
  )
}

const StationListItem = ({ enabledActions, disableDrag, station, selected = false, onClick, onClickItemMenu }) => {
  const className = !selected ? "station-item-list close" : "station-item-list selected";

  const isMenuEnabled = () => {
    if (!enabledActions.includes("manage-device") && !enabledActions.includes("update-info-device")) return false;
    return true;
  };

  const handleClick = () => {
    if(disableDrag) 
      onClickItemMenu && onClickItemMenu("config-station", {...station, type: "station"});
    else onClick && onClick(station.id)
  };


  return station && (
    <div className={className} style={{ display: "flex", padding: "1rem 0rem", alignItems: "center", borderTop: "1px solid grey" }} onClick={handleClick}>
      <span style={{ fontWeight: "bold" }}>{station.serial}</span>
      {isMenuEnabled() && <StationListItemMenu style={{ marginLeft: "auto" }} enabledActions={enabledActions} station={station} onClickItem={onClickItemMenu} />}
    </div>
  );
}

const StationsList = memo(({ enabledActions, list, selectedItems, onClickItem, onClickItemMenu }) => {
  const [xs, sm] = useMobile();
  const stations = useSelector((state) => state.stationsMongo.list);
  const disableDrag = useMemo(() => xs || sm);

  const handleClick = (stationId) => {
    if (!selectedItems.includes(stationId)) onClickItem([...selectedItems, stationId], stationId);
    else onClickItem(selectedItems.filter((e) => e !== stationId), stationId);
  };

  const getStationElement = (stationId) => {
    return { id: stationId, ...stations[stationId] }
  };

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      {list?.map((stationId) => (
        <StationListItem key={stationId} enabledActions={enabledActions} disableDrag={disableDrag} station={getStationElement(stationId)} selected={selectedItems.includes(stationId)}
          onClick={handleClick} onClickItemMenu={onClickItemMenu} />
      ))}
    </div>
  )
});


const StationsManagement = ({ loading, selectedTenant, selectedStations, enabledActions, onChangeTenant, onStationItemClick, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const [stationsOptions, setStationsOptions] = useState([]);
  const [filteredStations, setFilteredStations] = useState();
  const [visibleStations, setVisibleStations] = useState();

  useLayoutEffect(() => {
    setFilteredStations([]);
  }, [selectedTenant])

  useLayoutEffect(() => {
    setVisibleStations(() => {
      if (!filteredStations || filteredStations.length === 0) return Object.keys(stations);
      return filteredStations;
    });
  }, [stations, filteredStations])

  useLayoutEffect(() => {
    const allStationsId = Object.keys(stations);
    setStationsOptions(allStationsId.map((id) => ({ id, serial: stations[id].serial })));
    setFilteredStations((prev) => prev.filter((stationId) => allStationsId.includes(stationId)));
  }, [stations])

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", height: "100%", overflowX: "visible" }}>
      {!loading ?
      <>
        <div style={{ display: "flex", gap: "1rem"}}>
          <StationSelect isUserTenant={false} limitTags={1} multiple stationsId={stationsOptions} value={filteredStations} onChange={setFilteredStations} />
          <TenantSelect value={selectedTenant} onChange={onChangeTenant} />
          {enabledActions.includes("manage-device") && <Button sx={{ ml: "auto" }} onClick={() => onClickItemMenu("create-station", null)}>+ {handleTranslate("NEW_STATION")}</Button>}
        </div>
        <StationsList enabledActions={enabledActions} list={visibleStations} selectedItems={selectedStations} onClickItem={onStationItemClick}
          onClickItemMenu={onClickItemMenu} />
      </>
      :
      <LoadingGif />
      }
    </div>
  )
}

export default StationsManagement;
