import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import MapComponent from '../SharingComponents/MapComponent';
import { useSelector } from 'react-redux';
import mkrDark from "src/app/asset/markers/EvocsThemeBlack.png";
import mkrWifiRed from "src/app/asset/markers/mkrWifiRed.png";
import mkrWifiYellow from "src/app/asset/markers/mkrWifiYellow.png";
import mkrWifiGreen from "src/app/asset/markers/mkrWifiGreen.png";
import mkrWifiGrey from "src/app/asset/markers/mkrWifiGrey.png";
import boaIcon from "src/app/asset/markers/boaIcon_yellow.png"
import boaRed from "src/app/asset/gif/boa_red2.gif";
import boaGreen from "src/app/asset/gif/boa_green.gif";
import boaYellow from "src/app/asset/gif/boa_yellow.gif";
import { MdOutlineWifi } from "react-icons/md";
import { MdOutlineWifi2Bar } from "react-icons/md";
import { MdOutlineWifiOff } from "react-icons/md";
import { CgUnavailable } from "react-icons/cg";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { getLastStationPosition } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';

const defaultCenter = [40.4774123507161, 17.2209236958397];

const getBoaIcon = (light) => {
  const getSrc = () => {
    switch(light) {
      case "red":
        return boaRed;
      case "yellow":
        return boaYellow;
      case "green":
        return boaGreen;
      default:
        return boaIcon;
    }
  }
  return light && (
    <div>
      <img style={{width: "30px"}} src={getSrc()} alt="Boa" />
      {/* <div style={{position: "absolute", boxShadow: "0px 0px 12px 10px red", top: "5px", left: "50%", transform: "translate(-50%, -50%)", zIndex: -1}}></div> */}
    </div>
  )
};

const DashboardMap = ({aliveSensors, selectedStation, defaultStation, onClickMarker, onClosePopup}) => {
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const baseLocations = useSelector((state) => state.configuration.constants?.base_location);
  const [overlays, setOverlays] = useState([]);
  const [popups, setPopups] = useState([]);
  const [stationSelected, setStationSelected] = useState(defaultStation);
  const [mapCenter, setMapCenter] = useState();
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const getMarkerIcon = useCallback((stationId, status) => {
    
    switch (status) {
      case 0:
        return stationId !=="65a7bce7e8b2f26afc70747c" ? mkrWifiRed : getBoaIcon("red");
      case 1:
        return stationId !=="65a7bce7e8b2f26afc70747c" ? mkrWifiYellow : getBoaIcon("yellow");;
      case 2:
        return stationId !=="65a7bce7e8b2f26afc70747c" ? mkrWifiGreen : getBoaIcon("green");
      case null:
        return  mkrWifiGrey;
      default:
        return mkrDark;
    }
  }, []);

  const getIconType = useCallback((status) => {
    switch (status) {
      case 0:
        return <MdOutlineWifiOff />;
      case 1:
        return <MdOutlineWifi2Bar />;
      case 2:
        return <MdOutlineWifi />;
      default:
        return <CgUnavailable />;
    }
  }, []);


  useEffect(() => {
    if(!aliveSensors || !stations) return;
    const ovrly = [
      {
        id: "available_station",
        name: handleTranslate("AVAILABLE_STATIONS", true),
        checked: true,
        markers: []
      },
      {
        id: "unavailable_station",
        name: handleTranslate("UNAVAILABLE_STATIONS", true),
        checked: false,
        markers: []
      },
    ];
    const endDate = new Date().valueOf();
    const startDate = endDate - 3600000;
    getLastStationPosition(Object.keys(stations), startDate, endDate).then((res) => {
      res.forEach((location) => {
        if(location) {
          const markerIcon = getMarkerIcon(location.stationId, aliveSensors[location.stationId]?.status);
          const markerType = getIconType(aliveSensors[location.stationId]?.status);
          const marker = {id: location.stationId, lat: location.lat, lon: location.lon, icon: markerIcon, iconType:markerType};
          if(stations[location.stationId].available === 1) ovrly[0].markers.push(marker)
          else ovrly[1].markers.push(marker);
        } 
      });
      setOverlays(ovrly);
    });
  }, [aliveSensors, stations])

  useEffect(() => {
    if(!stations || !controlUnits || !sensors || !baseLocations) return;
    const allPopups = [];
    Object.keys(stations).forEach((stationId) => {
        let content = {};
        const station = stations[stationId];
        // const location = baseLocations?.[station.base_location];
        // if(!location) return;
        const listCu = station.list_cu.map((cuId) => controlUnits[cuId]?.serial);
        const listSensors = station.list_sensors.map((sensorId) => sensors[sensorId]?.serial);

        content.id = stationId;
        content.title = station.serial;
        if(listCu.length > 0) content.listCu = {label: handleTranslate('CONTROL_UNITS_INSTALLED', true), value: listCu};
        if(listSensors.length > 0) content.listSensors = {label: handleTranslate('SENSORS_INSTALLED', true), value: listSensors};
        allPopups.push(content);
    })
    setPopups(allPopups);
  }, [stations, controlUnits, sensors, baseLocations, t])


  useEffect(() => {
    selectedStation !== undefined && setStationSelected(selectedStation);
  }, [selectedStation]);

  useEffect(() => {
    if(!stationSelected || overlays.length === 0) return;
    const marker = overlays[0].markers.find((element) => element.id === stationSelected);
    marker && setMapCenter({lat: marker.lat, lon: marker.lon});
  }, [stationSelected]);


  return (
    <MapComponent height="400px" defaultCenter={defaultCenter} center={mapCenter} overlays={overlays} selectedMarker={stationSelected} popupsContent={popups} onClickMarker={onClickMarker} onClosePopup={onClosePopup}/>
  )
}

export default DashboardMap