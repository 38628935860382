import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { createCu } from "app/store/evocsSlice/controlUnits/cuActions";
import StationSelect from "../SharingComponents/Inputs/StationSelect";
import SensorSelect from "../SharingComponents/Inputs/SensorSelect";
import SwitchComponent from "../SharingComponents/Inputs/SwitchComponent";
import LoadingGif from "../FileManager/LoadingGif";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const requiredInputs = ["serial", "owner"];

const FormCreation = ({ onPending, onSuccess, onChangeInputs }) => {
  const [inputs, setInputs] = useState({
    serial: "",
    owner: "",
    available: false,
    stationParent: null,
    sensors: []
  });
  const [changedInputs, setChangedInputs] = useState([]);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  useEffect(() => {
    const disabled = Object.keys(inputs).some((key) => (requiredInputs.includes(key) && (inputs[key] === "" || inputs[key]?.length === 0)));
    setDisableConfirm(disabled);
    onChangeInputs(changedInputs);
  }, [changedInputs]);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setChangedInputs(prev => [...prev, key]);
  };

  const handleConfirm = () => {
    onPending();
    createCu(inputs.serial, inputs.owner, inputs.stationParent, inputs.sensors, inputs.available).then(() => onSuccess());
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <div style={{ display: "flex", gap: "5rem", alignItems: "center", justifyContent: "space-between" }}>
        <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_STATION")} </h2>
        <SwitchComponent label={handleTranslate("ACTIVE", true)} defaultChecked={inputs.available} checked={inputs.available} onChange={(value) => handleChangeInput("available", value)} />
      </div>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={handleTranslate("SERIAL", true)}
        required
        className="rounded-2xl"
        value={inputs.serial}
        onChange={(e) => handleChangeInput("serial", e.target.value)}
      />
      <TenantSelect value={inputs.owner} onChange={(value) => handleChangeInput("owner", value)} required />
      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <span style={{ minWidth: "100px" }}> {handleTranslate('INSTALL', true)} </span>
        <SensorSelect multiple uninstalled limitTags={2} value={inputs.sensors} onChange={(value) => handleChangeInput("sensors", value)} />
      </div>
      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        <span style={{ minWidth: "100px" }}> {handleTranslate('INSTALL_ON', true)} </span>
        <StationSelect value={inputs.stationParent} onChange={(value) => handleChangeInput("stationParent", value)} />
      </div>
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={disableConfirm} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}

const styles = {
  containerStyle: {
    position: "absolute",
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#2d3740",
    padding: "1rem",
    borderRadius: "1rem",
    height: "80vh",
    width: "95%",
    maxWidth: "800px",
    flexDirection: "column"
  },
  loadingPopup: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255, 255, 255, .5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};


const CreateCuModal = ({ isOpen = false, onSuccess, onClose }) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changedInputs, setChangedInputs] = useState([]);

  useEffect(() => {
    !isOpen && setChangedInputs([]);
  }, [isOpen])

  const handleSuccess = () => {
    setUpdateLoading(false);
    onSuccess && onSuccess();
  }

  const handleClose = () => {
    setUpdateLoading(false);
    if (!onClose) return;
    if (changedInputs?.length > 0) onClose(false);
    else onClose();
  }

  return (
    <Modal open={isOpen} disablePortal>
      <div style={styles.containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <FormCreation onChangeInputs={setChangedInputs} onPending={() => setUpdateLoading(true)} onSuccess={handleSuccess} />
          {updateLoading &&
            <div style={styles.loadingPopup}>
              <LoadingGif />
            </div>
          }
        </div>
      </div>
    </Modal>
  )
};

export default CreateCuModal;
