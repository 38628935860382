import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const StationTypeSelect = ({ defaultMobile = false, mobile = undefined, defaultStationType = undefined, stationType = undefined, multiple = false, enableAllValue=false, enableNullValue=false, onChangeMobile, onChangeType, disableRadioButtons = true, disabled = false }) => {
  const { t } = useTranslation("evocs");
  const stationTypes = useSelector((state) => state.configuration.constants?.station_type);
  const options = useMemo(() => {
    if (!stationTypes) return [];
    return Object.keys(stationTypes);
  }, [stationTypes])
  const [isMobile, setMobile] = useState(defaultMobile);
  const [type, setType] = useState(defaultStationType !== undefined ? defaultStationType : multiple ? [] : "");
  const handleTranslate = (textId) => {
    return translate(t, textId);
  };

  useEffect(() => {
    stationType !== undefined && setType(stationType);
  }, [stationType]);

  useEffect(() => {
    mobile !== undefined && setMobile(mobile);
  }, [mobile]);

  const handleChangeMobile = (e, mobileValue) => {
    const value = mobileValue === "true" ? true : false;
    setMobile(value);
    onChangeMobile && onChangeMobile(value);
  }

  const handleChangeType = (typeValue) => {
    setType(typeValue);
    onChangeType && onChangeType(typeValue);
  }

  const getStationTypeLabel = (selected) => {
    return multiple ? selected.map((typeId) => stationTypes[typeId]?.station_type).join(", ") : stationTypes[selected]?.station_type
  };


  return (
    <div >
      {!disableRadioButtons &&
        <FormControl fullWidth sx={{ mb: "1rem" }} disabled={disabled}>
          <RadioGroup value={isMobile} onChange={handleChangeMobile}>
            <FormControlLabel value={false} control={<Radio />} label="Fissa" />
            <FormControlLabel value={true} control={<Radio />} label="Mobile" />
          </RadioGroup>
        </FormControl>
      }
      <FormControl sx={{ width: "100%", maxWidth: "300px" }} disabled={disabled}>
        <InputLabel id="station-type-select-label">{handleTranslate('STATION_TYPE')}</InputLabel>
        <Select
          labelId="station-type-select-label"
          multiple={multiple}
          renderValue={getStationTypeLabel}
          value={type}
          label={handleTranslate('STATION_TYPE')}
          onChange={(e) => handleChangeType(e.target.value)}
        >
          {!multiple &&
            enableAllValue && 
            <MenuItem value={"all"}>
              {multiple && <Checkbox checked={val?.indexOf("all") > -1} /> }
              <em>Tutte</em>
            </MenuItem>
          }
          {!multiple && 
            enableNullValue && 
            <MenuItem value={""}>
              {multiple && <Checkbox checked={val?.indexOf("") > -1} /> }
              <em>Null</em>
            </MenuItem>
          }
          {options.map((id, index) => (
            <MenuItem key={index} value={id}>
              {multiple && <Checkbox checked={type?.indexOf(id) > -1} />}
              {stationTypes[id]?.station_type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default StationTypeSelect;
