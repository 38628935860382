import React, { useEffect, useState } from 'react';
import newLogoEvocs from 'src/app/asset/images/logoEvocsWhite.png';
import { Box } from '@mui/system';
import { Button, TextField } from '@mui/material';
import LanguageSelect from 'src/components/SharingComponents/Inputs/LanguageSelect';
import LoadingGif from 'src/components/FileManager/LoadingGif';

const leftBoxText = `
  Proteggi il tuo futuro, inizia oggi!
  Con Evocs puoi sfruttare il potere dell'intelligenza artificiale per un mondo più sostenibile.
  Evocs è la piattaforma di Tilebytes s.r.l. che ti permette di monitorare in tempo reale la qualità dell'aria nella tua città, 
  ricevere avvisi in caso di picchi di inquinamento, 
  contribuire a creare una rete di dati per la tutela dell'ambiente e molto altro.
  Registrati ora su Evocs e:
  Scopri i dati sulla qualità dell'aria nella tua zona.
  Confronta i dati con altre città e paesi.
  Personalizza i tuoi avvisi e ricevili direttamente sul tuo smartphone.
  Insieme, possiamo fare la differenza!.
`
const defaultInputs = {
  username: {
    required: true,
    value: ""
  },
  email: {
    required: true,
    value: ""
  },
  password: {
    required: true,
    value: ""
  },
  language: {
    required: true,
    value: ""
  }
}

const UserForm = () => {
  const [inputs, setInputs] = useState(defaultInputs);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    if (Object.keys(inputs).some((key) => inputs[key].required && inputs[key].value === "")) setDisableSubmit(true);
    else setDisableSubmit(false);
  }, [inputs])


  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: { ...inputs[key], value: value } });
    setInputsChanged(true);
  }

  return (
    <form style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem", width: "100%"}}>
      <TextField fullWidth id='email' label='Email' required value={inputs.email.value} onChange={(e) => handleChangeInput("email", e.target.value)} />
      <TextField fullWidth id='username' label='Username' required value={inputs.username.value} onChange={(e) => handleChangeInput("username", e.target.value)} />
      <div style={{display: "flex", gap: "1rem"}}>
        <TextField sx={{width: "100%", maxWidth: "300px"}} id='password' label='Password' type="password" autoComplete='new-password' required value={inputs.password.value} onChange={(e) => handleChangeInput("password", e.target.value)} />
        <LanguageSelect required value={inputs.language.value} onChange={(value) => handleChangeInput("language", value)} />
      </div>
      <Button fullWidth disabled={disableSubmit} className="custom-button dark" type="submit">Crea il tuo account gratuito</Button>
    </form>
  )
}

const SignupPage = () => {

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Box sx={{ background: "#242a30", width: "30%", color: "white" }} display={{ xs: "none", md: "block" }}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%", gap: "2rem", alignItems: "center", padding: "1rem" }}>
          <img style={{ width: "250px", marginTop: "200px" }} src={newLogoEvocs} alt="Logo" />
          <h1>Benvenuto nella nostra community</h1>
          <p>
            {leftBoxText}
          </p>
        </div>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", padding: "1rem" }} width={{ xs: "100%", md: "80%" }}>
        <div style={{ display: "flex", height: "100%", gap: "1rem", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
          <h2>Iscriviti</h2>
          <p>Hai già un account? <a href="#">Accedi</a></p>
          <LoadingGif />
          <UserForm />
        </div>
      </Box>
    </div>
  )
};

export default SignupPage;
