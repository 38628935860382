import DASHBOARD_IT from "src/components/Dashboard/i18n/it";
import SIGN_IN_IT from "src/components/Sign-in/i18n/it";
import ADMINISTRATION_IT from "src/components/Administration/i18n/it";
import ALERTS_IT from "src/components/Alerts/i18n/it";
import CONFIGURATION_IT from "src/components/Configuration/i18n/it";
import ODOR_REPORTS_IT from "src/components/OdorReports/i18n/it";
import OPEN_DATA_AIR_QUALITY_IT from "src/components/OpenDataAir/i18n/it";
import OPEN_DATA_METEOROLOGY_IT from "src/components/OpenDataMeteo/i18n/it";
import SINGLE_MULTIMEDIA_IT from "src/components/SingleMultimedia/i18n/it";
import SINGLE_STATION_IT from "src/components/SingleStationHandling/i18n/it";
import STATIONS_IT from "src/components/StationHandling/i18n/it";
import USER_PROFILE_IT from "src/components/UserProfile/i18n/it";
import MULTIMEDIA_IT from "src/components/Multimedia/i18n/it";
import VESSELS_IT from "src/components/VesselsTraffic/i18n/it";
import TEST_PAGE_IT from "src/components/TestPage/i18n/it";
import FOOTER_IT from "src/components/SharingComponents/i18n/Footer/it";
import { MONGO_CONSTANTS_IT } from "./mongoCostants/costants_it";
import { navbarGroupsNamesIt } from "./navBarGroups/groups_it";
import SHARING_COMPONENTS_IT from "src/components/SharingComponents/i18n/it";
import MODAL_COMPONENTS_IT from "src/components/Modals/i18n/it";
import INPUT_COMPONENTS_IT from "src/components/SharingComponents/Inputs/i18n/it";
import { SELF_CONTAINED_VIEWS_IT } from "./self_contained_views/views_it";
import { COLORS } from "html2canvas/dist/types/css/types/color";

const generalTerms = {
	STATION : "Stazione",
	STATIONS : "Stazioni",
	MONITORING_STATIONS : "Stazioni di monitoraggio",
	YELLOW: "Giallo",
	GREEN: "Verde",
	BLUE: "Blu",
	RED: "Rosso",
	GRAY: "Grigio",
	STATISTICS: "Statistiche",
	THRESHOLD: "Soglia",
	THRESHOLDS: "Soglie",
	VALUE: "Valore",
    AVERAGE: "Media",
    CATEGORY: "Categoria",
    LAST_RELEVATION: "Ultima rilevazione",
	SELECT_STATION: "Seleziona una stazione",
	NO_SENSORS_INSTALLED: 'Non ci sono sensori installati',
	CONTROL_UNIT: "Centralina",
	MEDIAN: "Mediana",
	NO_DATA: "Nessun dato",
	CONTROL_UNITS_INSTALLED: "Centraline installate",
	SENSOR: "Sensore",
	SENSORS: "Sensori",
	SENSORS_INSTALLED: "Sensori installati",
	SENSORS_TYPE: "Tipo sensore",
	STATION_TYPE: "Tipo stazione",
	TYPE: "Tipo",
	LEVEL: "Livello",
	MEASURE : "Misura",
	SOURCE : "Sorgente",
	ALL: "Tutti/e",
	INTERVAL: "Intervallo",
	SERIAL : "Seriale",
	NAME: "Nome",
	TRAFFIC: "Traffico",
	AVAILABLE_STATIONS: "Stazioni attive",
	UNAVAILABLE_STATIONS: "Stazioni disattive",
	CONTROL_UNITS: "Centraline",
	MEASURES: "Misure",
	ADD: "Aggiungi",
	DELETE: "Elimina",
	ODOUR_LEVEL: "Livello",
	DATE: 'Data',
	ON_THE_DATE_OF: "In data",
	DEPARTURE: "Partenza",
	ARRIVAL: "Arrivo",
	BASE_LOCATION: "Posizione di default",
	LAST_POSITION: "Ultima posizione",
	CURRENT: 'Attuale',
	POSITION: "Posizione",
	NEW : "Nuovo/a",
	EXISTING: "Esistente",
	SAVE: "Salva",
	CONFIRM: "Conferma",
	CANCEL: "Annulla",
	LAST_RELEVATIONS: "Ultime rilevazioni",
	SAVING: "Salvataggio",
	CANCEL_CHANGES: "Annulla modifiche",
	INSTALL: "Installa",
	INSTALL_ON: "Installa su",
	INSTALLED_ON: "Installato/a su",
	ACTIVE : "Attivo/a",
	DEACTIVATE: "Disattiva",
	SENSOR_TYPE: "Tipologia del sensore",
	NO_THRESHOLDS: "Nessuna soglia",
	HISTORY: "Storico",
	GENERAL: "Generali/e",
	HEADQUARTER: "Sede",
	START_DATE: "Data inizio",
	END_DATE: "Data fine",
	ERROR: "Errore",
	LANGUAGE: "Lingua",
	SESSION_EXPIRED: "Sessione scaduta",
	SOMETHING_WENT_WRONG: "Ops qualcosa è andato storto",
	GO_TO_LOGIN : "Torna al login",
	WELCOME: "Benvenuto",
	TENANTS: "Tenant",
	USERS: "Utenti",
	ROLE: "Ruolo",
	ROLES: "Ruoli",
	FILTERS: "Filtri",
	GLOBAL_VARIABLES: "Variabili globali",
	SHARING: "Condivisione",
	DESCRIPTION: "Descrizione",
	EDIT: "Modifica",
	IDENTIFIER: "Identificativo",
	MEASUREMENT_UNIT: "Unità di misura",
	COLOR: "Colore",
	TITLE: "Titolo",
	MESSAGE: "Messaggio",
	TIME: "Tempo",
	ACTION: "Azione", 
	ACTIONS: "Azioni", 
	NOTIFICATION: "Notifica",
	NOTIFICATIONS: "Notifiche",
	NOTIFICATION_TYPE: "Tipo notifica",
	EXTENSION : "Estensione",
    RESOLUTION : "Risoluzione",
    SIZE : "Dimensione",
	IMAGES: "Immagini",
	STATE: "Stato",
	EXPORT: "Esporta",
	SECONDS: "Secondi",
	NO_STATIONS_AVAILABLE: "Nessuna stazione disponibile",
	DATA: "Dati",
	MAINTANCE: "Manutenzione",
	OTHERS: "Altro",
};



const locale = {
	...SIGN_IN_IT,
	...DASHBOARD_IT,
	...ADMINISTRATION_IT,
	...ALERTS_IT,
	...CONFIGURATION_IT,
	...ODOR_REPORTS_IT,
	...OPEN_DATA_AIR_QUALITY_IT,
	...OPEN_DATA_METEOROLOGY_IT,
	...SINGLE_MULTIMEDIA_IT,
	...SINGLE_STATION_IT,
	...STATIONS_IT,
	...USER_PROFILE_IT,
	...MULTIMEDIA_IT,
	...VESSELS_IT,
	...TEST_PAGE_IT,
	...FOOTER_IT,
	...generalTerms,
	...navbarGroupsNamesIt,
	...MONGO_CONSTANTS_IT,
	...SHARING_COMPONENTS_IT,
	...MODAL_COMPONENTS_IT,
	...INPUT_COMPONENTS_IT,
	...SELF_CONTAINED_VIEWS_IT,
};


// console.log('locale it', locale);

export default locale;