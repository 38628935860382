import { useCallback, useEffect, useState } from 'react';
import MapComponent from '../SharingComponents/MapComponent';
import { FaShip } from "react-icons/fa6";
import mkrIcon from "src/app/asset/markers/Blue2.png";
import { getPolarPlots } from 'app/store/evocsSlice/stationsMongo/stationsMongoActions';
import legendImage from 'src/app/asset/images/ciccioColorMap3.png';

const styles = {
  popoupLegend: {
    position: 'absolute',
    bottom: '0.5rem',  // Adjusted to bottom
    right: '1rem',
    backgroundColor: 'white',
    padding: '0.5rem',
    borderRadius: '1rem',
    zIndex: 1000,  // You can adjust the z-index as needed
    width: '250px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add a shadow
    pointerEvents: "none"
  },
  imageLegend: {
    position: 'absolute',
    width: "50px",
    bottom: '5rem',  // Adjusted to bottom
    left: '1rem',
    zIndex: 1000,  // You can adjust the z-index as needed
    pointerEvents: "none"
  }
};


const VesselMap = ({vessels, center, selectedMarker}) => {
  const [markers, setMarkers] = useState([]);
  const [mapPopups, setMapPopups] = useState([]);
  const [displayPolarLegend, setDisplayPolarLegend] = useState(false);

  const getOverlays = useCallback(() => {
    return [
      {
        id: "polarPlots",
        name: "Polar Plots",
        checked: false,
        imageOverlays: [],
        onHover: () => setDisplayPolarLegend(true),
        onBlur: () => setDisplayPolarLegend(false)
      },
    ];
  }, [])

  const [overlays, setOverlays] = useState(getOverlays());

  useEffect(() => {
    const endDate = new Date().valueOf();
    const startDate = endDate - 3600000*4;
    getPolarPlots(startDate, endDate).then((res) => {
      const newOverlays = getOverlays();
      newOverlays[0].imageOverlays = [{
        lat: 40.4769776766326,
        lon: 17.1974215834441,
        url: "data:image/png;base64,"+res.plot
      }] 
      setOverlays(newOverlays);
    })
  }, [])

  useEffect(() => {
    if(!vessels) return;
    setMarkers(vessels.map((element) => ({...element, icon: mkrIcon, iconType: <FaShip />})));
    setMapPopups(vessels.map((element) => ({title: 
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "0.5rem"}}>
        {element.flag}
        {element.name}
      </div>

    })))
  }, [vessels])

  return (
    <MapComponent cluster center={center} selectedMarker={selectedMarker} overlays={overlays} markers={markers} popupsContent={mapPopups} clusterColor={"#168cdf"}>
      {displayPolarLegend &&
      <div>
        <div style={styles.popoupLegend}>
          <p>Questo è un polarplot ricavato dall'integrazione di dati VOC e meteorologici delle ultime 24 ore.
            Dal <label style={{ color: 'blue' }}>blu</label> (più bassa) al <label style={{ color: 'red' }}>rosso</label> (più alta), 
            si rappresenta una probabilità crescente di trovare la fonte dell'inquinamento.</p>
        </div>
        <div style={styles.imageLegend}>
          <img src={legendImage}/>
        </div>
      </div>
      }
    </MapComponent>
  )
}

export default VesselMap