import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CuConfigurationModal from "../Modals/CuConfigurationModal";
import StationConfigurationModal from "../Modals/StationConfigurationModal";
import SensorConfigurationModal from "../Modals/SensorConfigurationModal";
import SuccessModal from "../Modals/SuccessModal";
import WarningModal from "../Modals/WarningModal";
import CreateStationModal from '../Modals/CreateStationModal';
import ErrorModal from '../Modals/ErrorModal';
import CreateCuModal from '../Modals/CreateCuModal';
import CreateSensorModal from '../Modals/CreateSensorModal';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import AddMeasureToSensorModal from '../Modals/AddMeasureToSensorModal';
import AddUnitModal from '../Modals/AddUnitModal';

const ConfigurationModals = ({enabledActions, device, modalType, modalText=null, onClose, onConfirm}) => {
  const [openDeviceModal, setOpenDeviceModal] = useState(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [title, setTitle] = useState(modalText);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };


  const successText = useMemo(() => {
    switch(modalType) {
      case "create-station":
        return handleTranslate('STATION_CREATION_SUCCESS');
      case "create-cu":
        return handleTranslate('CONTROL_UNIT_CREATION_SUCCESS');
      case "create-sensor":
        return handleTranslate('SENSOR_CREATION_SUCCESS');
      case "config-station":
        return handleTranslate('STATION_UPDATE_SUCCESS');
      case "config-cu":
        return handleTranslate('CONTROL_UNIT_UPDATE_SUCCESS');
      case "config-sensor":
        return handleTranslate('SENSOR_UPDATE_SUCCESS');
    }
  }, [modalType])

  useEffect(() => {
    setTitle(modalText);
  }, [modalText])

  useEffect(() => {
    switch(modalType) {
      case "success":
        setOpenSuccessModal(true);
        break;
      case "warning":
        setOpenWarningModal(true);
        break;
      case "error":
        setOpenErrorModal(true);
        break;
      default:
        setOpenDeviceModal(modalType);
        break;
    }
  }, [modalType])


  const handleSuccess = () => {
    handleCloseConfigurationModal();
    setOpenSuccessModal(true);
  }

  const handleCloseConfigurationModal = (saved=true) => {
    if(!saved) {
      setTitle(handleTranslate('EXIT_WARNING'));
      setOpenWarningModal(true);
    }
    else {
      setOpenDeviceModal(null);
      onClose && onClose();
    }
  }

  const handleClose = (type) => {
    switch(type) {
      case "success": 
        setOpenSuccessModal(false);
        break;
      case "warning": 
        setOpenWarningModal(false);
        break;
      case "error": 
        setOpenErrorModal(false);
        break;
    }
    !openDeviceModal && onClose && onClose();
  }

  const handleConfirm = (type) => {
    handleClose(type);
    onConfirm && onConfirm();
    onClose && onClose();
  }

  return(
    <>
      <CreateStationModal isOpen={openDeviceModal === "create-station"} onSuccess={handleSuccess} onClose={handleCloseConfigurationModal}/>
      <CreateCuModal isOpen={openDeviceModal === "create-cu"} onSuccess={handleSuccess} onClose={handleCloseConfigurationModal}/>
      <CreateSensorModal isOpen={openDeviceModal === "create-sensor"} onSuccess={handleSuccess} onClose={handleCloseConfigurationModal}/>
      <StationConfigurationModal enabledActions={enabledActions} isOpen={openDeviceModal === "config-station"} stationId={device?.id} onSuccess={handleSuccess} onClose={handleCloseConfigurationModal}/>
      <CuConfigurationModal enabledActions={enabledActions} isOpen={openDeviceModal === "config-cu"} cuId={device?.id} onSuccess={handleSuccess} onClose={handleCloseConfigurationModal}/>
      <SensorConfigurationModal enabledActions={enabledActions} isOpen={openDeviceModal === "config-sensor"} sensorId={device?.id} onSuccess={handleSuccess} onClose={handleCloseConfigurationModal}/>
      <AddUnitModal unit={device} isOpen={openDeviceModal === "add-unit"} onClose={handleCloseConfigurationModal} /> 
      <AddMeasureToSensorModal measureId={device?.id} isOpen={openDeviceModal === "add-measure"} onClose={handleCloseConfigurationModal}/>
      <SuccessModal confirmButton isOpen={openSuccessModal} title={successText} onClose={() => handleClose("success")} onConfirm={() => handleConfirm("success")}/>
      <WarningModal title={title} isOpen={openWarningModal} onClose={() => handleClose("warning")} onConfirm={() => handleConfirm("warning")}/>
      <ErrorModal title={title} isOpen={openErrorModal} onClose={() => handleClose("error")} onConfirm={() => handleConfirm("error")}/>
    </>
  )
}

export default ConfigurationModals