import { Button, Modal } from '@mui/material'
import React, { useLayoutEffect, useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const containerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "1rem",
  borderRadius: "1rem",
  height: "fit-content",
  width: "95%",
  maxWidth: "400px",
  display: "flex",
  flexDirection: "column",
  gap: "3rem"
};


const ErrorModal = ({ style, defaultTitle, title, isOpen, onClose, onConfirm, confirmText = "Ok" }) => {

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const [titleText, setTitleText] = useState(defaultTitle ?? handleTranslate("SOMETHING_WENT_WRONG", true));

  useLayoutEffect(() => {
    title && setTitleText(title);
  }, [title])

  return (
    <Modal open={isOpen}>
      <div style={{ ...style, ...containerStyle }}>
        <ErrorIcon sx={{ width: "80px", height: "80px", color: "red", margin: "0 auto" }} fontSize='large' />
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}> {handleTranslate("ERROR", true)} </h2>
        <p style={{ textAlign: "center" }}>{titleText}</p>
        <Button fullWidth onClick={onConfirm} 
          // boxShadow: "0px 0px 14px 1px rgba(0,0,0,0.2)"
          > {confirmText}</Button>
      </div>
    </Modal>
  )
}

export default ErrorModal;