import { Box } from "@mui/system";
import SingleMultimediaSideBar from "./SingleMultimediaSideBar";
import { useCallback, useEffect, useMemo, useState } from "react";
import AxisRecording from "../SharingComponents/AxisRecording";
import SideBarContent from "./SideBarContent";
import axios from "axios";
import { deleteAxisRecording, getAxisRecordingInfo, getMongoFileInfo, setLoadingImageList, setLoadingRecord, setLoadingRecordDelete, setLoadingRecordExport, setLoadingRecordingList, startAxisRecording, stopAxisRecording } from "app/store/evocsSlice/multimedia/multimediaActions";
import { useSelector } from "react-redux";
import useMobile from "src/app/hooks/useMobile";
import { useLocation } from "react-router-dom";
import { getStationMeasures, getStationSensors } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { isMeasureMultimedia, isSensorMultimedia } from "app/store/evocsSlice/sensors/sensorsActions";
import useLoadingDevices from "src/app/hooks/useLoadingDevices";
import ImageGallery from "./ImageGallery";
import { bytesToSize } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import useActions from "src/app/hooks/useActions";

// 'Content-Type': 'video/x-matroska'
const viewName = viewNames.MULTIMEDIA_VIEW;
const cameraOptions = ["Camera 1", "Camera 2", "Camera 3", "Camera 4", "Quad"];
const resolutions = ["640x360", "1920x1080", "2560x1440"];
const quadResolutions = ["1280x720", "2560x1440"];
const defaultCamera= cameraOptions[0];

const getRecordVideoUrl = async (diskid, recordingid) => {
  if(!diskid || !recordingid) return;
  const params = {
    diskid: diskid,
    recordingid: recordingid,
  };
  const response = await axios.get("/api/py/stream/getAxisRecordingVideo", {
    params: params,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'video/mp4'
    }
  })
  const url = window.URL.createObjectURL(new Blob([response.data]));
  return url;
};

const getMongoFile = async(fileId, typeFile) => {
  const response = await fetch(`/api/py/stream/readMongoFile?fileId=${fileId}&typeFile=${typeFile}`);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const videoBlob = await response.blob();
  const videoUrl = URL.createObjectURL(videoBlob);
  return videoUrl;

  // const blob = new Blob([response]);
  // const url = window.URL.createObjectURL(blob);
  // return url;
};

const getVideoFileName = (starttime, stoptime, status=null) => {
  if(status === "recording") return "In registrazione";
  const startDate = new Date(starttime * 1000);
  const endDate = new Date(stoptime * 1000);
  const day = startDate.toLocaleDateString("it-IT", {
    timeZone: "Europe/Rome",
    day: "numeric",
  });
  const month = startDate.toLocaleDateString("it-IT", {
    timeZone: "Europe/Rome",
    month: "short",
  });
  const startTime = startDate.toLocaleTimeString("it-IT", {
    timeZone: "Europe/Rome",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });
  const endTime = endDate.toLocaleTimeString("it-IT", {
    timeZone: "Europe/Rome",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });
  return `${day} ${month} | ${startTime} - ${endTime}`;
};

const getImageFileName = (starttime) => {
  const startDate = new Date(starttime * 1000);
  const day = startDate.toLocaleDateString("it-IT", {
    timeZone: "Europe/Rome",
    day: "numeric",
  });
  const month = startDate.toLocaleDateString("it-IT", {
    timeZone: "Europe/Rome",
    month: "short",
  });
  const startTime = startDate.toLocaleTimeString("it-IT", {
    timeZone: "Europe/Rome",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });
  return `${day} ${month} | ${startTime}`;
};


const createVideoListElement = (element, isAxis=false) => {
  if(isAxis) {
    return {
      id: element.recordingid,
      displayId: element.recordingid,
      diskid: element.diskid,
      filename: getVideoFileName(element.starttime, element.stoptime, element.recordingstatus),
      source: element.source,
      videotype: element.videotype,
      resolution: element.resolution,
      // filesize: bytesToSize(element.fileSize),
      recordingstatus: element.recordingstatus,
      starttime: element.starttime,
      stoptime: element.stoptime,
      axis: true
    }
  }else {
    return {
      id: element._id,
      displayId: element.filename,
      filename: getVideoFileName(element.starttime, element.endtime),
      videotype: element.typeFile,
      resolution: element.resolution,
      filesize: bytesToSize(element.fileSize),
      starttime: element.starttime,
      stoptime: element.endtime,
      axis: false
    }
  }
};

const createImageListElement = (element) => {
  return {
    id: element._id,
    displayId: element.filename,
    filename: getImageFileName(element.starttime),
    typefile: element.typeFile,
    filesize: bytesToSize(element.fileSize),
    resolution: "1536×864", //element.resolution,
    starttime: element.starttime,
    axis: false
  }
};

const isStationSmartBuoy = (stationId) => {
  if(stationId === "65a7bce7e8b2f26afc70747c") return true;
  return false;
}

const SingleMultimediaContent = () => {
  const loadingDevices = useLoadingDevices();
  const user = useSelector((state) => state.user);
  const actions = useActions(viewName);
  const location = useLocation();
  const station = location.state.station;
  const [xs, sm] = useMobile();
  const loadingRecord = useSelector((state) => state.multimedia.loadingRecord);
  const [isSideBarOpen, setSideBarOpen] = useState();
  const [selectedRecording, setSelectedRecording] = useState(null);
  const [lastSelectedRecording, setLastSelectedRecording] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [lastVideoUrl, setLastVideoUrl] = useState(null);
  
  const [isLive, setLive] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(defaultCamera);
  const [cameraResolution, setCameraResolution] = useState(resolutions[1]);
  const [quadResolution, setQuadResolution] = useState(quadResolutions[0]);
  const [recordinglist, setRecordingList] = useState([]);
  const [imagelist, setImageList] = useState([]);
  const [resolutionList, setResolutionList] = useState(resolutions);
  const [isRecording, setRecording] = useState(false);
  const [recordingProfile, setRecordingProfile] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedTab, setSelectedTab] = useState("video");

  const multimediaTypes = useMemo(() => {
    const types = [];
    getStationMeasures(station.id).forEach((measureId) => {
      const multimedia = isMeasureMultimedia(measureId)
      if(multimedia) types.push(multimedia);
    });
    return types;
  }, [station])

  const fetchFileList = useCallback(async() => {
    if(isStationSmartBuoy(station?.id)) {
      const response = await getAxisRecordingInfo().then((res) => {
        const data = res.map((element) => createVideoListElement(element, true));
        return {
          list: data.filter((element) => element.recordingstatus !== "recording"),
          profile: data.find((element) => element.recordingstatus === "recording")
        }
      });
      return response;
    }else if(!loadingDevices) {
      const endDate = new Date().valueOf();
      const startDate = endDate - 86400000 * 30;
      const mediaSensors = getStationSensors(station.id).filter((sensorId) => isSensorMultimedia(sensorId)); 
      
      const response = await getMongoFileInfo(user.id, user.selectedTenant, mediaSensors, startDate, endDate, selectedTab).then((res) => {
        if(selectedTab === "video") {
          return res.map((element) => createVideoListElement(element));
        } else {
          return res.map((element) => createImageListElement(element));
        }
      });

      return response;
    }
  }, [user, loadingDevices, station, selectedTab]);
  

  useEffect(() => {
    if(!isStationSmartBuoy(station?.id)) return;
    setLive(true);
    setLoadingRecordingList(true);
    fetchFileList().then(({list, profile}) => {
      setRecordingList(list);
      if(profile) {
        profile.stoptime = null;
        setRecordingProfile(profile);
        setRecording(true);
      }
      setLoadingRecordingList(false);
    });
  }, []);

  useEffect(() => {
    if(loadingDevices || isStationSmartBuoy(station?.id)) return;
    if(selectedTab === "video") {
      setLoadingRecordingList(true);
      setSelectedImage(null);;
    }else {
      setLoadingImageList(true);
      setSelectedRecording(null);
    } 
    fetchFileList().then((res) => {
      if(selectedTab === "video") {
        setRecordingList(res);
        setLoadingRecordingList(false);
      } else {
        setImageList(res);
        setSelectedImage(res[0]);
        setLoadingImageList(false);
      }
    });
  }, [fetchFileList])

  useEffect(() => {
    if(!selectedRecording) {setLoadingRecord(null); setVideoUrl(null); return;}
    let canSet = true;
    if(selectedRecording.id === lastSelectedRecording?.id) {
      setVideoUrl(lastVideoUrl);
    }else {
      setVideoUrl(null);
      setLoadingRecord(selectedRecording.id);
      if(selectedRecording.axis) {
        getRecordVideoUrl(selectedRecording.diskid, selectedRecording.id).then((url) => {
          if(!canSet) return;
          setLoadingRecord(null);
          setVideoUrl(url);
        });
      }else {
        getMongoFile(selectedRecording.id, "video").then((url) => {
          if(!canSet) return;
          setVideoUrl(url);
          setLoadingRecord(null);
        });
      }
    }
    setLive(false);
    setLastSelectedRecording(selectedRecording);
    return () => canSet = false;
  }, [selectedRecording])

  useEffect(() => {
    if(videoUrl) setPlayVideo(true);
    else setPlayVideo(false);
    !isLive && videoUrl && setLastVideoUrl(videoUrl);
  }, [videoUrl]);

  useEffect(() => {
    if (isLive) {
      let cameraNumber = selectedCamera.split(" ")[1];
      if(!Number(cameraNumber)) cameraNumber = "quad";
      const resolution = selectedCamera === "Quad" ? quadResolution : cameraResolution;
      setSelectedRecording(null);
      setVideoUrl(`/stream-dj/api/getAxisCameraLiveStream?camera=${cameraNumber}&resolution=${resolution}`);
    };
  }, [isLive, selectedCamera, cameraResolution, quadResolution])

  useEffect(() => {
    if(selectedCamera !== "Quad") setResolutionList(resolutions)
    else setResolutionList(quadResolutions);
  }, [selectedCamera]);

  useEffect(() => {
    let newProfile = recordingProfile?.profile;
    if(isRecording && !newProfile) {
      let cameraNumber = selectedCamera.split(" ")[1];
      if(!Number(cameraNumber)) cameraNumber = "all";
      const resolution = selectedCamera === "Quad" ? quadResolution : cameraResolution;
      startAxisRecording(cameraNumber, undefined, resolution).then(({profile}) => {
        newProfile = profile;
        const newRec = {
          id: profile,
          source: selectedCamera,
          recordingstatus: "recording",
          starttime: Math.floor(new Date() / 1000),
          stoptime: null,
          resolution: selectedCamera !== "Quad" ?  cameraResolution : quadResolution,
          videotype: "H.264",
          diskid: "SD_DISK"
        }
        setRecordingProfile(newRec);
      })
    }
    
    return () => {
      newProfile && stopAxisRecording(newProfile).then(() => {
        getAxisRecordingInfo().then((res) => { 
          setRecordingProfile(null);
          setRecordingList(res)
        });
      })
    }
  }, [isRecording]);

  useEffect(() => {
    if(!recordingProfile) {
      setRecordingList((prev) => prev.filter((e) => e.recordingstatus !== "recording"));
    }else setRecordingList((prev) => [recordingProfile, ...prev])
  }, [recordingProfile])

  const handleChangeResolution = (value) => {
    if(selectedCamera !== "Quad") {
      setCameraResolution(value);
    }else {
      setQuadResolution(value);
    }
  };

  // const handlePlayRecord = (recording) => {
  //   setLive(false);
  //   if(recording.id === lastSelectedRecording?.id) {
  //     setVideoUrl(lastVideoUrl);
  //   }else {
  //     setVideoUrl(null);
  //     setLoadingRecord(recording.id);
  //     if(recording.axis) {
  //       getRecordVideoUrl(recording.diskid, recording.id).then((url) => {
  //         setVideoUrl(url);
  //         setLoadingRecord(null);
  //       });
  //     }else {
  //       getMongoFile(recording.id, "video").then((url) => {
  //         setVideoUrl(url);
  //         setLoadingRecord(null);
  //       });
  //     }
  //   }
  //   setSelectedRecording(recording);
  // };

  const handlePlayRecord = (recording) => {
    setSelectedRecording(recording);
  };

  const handleStopRecord = (recording) => {
    if(recording.recordingstatus === "recording") {
      setRecording(false);
      return;
    }
    setSelectedRecording(null);
    // setVideoUrl(null);
    // setPlayVideo(false);
  };

  const handleExportRecord = async (recording) => {
    let downloadUrl = recording.id === lastSelectedRecording?.id && lastVideoUrl;
    let videoName = "";
    if(!recording.axis) {
      videoName = recording.displayId;
      if(!downloadUrl) {
        setLoadingRecordExport(recording.id);
        downloadUrl = await getMongoFile(recording.id, "video");
        setLoadingRecordExport(null);
      }
    }else {
      videoName = recording.displayId + ".mkv";
      if(!downloadUrl) {
        setLoadingRecordExport(recording.id);
        downloadUrl = await getRecordVideoUrl(recording.diskid, recording.id);
        setLoadingRecordExport(null);
      }
    }
    if (!downloadUrl) return;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', videoName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDeleteRecord = async (recording) => {
    if(!recording.axis) return;
    setLoadingRecordDelete(recording.id);
    if(selectedRecording?.id === recording.id)
      handleStopRecord(recording);
    deleteAxisRecording(recording.id).then(() => {
      setRecordingList((prev) => prev.filter((e) => e.id !== recording.id));
      setLoadingRecordDelete(null);
    })
  };

  const handleChangeLive = (value) => {
    setLive(value);
    !value && setVideoUrl(null);
  };

  const getVideoDuration = () => {
    if(!selectedRecording) return null;
    return selectedRecording.stoptime - selectedRecording.starttime;
  };

  const handleScrollNextImages = () => {
    console.log("fetch data!");
    fetchFileList();
  };

  return (
    <Box sx={{display: "flex", height: "100%"}} padding={{ xs: "1rem", sm: "2rem 5rem" }} >
      <Box sx={{flexGrow: 1, display: "flex", alignItems: "center", height: "100%"}}>
        {selectedTab === "video" ?
          <AxisRecording height={xs || sm ? "50vh" : "75vh"} url={videoUrl} live={isLive} duration={getVideoDuration()} isLoading={loadingRecord} recording={isRecording} play={playVideo} onPlay={() => setPlayVideo(true)} onPause={() => setPlayVideo(false)} onRecording={setRecording}/>
          // <AxisRecording url={videoUrl} live={isLive} duration={getVideoDuration()} isLoading={loadingRecord} recording={isRecording} play={playVideo} onPlay={() => setPlayVideo(true)} onPause={() => setPlayVideo(false)} onRecording={setRecording}/>
        :
          <ImageGallery height={xs || sm ? "70%" : "100%"} selectedImage={selectedImage}/>
        }
      </Box>
      <SingleMultimediaSideBar isOpen={isSideBarOpen} onOpen={() => setSideBarOpen(true)} onClose={() => setSideBarOpen(false)}>
        <SideBarContent 
        actions={actions}
        live={isLive}
        selectedCamera={selectedCamera}
        cameraResolution={cameraResolution}
        quadResolution={quadResolution}
        selectedRecording={selectedRecording} 
        selectedImage={selectedImage} 
        resolutionList={resolutionList}
        recordinglist={recordinglist}
        imageList={imagelist}
        disableLiveOptions={!isStationSmartBuoy(station?.id)}
        multimediaTypes={multimediaTypes}
        onChangeTab={setSelectedTab}
        onPlayRecord={handlePlayRecord} 
        onStopRecord={handleStopRecord} 
        onExportRecord={handleExportRecord}
        onDeleteRecord={handleDeleteRecord}
        onChangeLive={handleChangeLive}
        onChangeCamera={setSelectedCamera}
        onChangeResolution={handleChangeResolution}
        onSelectImage={setSelectedImage}
        onScrollNextImages={handleScrollNextImages}
        />
      </SingleMultimediaSideBar>
    </Box>
  );
};

export default SingleMultimediaContent;
