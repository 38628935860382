import { useSelector } from "react-redux";
import AccordionComponent from "../SharingComponents/AccordionComponent";
import GraphSwitcher from "./GraphSwitcher";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import LoadingGif from "../FileManager/LoadingGif";
import useMongoData from "src/app/hooks/useMongoData";
import { viewNames } from "app/configs/routesNames";
import { Link } from "react-router-dom";
import { getStationSensors } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { LuInspect } from "react-icons/lu";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Carousel from "../SharingComponents/Carousel";
import useActions from "src/app/hooks/useActions";
import DownloadComponent from "../SharingComponents/DownloadComponent/DownloadComponent";
import { getCuMeasures } from "app/store/evocsSlice/controlUnits/cuActions";
import { isMeasureGps, isMeasureMultimedia, isSensorGps, isSensorMultimedia } from "app/store/evocsSlice/sensors/sensorsActions";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const viewName = viewNames.STATIONS_VIEW;

const StationItem = ({ stationId, station, measuresSelected, startDate, endDate, userActions }) => {
  const datasetLoading = useSelector((state) => state.dataset.loading);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);

  const [childrens, setChildrens] = useState([]);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  const allStationMeasures = useMemo(() => {
    const measures = [];
    getStationSensors(stationId).forEach((sensorId) => {
      sensors[sensorId]?.measures.forEach((measureId) => !isMeasureGps(measureId) && !isMeasureMultimedia(measureId) && measures.push({
        measureId: measureId,
        sensor: { sensorId, ...sensors[sensorId] }
      }));
    });
    return measures;
  }, [stationId])

  const newCuElement = useCallback((cuId, isCompare = false, selectedCompare = "", selectedSensor = null, selectedMeasure = null) => {
    return { id: cuId, isCompare, selectedCompare, selectedSensor, selectedMeasure };
  }, []);

  const newSensorElement = useCallback((sensorId, isCompare = false, selectedCompare = "", selectedMeasure = null) => {
    return { id: sensorId, isCompare, selectedCompare, selectedMeasure };
  }, []);

  useEffect(() => {
    const list = [];
    station.list_sensors.forEach((sensorId) => {
      if(sensors[sensorId]?.available === 1 && !isSensorGps(sensorId) && !isSensorMultimedia(sensorId)) {
        const childrenElement = childrens.find((element) => element.id === sensorId);
        if (!measuresSelected || measuresSelected.length === 0) {
          if (childrenElement) list.push(childrenElement);
          else list.push(newSensorElement(sensorId));
          return;
        }
        const measureSelected = measuresSelected.findLast((measureId) => sensors[sensorId]?.measures.includes(measureId))
        if(!measureSelected) return;
        if(childrenElement) {
          childrenElement.selectedMeasure = measureSelected;
          list.push(childrenElement);
        }
        else list.push(newSensorElement(sensorId, false, "", measureSelected));
      }
    });
    station.list_cu.forEach((cuId) => {
      if (controlUnits[cuId]?.available === 0) return;
      const childrenElement = childrens.find((element) => element.id === cuId);
      if (!measuresSelected || measuresSelected.length === 0) {
        if (childrenElement) list.push(childrenElement);
        else list.push(newCuElement(cuId));
        return;
      }
      const cuMeasures = getCuMeasures(cuId, true);
      const measureSelected = measuresSelected.findLast((measureId) => cuMeasures.includes(measureId))
      if (measureSelected) {
        const sensorSelected = controlUnits[cuId].list_sensors.find((sensorId) => sensors[sensorId]?.measures.includes(measureSelected));
        if (childrenElement) {
          childrenElement.selectedSensor = sensorSelected;
          childrenElement.selectedMeasure = measureSelected;
          list.push(childrenElement);
        } else
          list.push(newCuElement(cuId, false, "", sensorSelected, measureSelected));

      }
    });
    setChildrens(list);
  }, [measuresSelected])


  const handleChangeSensor = (unitId, sensorId) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedSensor = sensorId;
    setChildrens(newChildrens);
  }

  const handleChangeMeasure = (unitId, measureId) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedMeasure = measureId;
    setChildrens(newChildrens);
  }

  const handleChangeCompare = (unitId, isCompare) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].isCompare = isCompare;
    setChildrens(newChildrens);
  }

  const handleChangeSelectedCompare = (unitId, compare) => {
    const newChildrens = [...childrens];
    const unitIndex = childrens.findIndex((element) => element.id === unitId);
    newChildrens[unitIndex].selectedCompare = compare;
    setChildrens(newChildrens);
  }

  // useEffect(() => {
  //   console.log(station.serial + ": ", childrens)
  // }, [childrens])

  return childrens?.length > 0 && (
    <AccordionComponent
      title={
        <>
          <div style={{
            display: "flex", flexDirection: "row",
            alignItems: "baseline", gap: "1rem", flexWrap: "wrap"
          }}>
            <Link to={`/stations/${station.serial}`} state={{ stationId: stationId }}
              style={{ fontSize: "15px" }}>
              <div style={{
                display: "flex", flexDirection: "row",
                alignItems: "baseline", gap: "0.3rem"
              }}>
                <LuInspect color="#92c870" />
                {station.serial}
              </div>
            </Link>
            <DownloadComponent userActions={userActions} startDate={startDate} endDate={endDate} stationId={stationId} stationSerial={station.serial} />
          </div>
        </>
      }>

      {!datasetLoading ?
        <Carousel value={currentCarouselIndex} onChange={setCurrentCarouselIndex} disableArrows={false}>
          {childrens.map((unit) => (
            <GraphSwitcher key={unit.id} userActions={userActions} disableSensorFilter unit={unit} startDate={startDate} endDate={endDate}
              compareOptions={allStationMeasures} sensorGraphShadow={false} graphHeight="300px"
              onChangeSensor={handleChangeSensor} onChangeMeasure={handleChangeMeasure} onChangeCompare={handleChangeCompare} onChangeSelectedCompare={handleChangeSelectedCompare} />
          ))}
        </Carousel>
        : <LoadingGif />
      }
    </AccordionComponent>
  )
}

const StationHandlingList = memo(({ filtersInView, list }) => {
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const userActions = useActions(viewName);
  const stations = useSelector((state) => state.stationsMongo.list);

  const timeRangeInView = filtersInView?.timeRange;
  const measureSelect = filtersInView?.measure;

  const sensorsInStations = useMemo(() => {
    if (!availableStations) return {};
    return getStationSensors(availableStations);
  }, [availableStations]);

  // const sensorsInStations = useMemo(() => {
  //   if (!availableStations) return {};
  //   let obj = {};
  //   availableStations.forEach((stationId) => {
  //     obj[stationId] =  getStationSensors(stationId);
  //   });
  //   return obj;
  // }, [availableStations]);

  // const calcSensors = useMemo(() => {
  //   const sensors = [];
  //   list.forEach((stationId) => sensorsInStations[stationId].forEach((sensorId) => sensors.push(sensorId)));
  //   return sensors;
  // }, [list, timeRangeInView.startDate, timeRangeInView.endDate])

  useMongoData(timeRangeInView.startDate, timeRangeInView.endDate, sensorsInStations);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.STATIONS_VIEW);
  };

  return list && (
    <div style={{ minHeight: "50vh" }}>
      {list.length > 0 ?
        list.map((stationId) => (
          <StationItem key={stationId} stationId={stationId} station={stations[stationId]} measuresSelected={measureSelect.selected} startDate={timeRangeInView.startDate} endDate={timeRangeInView.endDate} userActions={userActions} />
        )) :
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center", color: "rgba(0, 0, 0, .5)" }} >
          <SentimentVeryDissatisfiedIcon sx={{ width: "70px", height: "70px" }} fontSize="large" />
          <h2>{handleTranslate('NO_ACTIVE_STATIONS')}</h2>
        </div>
      }
    </div>
  )
})

export default StationHandlingList