import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Dashboard",
    PIE_LEGEND_1: 'The chart represents the current status of the Evocs monitoring stations:',
    PIE_LEGEND_2: 'all sensors of the station are active and functioning', 
    PIE_LEGEND_3: 'the station\'s sensors are partially functioning',
    PIE_LEGEND_4: 'the station has no sensors installed',
    PIE_LEGEND_5: 'A sensor is considered non-functional if it is unable to send data to the platform',
};

const DASHBOARD_EN = createLanguageObject(EN, viewNames.DASHBOARD_VIEW);

export default DASHBOARD_EN;