import React, { useLayoutEffect } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import LanguageSelect from "../SharingComponents/Inputs/LanguageSelect";
import { updateUser } from "app/store/evocsSlice/user/userAction";
import AvatarImage from "../SharingComponents/Inputs/AvatarImage";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from "react-i18next";
import { translate } from "src/utilities/utils";

const defaultInputs = {
  proPic: {
    value: "",
    required: false,
  },
  email: {
    value: "",
    required: true,
  },
  username: {
    value: "",
    required: true,
  },
  language: {
    value: "",
    required: true,
  },
  // enabled: {
  //   value: undefined,
  //   required: true
  // },
};

const ConfigurationChip = ({ user, onConfirm }) => {
  const [inputs, setInputs] = useState(defaultInputs);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  useLayoutEffect(() => {
    console.log(user);
    let newInputs = { ...inputs };
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = user[key];
    });
    setInputs(newInputs);
  }, [user]);

  useEffect(() => {
    console.log(inputs);
    const disabled = Object.keys(inputs).some(
      (key) =>
        inputs[key].required &&
        (inputs[key].value === "" || inputs[key].value?.length === 0)
    );
    isInputsChanged && setButtonDisabled(disabled);
  }, [inputs]);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: { ...inputs[key], value: value } });
    setInputsChanged(true);
  };

  const handleConfirm = () => {
    updateUser(user.sqlId, inputs.language.value, inputs.proPic.value).then(
      (res) => {
        onConfirm && onConfirm(res);
        // let updatedUser = {};
        // Object.keys(inputs).forEach((key) => updatedUser[key] = inputs[key].value);
        // onConfirm && onConfirm(updatedUser);
      }
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        padding: "2rem",
      }}
    >
      <h2 style={{ fontWeight: "bold" }}>Utente: {user.username}</h2>
      <div>
        <label>Immagine utente</label>
        <AvatarImage
          value={inputs.proPic.value}
          onChange={(value) => handleChangeInput("proPic", value)}
        />
      </div>
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={"Email"}
        required={inputs.email.required}
        className="rounded-2xl"
        value={inputs.email.value}
        onChange={(e) => handleChangeInput("email", e.target.value)}
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={"Username"}
        required={inputs.username.required}
        className="rounded-2xl"
        value={inputs.username.value}
        onChange={(e) => handleChangeInput("username", e.target.value)}
      />
      <LanguageSelect
        required
        value={inputs.language.value}
        onChange={(value) => handleChangeInput("language", value)}
      />
      <Button
        sx={{
          width: "fit-content",
          margin: "0 auto",
          border: "1px solid black",
        }}
        disabled={buttonDisabled}
        onClick={handleConfirm}
      >
        {" "}
        {handleTranslate("CONFIRM", true)}{" "}
      </Button>
    </div>
  );
};

const containerStyle = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#2d3740",
  padding: "1rem",
  borderRadius: "1rem",
  height: "80vh",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column",
};

const UserConfigurationModal = ({
  isOpen = false,
  user,
  onClose,
  onConfirmCallback,
}) => {
  return (
    user && (
      <Modal open={isOpen}>
        <div style={containerStyle}>
          <div style={{ display: "flex" }}>
            <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
              <CloseIcon fontSize="small" sx={{ color: "white" }} />
            </IconButton>
          </div>
          <div
            style={{
              background: "white",
              borderRadius: "0 1rem 1rem 1rem",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <ConfigurationChip user={user} onConfirm={onConfirmCallback} />
          </div>
        </div>
      </Modal>
    )
  );
};

export default UserConfigurationModal;
