import { useEffect, useMemo, useState } from 'react';
import { Paper } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import useMobile from 'src/app/hooks/useMobile';


const VesselsGraph = ({portTraffic}) => {
  const [xs, sm] = useMobile();
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);

  
  const isMobile = useMemo(() => {
    if(xs || sm) return true;
    return false;
  }, [xs, sm])

  useEffect(() => {
    if(!portTraffic?.shipCounter) return;
    const newData = [], newLabels = [];
    Object.keys(portTraffic.shipCounter).forEach((key) => {
      newData.push(portTraffic.shipCounter[key]);
      newLabels.push(key);
    });
    setData(newData);
    setLabels(newLabels);
  }, [portTraffic])

  return (
    <Paper sx={{height: "100%", padding: "1rem"}}>
      <Doughnut data={{
        labels: labels,
        datasets: [{
          label: "Numero di navi",
          data: data,
          backgroundColor: [
            "rgba(224, 69, 58, 0.7)",
            "rgba(57, 189, 40, 0.7)",
            "rgba(171, 36, 179, 0.7)",
            "rgba(26, 130, 228, 0.7)",
            "rgba(153, 102, 255, 0.7)",
            "rgba(222, 198, 40, 0.7)",
            "rgba(95, 44, 222, 0.7)",
            "rgba(44, 92, 222, 0.7)",
            "rgba(38, 212, 151, 0.7)",
            "rgba(134, 212, 38, 0.7)"
          ],
          borderColor: [
            "rgb(224, 69, 58)",
            "rgb(57, 189, 40)",
            "rgb(171, 36, 179)",
            "rgb(26, 130, 228)",
            "rgb(153, 102, 255)",
            "rgb(222, 198, 40)",
            "rgb(95, 44, 222)",
            "rgb(44, 92, 222)",
            "rgb(38, 212, 151)",
            "rgb(134, 212, 38)"
          ],
          borderWidth: 1,
        }]
      }}
      options={{
        layout: {
          padding: 15
        },
        maintainAspectRatio: false,
        responsive: true,
        hoverOffset: 30,
        plugins: {
          title: {
            display: true,
            color: "black",
            text: "Navi del porto di Taranto",
            font: {
              size: 20,
              weight: 3,
            },
          },
          legend: {
            position: "bottom",
            maxHeight: 150,
            maxWidth: 115,
            align: "center",
            labels: {
              font: {
                size: 11,
              },
            },
          },
        },
      }}
      />
    </Paper>
  )
}

export default VesselsGraph