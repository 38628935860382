import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getSensorInfo = createAsyncThunk(
    "sensors/getSensorInfo",
    async (payload) => {
        const { tenantIds } = payload;
        const data = await axios.post("/api/py/readSensorInfo", {
            tenantIds: tenantIds
        } )
            .then((res) => {
                return res.data;
            }).catch((error) => {
                console.log("Error: ", error);
                throw error;
            });
        return data;
    }
);

const createSensor = createAsyncThunk(
    "sensors/createSensor",
        async (payload) => {
            const { serial, owner, sensorType, measures, available, stationId, cuId, thresholds } = payload;
            const data = await axios
            .post(`/api/py/createSensor`, {
                serial: serial,
                owner: owner,
                sensor_type: sensorType,
                measures: measures,
                available: available,
                stationId: stationId,
                cuId: cuId,
                threshold: thresholds
            })
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log("Error: ", error);
                throw error;
            });
            const sensorId = data._id;
            delete data._id;
            return {sensorId, sensor: data};
        }
);

const readSensor = createAsyncThunk(
    "sensors/readSensor",
        async (payload, { dispatch, getState }) => {
            const { sensorId } = payload;
            const body =
            {
                sensorId: sensorId,
            };
            const data = await axios
                .post(`/api/py/readSensor`, body)
                .then((res) => {
                    return res.data;
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
            return data;
        }
);

const updateSensor = createAsyncThunk(
    "sensors/updateSensor",
    async (payload) => {
        const {sensorId, serial, owner, sensorType, measures, available, stationId, cuId, uninstall, thresholds, operationTime} = payload;
        const data = await axios
        .post(`/api/py/updateSensor`, {
            sensorId: sensorId,
            serial: serial,
            owner: owner,
            sensor_type: sensorType,
            measure: measures,
            stationId: stationId,
            cuId: cuId,
            uninstall: uninstall,
            operationTime: operationTime,
            threshold: thresholds,
            available: available
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return data;
    }
);

const updateAndMoveSensor = createAsyncThunk(
    "sensors/updateAndMoveSensor",
    async (payload) => {
        const {sensorId, serial, owner, sensorType, measures, stationId, cuId} = payload;
        const data = await axios
        .post(`/api/py/updateSensor`, {
            sensorId: sensorId,
            serial: serial,
            owner: owner,
            sensor_type: sensorType,
            measure: measures
        })
        .then((res) => {
            console.log("Res: ", res);
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return data;
    }
);

const deleteSensor = createAsyncThunk(
    "sensors/deleteSensor",
    async (sensorId) => {
        const data = await axios
            .post(`/api/py/deleteSensor`, {
                sensorId: sensorId,
            })
            .then((res) => {
                return sensorId;
            })
            .catch((error) => {
                console.log("Error: ", error);
                throw error;
            });
        return data;
    }
);

const getHistory = createAsyncThunk(
    "sensors/getHistory",
    async (deviceDict) => {
        const data = await axios
            .post(`/api/py/readDeviceHistory`, {
                deviceDict: deviceDict
            })
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log("Error: ", error);
                throw error;
            });
        return data;
    }
);

const downloadHistory = createAsyncThunk(
    "sensors/updateHistory",
    async ({sensorId, form}) => {
      const data = await axios.post("/api/py/createHistoryFile", {
        deviceDict: {
          [sensorId]: "sensor"
        },
        form: form // csv o excel
      })
      .then((res) => {
        return res.data;
      }).catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
      return data;
    }
  );

const installSensor = createAsyncThunk(
    "sensors/readSensor",
    async (payload) => {
        const { sensorIds, deviceId, start, deviceType } = payload;
        const data = await axios
        .post(`/api/py/multipleInstallSensor`, {
            sensorIds: sensorIds,
            deviceId: deviceId,
            start: start,
            deviceType: deviceType // station || cu
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return {sensorIds, data};
    }
);

const uninstallSensor = createAsyncThunk(
    "sensors/uninstallSensor",
    async (payload) => {
        const { sensorId, end, parentType } = payload;
        const data = await axios
            .post(`/api/py/uninstallSensor`, {
                sensorId: sensorId,
                end: end,
                deviceParent: parentType // station || cu
            })
            .then((res) => {
                return sensorId;
            })
            .catch((error) => {
                console.log("Error: ", error);
                throw error;
            });
        return data;
    }
);

const moveSensor = createAsyncThunk(
  "sensors/moveSensor",
  async (payload) => {
    const {sensorId, sourceType, destinationId, destinationType, start} = payload;
    const data = await axios.post("/api/py/moveDevice", {
      deviceId : sensorId,
      deviceType : "sensor",
      sourceType : sourceType,
      destinationId : destinationId,
      destinationType : destinationType,
      starting_date: start
    })
    .then((res) => {
      return payload;
    }).catch((error) => {
      console.log("Error: ", error);
      throw error;
    });
    return data;
  }
);

const moveAndUpdateSensor = createAsyncThunk(
    "sensors/moveAndUpdateSensor",
    async (payload) => {
      const {sensorId, sourceType, destinationId, destinationType, start} = payload;
      const data = await axios.post("/api/py/moveAndUpdate", {
        deviceId : sensorId,
        deviceType : "sensor",
        sourceType : sourceType,
        destinationId : destinationId,
        destinationType : destinationType,
        starting_date: start,
        updateDict: null, // oggetto dell'update
      })
      .then((res) => {
        return payload;
      }).catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
      return data;
    }
  );

const createShareSensor = createAsyncThunk(
    "sensors/createShareSensor",
    async (dictShare) => {
        const data = await axios
            .post(`/api/py/createShareSensor`, {
                dictShare: dictShare
            })
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                console.log("Error: ", error);
                throw error;
            });
        return data;
    }
);

const updateShareSensor = createAsyncThunk(
    "sensors/updateShareSensor",
    async (payload) => {
        const {sensorId, tenantsList} = payload;
        const data = await axios
        .post(`/api/py/updateShareSensor`, {
            dictShare: {
                [sensorId]: tenantsList
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return data;
    }
);

const readAliveSensors = createAsyncThunk(
    "sensors/readAliveSensors",
    async (payload) => {
        const {tenantId, stationIds, cuIds, sensorIds, available, interval} = payload;
        const data = await axios
        .post(`/api/py/readAliveSensors`, {
            tenantId: tenantId,
            stationIds: stationIds,
            // cuIds: cuIds,
            // sensorIds: sensorIds,
            available: available,
            // intervalToCheck: interval,
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return data;
    }
);

const getThreshold = createAsyncThunk(
  "sensors/getThreshold",
  async (payload) => {
    const {tenantId, sensorIds} = payload;
    const data = await axios.post("api/py/readThreshold", {
      tenantId: tenantId,
      sensorIds: sensorIds,
      stationIds: [],
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      throw error;
    });
    return data;
  }
)

const updateThreshold = createAsyncThunk(
    "sensor/updateThreshold",
    async (payload) => {
        const {tenantId, sensorId, measureId, min, max} = payload;
        const data = await axios
        .post(`/api/py/updateThreshold`, {
            tenantId: tenantId,
            updateDict: {
                [sensorId]: {
                    threshold: {
                        [measureId]: {
                            min: min,
                            max: max
                        }
                    }
                }
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return data;
    }
);

const updateHistory = createAsyncThunk(
    "sensors/updateHistory",
    async (payload) => {
        const {sensorId, deviceId, deviceType, startDate, endDate} = payload;
        const data = await axios
        .post(`/api/py/updateHistory`, {
            sensorId: sensorId,
            deviceId: deviceId,
            containerType: deviceType,
            startDate: startDate, 
            endDate: endDate
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            console.log("Error: ", error);
            throw error;
        });
        return data;
    }
);

const getLastPosition = createAsyncThunk(
    "sensors/readLastSensorsPosition",
    async (payload) => {
      const {sensorIds, startDate, endDate} = payload;
      const data = await axios
      .post(`/api/py/readLastSensorsPosition`, {
        sensorIds: sensorIds,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
      return data;
    }
);

const getCorrelationMatrix = createAsyncThunk(
    "sensors/getCorrelationMatrix",
    async (payload) => {
      const {sensorId, startDate, endDate} = payload;
      const data = await axios
      .post(`/api/py/calcCorrelationMatrix`, {
        sensorId: sensorId,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        return "data:image/png;base64," + res.data[sensorId];
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw error;
      });
      return data;
    }
);

const sensorSlice = createSlice({
    name: "sensors",
    initialState: {
        list: {},
        installed: [],
        uninstalled: [],
        loading: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSensorState: (state, action) => {
            const {id, sensor} = action.payload;
            state.list[id] = sensor;
        },
        installOnDevice: (state, action) => {
            const {sensorId, device, type, start} = action.payload;
            state.list[sensorId].deviceParent = {device, type, start};
            state.uninstalled = state.uninstalled.filter((id) => id !== sensorId);
            state.installed.push(sensorId);
        },
        uninstallFromParent: (state, action) => {
            const sensorId = action.payload;
            delete state.list[sensorId].deviceParent;
            state.installed= state.installed.filter((id) => id !== sensorId);
            state.uninstalled.push(sensorId);
        },
        addInstalledSensor: (state, action) => {
            const sensorId = action.payload;
            const index = state.uninstalled.indexOf(sensorId);
            state.uninstalled = state.uninstalled.toSpliced(index, 1);
            state.installed.push(sensorId);
        },
        addUninstalledSensor: (state, action) => {
            const sensorId = action.payload;
            const index = state.installed.indexOf(sensorId);
            state.installed = state.installed.toSpliced(index, 1);
            state.uninstalled.push(sensorId);
        },
        activateSensor: (state, action) => {
            const sensorId = action.payload;
            state.list[sensorId].available = 1;
        },
        deactivateSensor: (state, action) => {
            const sensorId = action.payload;
            state.list[sensorId].available = 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSensorInfo.fulfilled, (state, action) => {
            const { list, installed, uninstalled } = action.payload;
            state.list = list || {};
            state.installed = installed || [];
            state.uninstalled = uninstalled || [];
        });
        builder.addCase(createSensor.fulfilled, (state, action) => {
            const {sensorId, sensor} = action.payload;
            state.list[sensorId] = sensor;
            if(sensor.deviceParent) state.installed.push(sensorId);
            else state.uninstalled.push(sensorId);
        });
        builder.addCase(deleteSensor.fulfilled, (state, action) => {
            const sensorId = action.payload;
            if(state.list[sensorId].deviceParent)state.installed = state.installed.filter((id) => id !== sensorId);
            else state.uninstalled = state.uninstalled.filter((id) => id !== sensorId);
            delete state.list[sensorId];
        });
        builder.addCase(installSensor.fulfilled, (state, action) => {
            const {sensorIds, data} = action.payload;
            sensorIds.forEach((sensorId) => {
                state.list[sensorId] = data[sensorId];
                state.uninstalled = state.uninstalled.filter((id) => id!== sensorId);
                state.installed.push(sensorId);
            })
        });
        builder.addCase(uninstallSensor.fulfilled, (state, action) => {
            const sensorId = action.payload;
            delete state.list[sensorId].deviceParent;
            state.installed = state.installed.filter((id) => id!== sensorId);
            state.uninstalled.push(sensorId);
        });
        builder.addCase(moveSensor.fulfilled, (state, action) => {
            const {sensorId, destinationId, destinationType, start} = action.payload;
            state.list[sensorId].deviceParent = {
                device: destinationId,
                type: destinationType,
                start: start
            }
        });
        builder.addCase(updateThreshold.fulfilled, (state, action) => {
            const sensorObj = action.payload[0];
            const sensorId = Object.keys(sensorObj)[0];
            state.list[sensorId] = sensorObj[sensorId];
        });
    }
});

export const sensorsSliceActions = {
    ...sensorSlice.actions,
    getSensorInfo, 
    createSensor,
    updateSensor,
    deleteSensor, 
    installSensor,
    uninstallSensor,
    getHistory, 
    downloadHistory,
    moveSensor,
    createShareSensor,
    updateShareSensor,
    readAliveSensors,
    getThreshold,
    updateThreshold,
    updateHistory,
    getLastPosition,
    getCorrelationMatrix
}

export default sensorSlice.reducer;