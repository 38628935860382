import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  LayersControl,
  LayerGroup,
  WMSTileLayer,
  ImageOverlay,
} from "react-leaflet";
import { Icon, divIcon, point } from "leaflet";
import { useEffect, useLayoutEffect, useMemo, useState, useRef, isValidElement } from "react";
import { TARANTO_COORDS } from "app/store/evocsSlice/maps/utils";
import MarkerClusterGroup from "react-leaflet-cluster";
import { FullscreenControl } from "react-leaflet-fullscreen";
import L from "leaflet";
import "leaflet-easyprint";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Box
} from "@mui/material";
import useMobile from "src/app/hooks/useMobile";
import { GoGraph } from "react-icons/go";
import { useSelector } from "react-redux";
import NoDataMessage from "./NoDataMessage";
import { useNavigate } from "react-router-dom";
import { routesNames, viewNames } from "app/configs/routesNames";
import CopernicusLogo from "src/app/asset/images/copernicus.png";
import ReactDOMServer from 'react-dom/server';
import defaultMarker from 'src/app/asset/markers/DefaultMarker.png'
import LeafletVelocity from "./LeafletVelocity";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const createClusterCustomIcon = (cluster, clusterColor) => {

  const styledIcon = (
    <span className="custom-marker-cluster" style={{ border: `5px solid ${clusterColor}` }}>
      {cluster.getChildCount()}
    </span>
  )

  return divIcon({
    html: ReactDOMServer.renderToString(styledIcon),
    iconSize: point(33, 33, true),
  });
};

const iconStyle = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  fontSize: 20
};

const createBaseMarkerIcon = (icon, iconType) => {

  const customIcon = () => {
    return (
      <div style={{ position: "relative" }}>
        <div style={iconStyle}>
          {iconType}
        </div>
        <img src={icon || defaultMarker} style={{ width: 50, height: 50 }} />
      </div>
    )
  }
  return (ReactDOMServer.renderToString(customIcon()))
};

const CustomMarker = ({
  marekerId,
  marker,
  selected = false,
  onClick,
  popupContent,
  popupTableContent,
  isFlying,
}) => {

  const markerRef = useRef();
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };
  const customIcon = useMemo(() => {
    if (!isValidElement(marker.icon)) {
      // return createBaseMarkerIcon(marker.icon);
      return divIcon({
        html: createBaseMarkerIcon(marker.icon, marker.iconType),
      });
      // return new Icon({
      //   iconUrl: marker.icon || tilebytesMarker,
      //   iconSize: marker.size || [40, 40],
      //   className: "mapMarker",
      // });
    } else {
      return divIcon({
        html: ReactDOMServer.renderToString(marker.icon),
      });
    }
  }, [marker.icon]);
  const [xs, sm, md] = useMobile();

  useEffect(() => {
    if (selected) {
      !isFlying && markerRef?.current?.openPopup();
    } else {
      markerRef?.current?.closePopup();
    }
  }, [isFlying, selected]);

  const AlertTable = ({ stationId, stationSerial }) => {
    if (!popupTableContent) return null;

    const alertCategories = useSelector(
      (state) => state?.configuration?.constants?.categoryAlert
    );
    const alertTypes = useSelector(
      (state) => state?.configuration?.constants?.alert
    );
    const [tableContent, setTableContent] = useState();
    const dataCatId = "65f2df23a2a783c7dd07f623";
    const maintanceCatId = "65f2df23a2a783c7dd07f622";
    const sharingCatId = "65f2df23a2a783c7dd07f624";
    const othersCatId = "65f2df23a2a783c7dd07f625";
    const [activeTab, setActiveTab] = useState(dataCatId);
    const navigate = useNavigate();

    const dataAlertColumns = [
      "categoryAlert",
      "data",
      "tipo alert",
      "device",
      "misura",
      "valore",
      "unita di misura",
      "soglia massima",
      "soglia minima",
      "n superamenti",
      "tipo device",
      "_id",
    ];
    const maintanceAlertColumns = [
      "categoryAlert",
      "data",
      "tipo alert",
      "tenant",
      "_id",
    ];
    const sharingAlertColumns = [
      "categoryAlert",
      "data",
      "tipo alert",
      "tenant",
      "ha condiviso con",
      "_id",
    ];

    useEffect(() => {
      if (!popupTableContent) return;
      if (tableContent) return;

      let dataAlert = popupTableContent
        .filter((alert) => alert["categoryId"] === dataCatId)
        .map((alert) => dataAlertColumns.map((key) => alert[key]));

      const maintanceAlert = popupTableContent
        .filter((alert) => alert["categoryId"] === maintanceCatId)
        .map((alert) => maintanceAlertColumns.map((key) => alert[key]));

      const sharingAlert = popupTableContent
        .filter((alert) => alert["categoryId"] === sharingCatId)
        .map((alert) => sharingAlertColumns.map((key) => alert[key]));

      const tableContentTemp = {
        [dataCatId]: dataAlert,
        [maintanceCatId]: maintanceAlert,
        [sharingCatId]: sharingAlert,
      };

      setTableContent(tableContentTemp);
    }, []);

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const columns = (key) => {
      switch (key) {
        case dataCatId: //dataCategory
          return dataAlertColumns;
        case maintanceCatId: //maintanceAlert
          return maintanceAlertColumns;
        case sharingCatId: //sharingAlert
          return sharingAlertColumns;
        default:
          return [];
      }
    };

    const capitalize = (word) => {
      if (!word) return word;
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    };

    const handleGotoGraph = (date) => {
      const timestamp = Math.floor(new Date(date).valueOf() / 1000) * 1000;
      console.log(date, timestamp);
      navigate(`${routesNames.STATIONS_VIEW}/${stationSerial}`, {
        state: {
          stationId: stationId,
          startDate: timestamp - 3600 * 1000,
          endDate: timestamp + 3600 * 1000,
        }
      });
    };

    return (
      <>
        {tableContent && (
          <>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="alert tabs"
            >
              {Object.keys(tableContent).map((key) => (
                <Tab
                  label={handleTranslate(capitalize(alertCategories[key].categoryAlert), true)}
                  value={key}
                  key={key}
                />
              ))}
            </Tabs>
            {Object.keys(tableContent).map(
              (key) =>
                activeTab === key &&
                (tableContent[key].length > 0 ? (
                  <TableContainer component={Paper} key={key}>
                    <Table size="small" aria-label="responsive table">
                      <TableHead>
                        <TableRow>
                          {columns(key).map((column, index) => (
                            <TableCell key={column + "_" + index} sx={{ fontWeight: "bold" }}>
                              {index == 0 ? "" : column}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableContent[key].map((row, index) => (
                          <TableRow key={row._id}>
                            {row.map((colValue, index) => (
                              <TableCell key={index}>
                                {index === 0 && (
                                  <Button onClick={() => handleGotoGraph(row[1])}>
                                    <GoGraph fontSize={16} />
                                  </Button>
                                )}
                                {index === 2 && alertTypes[colValue]?.title}
                                {index !== 0 && index !== 2 && colValue}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoDataMessage message="No alerts" />
                ))
            )}
          </>
        )}
      </>
    );
  };

  const PopupContent = ({ }) => {
    const stationSerial = useSelector(
      (state) => state?.stationsMongo?.list?.[marker?.stationId]?.serial
    );

    const valueDisplay = (key) => {
      if (!popupContent) return;
      let value;
      if (!popupContent[key].value) value = popupContent[key]
      else value = popupContent[key].value;
      if (Array.isArray(value)) {
        return value.join(", ");
      } else {
        return value;
      }
    };


    const { t } = useTranslation("evocs");
    const handleTranslate = (textId, general = false) => {
      if (general) return translate(t, textId);
      return translate(t, textId, viewNames.DASHBOARD_VIEW);
    };

    if (popupTableContent) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            overflow: "auto",
          }}
          width={xs ? 250 : sm ? 350 : md ? 550 : 1000}
          height={xs ? 350 : 400}
        >
          <Typography
            gutterBottom
            fontSize={xs ? "16px" : "18px"}
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Alert  {handleTranslate("STATION", true)} {stationSerial}
          </Typography>
          <div
            style={{
              margin: "0.5rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <label
              style={{
                fontSize: `${xs ? "12px" : "14px"}`,
                fontWeight: "bold",
              }}
            >
              Latitudine:{" "}
              <span style={{ fontWeight: "normal" }}>{marker.lat} </span>
            </label>
            <label
              style={{
                fontSize: `${xs ? "12px" : "14px"}`,
                fontWeight: "bold",
              }}
            >
              Longitudine:
              <span style={{ fontWeight: "normal" }}> {marker.lon}</span>
            </label>
          </div>
          <AlertTable stationId={marker.stationId} stationSerial={stationSerial} />
        </Box>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "200px",
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {popupContent?.title && (
            <h2 style={{ textAlign: "center" }}>{popupContent.title}</h2>
          )}
          <label sx> {handleTranslate('MEASURE_LAT', true)} : {marker.lat}</label>
          <label> {handleTranslate('MEASURE_LON', true)} : {marker.lon}</label>
          {popupContent &&
            Object.keys(popupContent).map(
              (key, index) =>
                key !== "title" &&
                key != "id" &&
                (popupContent[key].label ? (
                  <label key={index}>
                    {popupContent[key].label}: {valueDisplay(key)}
                  </label>
                ) : popupContent[key].title ? (
                  <h3 key={index}>{popupContent[key].title}</h3>
                ) : (
                  <label key={index}>
                    {key}: {valueDisplay(key)}
                  </label>
                ))
            )}
        </div>
      );
    }
  };

  return (
    <Marker
      ref={markerRef}
      position={[marker.lat, marker.lon]}
      icon={customIcon}
      divI
      eventHandlers={{
        click: (e) => {
          onClick(marekerId, marker);
        },
      }}
    >
      <Popup
        closeButton={false}
        maxWidth={"70vw"}
      // maxHeight={'60%'}
      // maxWidth={xs ? 300 : sm ? 600 : md ? 900 : 1200}
      // maxHeight={xs ? 300 : sm ? 400 : md ? 500 : 700}
      >
        <PopupContent />
      </Popup>
    </Marker>
  );
};

const SetView = ({
  coords,
  zoom,
  autoZoom,
  onClosePopup,
  onDoubleClick,
  onFlying,
  onFlyingEnd,
}) => {
  const map = useMap();

  useEffect(() => {
    if (!coords) return;
    const mapZoom = map.getZoom();
    const newZoom = zoom ? zoom : (!autoZoom ? mapZoom : mapZoom < 12 ? 12 : mapZoom)
    map?.flyTo(coords, newZoom);
    onFlying && onFlying();
    map?.once("moveend", onFlyingEnd);
  }, [coords]);

  useEffect(() => {
    const onPopupClose = () => {
      onClosePopup && onClosePopup();
    };
    const handleDoubleClick = (e) => {
      onDoubleClick && onDoubleClick(e);
    };

    map?.addEventListener("dblclick", handleDoubleClick);
    map?.addEventListener("click", onPopupClose);

    return () => {
      map?.removeEventListener("click", onPopupClose);
      map?.removeEventListener("click", handleDoubleClick);
    };
  }, [onClosePopup, onDoubleClick]);

  return null;
};

function MapPrint(props) {
  const map = useMap();
  useEffect(() => {
    const control = L.easyPrint({
      ...props,
    });
    map.addControl(control);
    return () => {
      map.removeControl(control);
    };
  }, [map]);

  return null;
}

const EcmwfOverlay = (payload) => {
  const { name, layer } = payload;

  return (
    <LayersControl.Overlay name={name}>
      <LayerGroup>
        <WMSTileLayer
          url="http://eccharts.ecmwf.int/wms/?"
          layers={layer}
          format="image/png"
          transparent={true}
          opacity={0.6}
          zIndex={9000}
          params={{
            token: "public",
            request: "GetMap",
            bbox: "-90,-180,90,180",
          }}
        />
      </LayerGroup>
    </LayersControl.Overlay>
  );
};
const ProtCivOverlay = (payload) => {
  const { name, layer, opacity = 0.6 } = payload;

  return (
    <LayersControl.Overlay name={name}>
      <LayerGroup>
        <WMSTileLayer
          url="https://radar-geowebcache.protezionecivile.it/service/wms?VERSION=1.1.1&TILED=true"
          layers={layer}
          format="image/png"
          transparent={true}
          opacity={opacity}
          zIndex={9000}
          params={{
            // token: "public",
            // request: "GetMap",
            // bbox: "-90,-180,90,180",
            name: "WMI",
            overlay: true,
            control: true,
          }}
        />
      </LayerGroup>
    </LayersControl.Overlay>
  );
};


const customControlStyleBottomLeft = {
  position: 'absolute',
  bottom: '5px',
  left: '0px',
  border: 'none',
  zIndex: 1000,
};

const CustomControl = () => {
  return (
    <div style={customControlStyleBottomLeft}>
      {/* <p>Questo è un polarplot ricavato dall'integrazione di dati VOC e meteorologici delle ultime 24 ore.
        Dal <label style={{ color: 'blue' }}>blu</label> (più bassa) al <label style={{ color: 'red' }}>rosso</label> (più alta), 
        si rappresenta una probabilità crescente di trovare la fonte dell'inquinamento!</p> */}
      <img src={CopernicusLogo} style={{ width: "80px", height: "40px", border: "none" }}></img>
    </div>
  );
};

const getImgBounds = (lat, lon) => {
  if (!lat || !lon) return null;
  // console.log('getImgBounds: ', lat, lon);
  const soglia = 0.009;
  const imageBounds = [
    [(lat - soglia), (lon + soglia + 0.0025)], //sw (angolo in basso a sx)
    [(lat + soglia), (lon - soglia - 0.0025)] //ne (angolo in alto a dx)
  ];
  return imageBounds;
}

const MapComponent = ({
  style = {},
  center,
  defaultCenter = TARANTO_COORDS,
  zoom,
  defaultZoom = 12,
  markers = [],
  scrollWheelZoom,
  defaultScrollWheelZoom = true,
  selectedMarker,
  onClickMarker,
  onDoubleClick,
  popupsContent,
  popupsTableContent,
  onClosePopup,
  overlays = [],
  cluster = false,
  clusterColor = "#92c870",
  children,
  height = null,
  fullscreen = true,
  print = true,
  download = true,
  copernicusLogo = true,
}) => {
  const [centerPos, setCenter] = useState(defaultCenter);
  const [zoomVal, setZoom] = useState();
  const [scroll, setScroll] = useState(defaultScrollWheelZoom);
  const [selected, setSelected] = useState();
  const [isFlying, setIsFlying] = useState(false);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  const zoomInPath = "/assets/mapIcon/zoomIn.png";
  const sceenSizeIcon = `<img src=${zoomInPath} alt='full screen control' width=500 height=600>`;
  // const sceenSizeIcon = `<img src=${isFullScreen ? minimizeScreenPath : fullScreenPath} alt='full screen control' width=500 height=600>`

  const emwfLayers = [
    { name: "<span>CO<sub>2</sub></span>", layer: "composition_co2_surface" },
    { name: "<span>PM<sub>25</sub></span>", layer: "composition_pm2p5" },
    { name: "<span>PM<sub>10</sub></span>", layer: "composition_europe_pm10_analysis_surface" },
    { name: "<span>NO<sub>2</sub></span>", layer: "composition_no2_surface" },
    { name: "<span>O<sub>3</sub></span>", layer: "composition_o3_surface" },
    { name: "<span>CO</span>", layer: "composition_co_surface" },
    { name: "<span>nmV</span>", layer: "composition_europe_nmvoc_forecast_surface" },
    // { name: "Mean sea level", layer: "msl" },
  ];
  const protCivLayers = [
    { name: handleTranslate('MEASURE_PP', true), layer: "radar:vmi" },
    { name: handleTranslate('MEASURE_TEMP', true), layer: "radar:temperature" },
  ];

  const trafficLayer = {
    name: handleTranslate('TRAFFIC', true),
    layer: 'https://mt1.google.com/vt?lyrs=h@159000000,traffic|seconds_into_week:-1&style=3&x={x}&y={y}&z={z}',
  }

  useLayoutEffect(() => {
    setCenter(center);
  }, [center]);

  useLayoutEffect(() => {
    zoom && setZoom(zoom);
  }, [zoom]);

  useLayoutEffect(() => {
    scrollWheelZoom !== undefined && setScroll(scrollWheelZoom);
  }, [scrollWheelZoom]);

  useLayoutEffect(() => {
    selectedMarker !== undefined && setSelected(selectedMarker);
  }, [selectedMarker]);

  const handleClickMarker = (m, popupContent, index) => {
    let popup;
    if (popupContent) {
      popup = popupContent;
    } else {
      const index = popupsContent?.findIndex((e) => e.id === m.id);
      popup = popupsContent?.[index];
    }
    onClickMarker && onClickMarker(m, popup, index);
  };

  const layerControlRef = useRef();

  return (
    <Box sx={style} height={height || { xs: "400px", sm: "500px" }}>
      <MapContainer
        className="card"
        style={{ height: "100%", maxHeight: "100%" }}
        center={defaultCenter}
        zoom={defaultZoom}
        doubleClickZoom={false}
        scrollWheelZoom={scroll}
      >
        <LayersControl position="topright" ref={layerControlRef}>
          <LayersControl.BaseLayer checked name="Satellite">
            <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              tileSize={256}
              noWrap={true}
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Street v1">
            <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Street v2">
            <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Openstreetmap">
            <TileLayer
              attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>

          {overlays.map((element, i) => (
            <LayersControl.Overlay
              key={element.id}
              checked={element.checked}
              name={element.name}
            >
              <LayerGroup>
                {element.markers?.map((m) => (
                  <CustomMarker
                    key={m.id}
                    marker={m}
                    selected={m.id === selected}
                    onClick={() => handleClickMarker(m)}
                    popupContent={
                      popupsContent?.[
                      popupsContent.findIndex((e) => e.id === m.id)
                      ]
                    }
                  />
                ))}
                {element.imageOverlays?.map((image, index) => (
                  <ImageOverlay
                    key={index}
                    url={image?.url}
                    bounds={getImgBounds(image?.lat, image?.lon)}
                    className="polarMarker"
                    interactive={true}
                    // onMouseOver={handleMouseOverImage}
                    // onMouseOut={handleMouseOutImage}
                    eventHandlers={{ mouseover: () => element.onHover(index), mouseout: () => element.onBlur(index) }}
                  />
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          ))}

          {emwfLayers.map((element) => (
            <EcmwfOverlay
              key={element.name}
              name={element.name}
              layer={element.layer}
            />
          ))}

          {protCivLayers.map((element) => (
            <ProtCivOverlay
              key={element.name}
              name={element.name}
              layer={element.layer}
              opacity={0.8}
            />
          ))}

          <LayersControl.Overlay name={trafficLayer.name}>
            <WMSTileLayer
              url={trafficLayer.layer}
              layers="traffic"
            />
          </LayersControl.Overlay>

          {cluster ? (
            <MarkerClusterGroup
              disableClusteringAtZoom={12}
              iconCreateFunction={(event) => createClusterCustomIcon(event, clusterColor)}
              polygonOptions={{
                fillColor: clusterColor,
                color: clusterColor,
                weight: 2,
                opacity: 1,
                fillOpacity: 0.3,
              }}
            >
              {markers?.map((m, index) => (
                <CustomMarker
                  key={index}
                  marker={m}
                  selected={index === selected}
                  onClick={() =>
                    handleClickMarker(m, popupsContent?.[index], index)
                  }
                  popupContent={popupsContent?.[index]}
                  isFlying={isFlying}
                />
              ))}
            </MarkerClusterGroup>
          ) : (
            markers?.map((m, index) => (
              <CustomMarker
                key={index}
                marker={m}
                selected={index === selected}
                onClick={() => {
                  const popupContentToPass = popupsContent
                    ? popupsContent?.[index]
                    : popupsTableContent
                      ? popupsTableContent?.[index]
                      : null;
                  handleClickMarker(m, popupContentToPass, index);
                }}
                popupContent={
                  popupsContent ? popupsContent?.[index] : undefined
                }
                popupTableContent={
                  popupsTableContent ? popupsTableContent?.[index] : undefined
                }
              />
            ))
          )}
        </LayersControl>
        <LeafletVelocity ref={layerControlRef} />
        <SetView
          coords={centerPos}
          zoom={zoomVal}
          autoZoom={cluster}
          onClosePopup={onClosePopup}
          onDoubleClick={onDoubleClick}
          onFlying={() => setIsFlying(true)}
          onFlyingEnd={() => setIsFlying(false)}
        />
        {children}

        {print && (
          <MapPrint
            position="topleft"
            sizeModes={["A4Portrait", "A4Landscape"]}
            hideControlContainer={false}
            title="Print"
          />
        )}
        {download && (
          <MapPrint
            position="topleft"
            sizeModes={["A4Portrait", "A4Landscape"]}
            hideControlContainer={false}
            title="Export as PNG"
            exportOnly
          />
        )}
        {fullscreen && (
          <FullscreenControl
            forceSeparateButton={true}
            position="topleft"
            content={sceenSizeIcon}
          />
        )}

        {copernicusLogo && <CustomControl />}
      </MapContainer>
    </Box>
  );
};

export default MapComponent;
