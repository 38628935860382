import { useSelector } from "react-redux"
import AccordionComponent from "../SharingComponents/AccordionComponent";
import { Box } from "@mui/system";
import { Button, IconButton, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { getUsers } from "app/store/evocsSlice/user/userAction";
import TableComponent from "../SharingComponents/TableComponent";
import UserConfigurationModal from "../Modals/UserConfigurationModal";
import CreateUserModal from "../Modals/CreateUserModal";
import EditIcon from "@mui/icons-material/Edit";
import SuccessModal from "../Modals/SuccessModal";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const UserTable = ({ tenantId, users, enabledActions, selectedRows, onSelectRow, onUpdateUser }) => {
  const [data, setData] = useState({})
  const [selectedUser, setSelectedUser] = useState();
  const [openModal, setOpenModal] = useState(false);

  useLayoutEffect(() => {
    setData(() =>
      users?.map((element) => {
        const newElement = { ...element };
        delete newElement.labelValue; delete newElement.loginError; delete newElement.session;
        return newElement;
      })
    )
  }, [users]);


  const handleClickRow = (rowIndex) => {
    setSelectedUser(users[rowIndex]);
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const handleUpdateUser = (updatedUser) => {
    onUpdateUser && onUpdateUser(selectedUser.id, updatedUser);
    handleCloseModal();
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };

  const tableColumns = {
    sqlId: "Sql Id",
    email: "Email",
    username: "Username",
    // loginSuccess: "Ultimo accesso",
    // passwordChanged: "Ultima modifica password",
    language: handleTranslate("LANGUAGE", true),
  };



  return (
    <>
      <TableComponent tableTitle={"Utenti"} columns={tableColumns} data={data} clickable={enabledActions.includes("update-info-user")} selectable={enabledActions.includes("update-info-user")}
        selectedRows={selectedRows} onClickRow={handleClickRow} onSelectRows={onSelectRow} />
      <UserConfigurationModal isOpen={openModal} user={selectedUser} onClose={handleCloseModal} onConfirmCallback={handleUpdateUser} />
    </>
  )

}

const SingleTenant = ({ tenantId, enabledActions }) => {
  const tenants = useSelector((state) => state.tenants.list);
  const [users, setUsers] = useState([]);
  const [tableUsers, setTableUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [edit, setEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [selectedTableRows, setSelectedTableRow] = useState([]);


  useEffect(() => {
    getUsers([tenantId]).then((res) => {
      setUsers(res);
      setTableUsers(res)
    })
  }, [tenantId])



  useEffect(() => {
    if (!searchText || searchText === "") {
      setTableUsers(users);
      return;
    }
    const timeout = setTimeout(() => {
      setTableUsers(searchUser(searchText.toLowerCase()));
    }, 500)

    return () => {
      clearTimeout(timeout);
    }
  }, [searchText])

  useEffect(() => {
    if (selectedTableRows.length > 0) setCanDelete(true);
    else setCanDelete(false);
  }, [selectedTableRows])


  const searchUser = (text) => {
    return users.filter((element) => Object.keys(element).find((key) => typeof element[key] === "string" && element[key].toLowerCase().includes(text)))
  };

  const handleUpdateUser = (userId, updatedUser) => {
    setUsers((prev) => {
      const newData = [...prev];
      const index = prev.findIndex((user) => user.id === userId);
      newData[index] = { ...prev[index], ...updatedUser };
      return newData;
    });
    setTableUsers((prev) => {
      const newData = [...prev];
      const index = prev.findIndex((user) => user.id === userId);
      newData[index] = { ...prev[index], ...updatedUser };
      return newData;
    })
  };

  const handleDeleteUser = () => {
    const usersToDelete = selectedTableRows.map((rowNumber) => users[rowNumber]);
    setEdit(false);
  };

  return (
    <AccordionComponent title={tenants[tenantId].name}>
      <TextField sx={{ mb: "1rem" }} label="Cerca" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
      <UserTable enabledActions={enabledActions} tenantId={tenantId} users={tableUsers} selectedRows={selectedTableRows} onSelectRow={setSelectedTableRow} onUpdateUser={handleUpdateUser} />
    </AccordionComponent>
  )
}

const UsersConfiguration = ({ selectedTenants, enabledActions }) => {
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const handleConfirmInvitation = () => {
    setOpenModal(false);
    setSuccessModal(true);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };


  return (
    <Box sx={{ width: "100%", padding: "1rem" }}>
      {enabledActions.includes("invite-user") &&
        <div style={{ display: "flex", marginBottom: "1rem" }}>
          <Button sx={{ marginLeft: "auto" }} onClick={() => setOpenModal(true)}>+ {handleTranslate("INVITE_USER")} </Button>
        </div>}
      {selectedTenants.map((tenantId) => (
        <SingleTenant key={tenantId} tenantId={tenantId} enabledActions={enabledActions} />
      ))}
      <CreateUserModal isOpen={openModal} onClose={() => setOpenModal(false)} onConfirm={handleConfirmInvitation} />
      <SuccessModal title={handleTranslate("NEW_USER_INVITATION_SUCCESS")} isOpen={successModal} onClose={() => setSuccessModal(false)} />
    </Box>
  )
}

export default UsersConfiguration