import store from "../.."
import { setGenericError } from "../globalError/errorSlice";
import { mapsSliceActions } from "./mapsSlice";

export const setMapDisplay = (viewName, display) => {
    store.dispatch(mapsSliceActions.setMapDisplay({viewName, display}));
}

export const setMapRoute = (viewName, enabled) => {
    store.dispatch(mapsSliceActions.setMapRoute({viewName, enabled}));
}

export const removeMapMarkers = (viewName, stationId) => {
    store.dispatch(mapsSliceActions.removeMapMarkers({viewName:viewName, stationId:stationId}));
};

export const setMapMarkers = (viewName, markers, stationId) => {
    // console.log('setMapMarkers', viewName, markers, stationId);
    store.dispatch(mapsSliceActions.setMapMarkers({viewName:viewName, markers:markers, stationId:stationId}));
};

export const setMapOptions = (viewName, centerLat, centerLon, zoom) => {
    // console.log('setMapOptions', viewName, centerLat, centerLon, zoom);
    const center = { lat: centerLat, lng: centerLon};
    store.dispatch(mapsSliceActions.setMapOptions({viewName:viewName, center:center, zoom:zoom}));
};

export const toggleStationMap = (viewName) => {
    // console.log('toggleStationMap', viewName);
    store.dispatch(mapsSliceActions.toggleStationMap({key: viewName}));
};

export const toggleStationMapRoute = () => {
    // console.log('toggleStationMapRoute');
    store.dispatch(mapsSliceActions.toggleStationMapRoute());
};

export const addMapMarkers = (viewName, markers) => {
    // console.log('addMapMarkers', viewName, markers);
    store.dispatch(mapsSliceActions.addMapMarkers({viewName:viewName, markers:markers}));
};


export const findAlertsPositions =  async (userId, alertIds=null) => {
    if (!userId) throw new Error('Missing userId');
    if (alertIds && !Array.isArray(alertIds)) throw new Error('alertIds must be an array');
    const data = await store.dispatch(mapsSliceActions.findAlertsPositions({userId, alertIds})).then(({payload, error}) => {
        if(error)  throw error;
        return payload;
    });
    return data;
};