import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLayoutEffect, useState } from "react";

const SelectComponent = ({style, options, labels, value, labelId, placeholder="Select", required=false, disabled=false, onChange, multiple=false}) => {
  const [allOptions, setOptions] = useState(options || []);
  const [allLabels, setAllLabels] = useState(labels || null);
  const [val, setVal] = useState(value ? value : multiple ? [] : "");

  useLayoutEffect(() => {
    options !== undefined && setOptions(options);
    setAllLabels(labels);
  }, [options, labels]);

  useLayoutEffect(() => {
    value !== undefined && setVal(value);
  }, [value]);


  const handleChange = (selected) => {
    setVal(selected);
    if(onChange) onChange(selected)
  }

  const getLabel = (option) => {
    if(allLabels) return allLabels[allOptions.indexOf(option)];
    else return option;
  }
  
  return(
    <FormControl sx={{...style, minWidth: "100px", maxWidth: "300px"}} fullWidth disabled={disabled}>
      <InputLabel id={`${labelId}-select-label`}>{required ? `${placeholder}*`: placeholder}</InputLabel>
      <Select
        sx={{maxWidth: "300px"}}
        labelId={`${labelId}-select-label`}
        value={val}
        multiple={multiple}
        renderValue={(selected) => multiple ? selected.map((element) => getLabel(element)).join(", ") : getLabel(selected)}
        label={required ? `${placeholder}*`: placeholder}
        onChange={(e) => handleChange(e.target.value)}
      >
        {!multiple && 
          <MenuItem value={""}>
            <i>Null</i>
          </MenuItem>
        }
        {allOptions.map((option, index) => (
        <MenuItem key={index} value={option}>
          {multiple && <Checkbox checked={val?.indexOf(option) > -1} />}
          {getLabel(option)}
        </MenuItem>
        ))}
      </Select>
    </FormControl>     
  )
}

export default SelectComponent;