    import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Administration",
    ACTIVE_FILTER: "Active filters",
    CUSTOMIZE_FILTER: "Customize filters",
    NEW_FILTER: "New filter",
    NEW_TENANT: "New tenant",
    NEW_USER_INVITATION_SUCCESS: "Invitation sent successfully",
    NO_TENANTS: "There are no tenants",
    INVITE_USER: "Invite user",
    WARNING_DELETE_TENANT: "Attention, this operation will delete \
                            all data relating to this tenant, \
                            are you sure you want to proceed with the deletion",
    NEW_ROLE: "New role",
    WARNING_DELETE_ROLE: "Are you sure you want to delete the role",
    NEW_VARIABLE: "New variable",
    SAVED_SUCCESSFULLY: "Saved successfully",
    ACTION_GROUP: "Action group",
    SHARE_WITH: "Share with",
    IN_SHARING: "In sharing",
};

const ADMINISTRATION_EN = createLanguageObject(EN, viewNames.ADMINISTRATION_VIEW);


export default ADMINISTRATION_EN;