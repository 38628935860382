import { Grid, Paper } from "@mui/material"
import { getStationsThreshold } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux"
// import LockIcon from '@mui/icons-material/Lock';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import CheckIcon from '@mui/icons-material/Check';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import EditIcon from '@mui/icons-material/Edit';
import LoadingGif from "../FileManager/LoadingGif";
import { updateSensorThreshold } from "app/store/evocsSlice/sensors/sensorsActions";
import SelectSomething from "../SharingComponents/SelectSomething";
import ThresholdCard from "../SharingComponents/ThresholdCard";
import { viewNames } from "app/configs/routesNames";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";



// const SingleThreshold = ({enabledUpdate, placeholder="", value, defaultValue=undefined, locked, defaultLocked=true, onChange, onConfirmEdit}) => {
//   const [inputClassName, setInputClassName] = useState("input-container");
//   const [val, setValue] = useState(defaultValue);
//   const [lock, setLock] = useState(defaultLocked);

//   useLayoutEffect(() => {
//     value && setValue(value);
//   }, [value])

//   useLayoutEffect(() => {
//     locked !== undefined && setLock(locked);
//   }, [locked])

//   const handleInputFocus = () => {
//     setInputClassName("input-container focus");
//   }

//   const handleInputBlur = () => {
//     setInputClassName("input-container");
//   }

//   const handleChangeValue = (newValue) => {
//     setValue(newValue);
//     onChange && onChange(newValue);
//   }


//   const handleConfirm = () => {
//     setLock(true);
//     onConfirmEdit && onConfirmEdit(Number(val));
//   }

//   return (
//     <div className="threshold">
//       <label style={{width: "50px"}}>{placeholder}</label>
//       { enabledUpdate ?
//       <>
//       <div className={inputClassName}>
//         <input type="number" min={0} disabled={lock} value={val || ""} onChange={(e) => handleChangeValue(e.target.value)} onFocus={handleInputFocus} onBlur={handleInputBlur}/>
//         {lock ? <LockIcon fontSize="small" />: <LockOpenIcon fontSize="small"/>}
//       </div>
//       {lock ? 
//         <button onClick={() => setLock(false)}><EditIcon fontSize="small"/></button> 
//       :
//         <div style={{whiteSpace: "nowrap"}}>
//           <button onClick={handleConfirm}><CheckIcon fontSize="small"/></button> 
//           <button onClick={() => handleChangeValue(undefined)}><RestartAltIcon fontSize="small"/></button> 
//         </div>}
//       </>
//       :
//       <span>{val || "nessuna soglia"}</span>
//       }
//     </div>
//   );
// };


// const ThresholdCard = ({enabledUpdate, measureId, sensorId, min, max}) => {
//   const user = useSelector((state) => state.user);
//   const measures = useSelector((state) => state.configuration.constants.measure);

//   const handleConfirmThreshold = (key, value) => {
//     if(key === "min") updateSensorThreshold(user.tenantId, sensorId, measureId, value, undefined);
//     else updateSensorThreshold(user.tenantId, sensorId, measureId, undefined, value);
//   }

//   return(
//     <Paper sx={{padding: "1rem"}}>
//       <h3 style={{marginBottom: "1rem"}}>{measures[measureId]?.label}</h3>
//       <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
//         <SingleThreshold enabledUpdate={enabledUpdate} placeholder="Min" value={min} onConfirmEdit={(value) => handleConfirmThreshold("min", value)}/>
//         <SingleThreshold enabledUpdate={enabledUpdate} placeholder="Max" value={max} onConfirmEdit={(value) => handleConfirmThreshold("max", value)}/>
//       </div>
//     </Paper>
//   )
// }

const ThresholdsChip = ({ enabledActions, stationId }) => {
  const user = useSelector((state) => state.user);
  const stations = useSelector((state) => state.stationsMongo.list);
  const sensors = useSelector((state) => state.sensors.list);
  const sensorsType = useSelector((state) => state.configuration.constants.sensor_type);
  const [thresholds, setThresholds] = useState({})
  const [loadingThresholds, setLoadingThresholds] = useState(false);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  useEffect(() => {
    if (!stationId) return;
    setLoadingThresholds(true);
    getStationsThreshold(user.tenantId, [stationId]).then((res) => {
      let stationThresholds = {};
      Object.keys(res).forEach((stationId) => {
        Object.keys(res[stationId]).forEach((sensorId) => {
          if (!res[stationId][sensorId]) return;
          Object.keys(res[stationId][sensorId]).forEach((measureId) => {
            const typeId = sensors[sensorId].sensor_type;
            stationThresholds[typeId] = {
              ...stationThresholds[typeId],
              [measureId]: { sensorId, ...res[stationId][sensorId][measureId] }
            }
          })
        })
      });
      setThresholds(stationThresholds);
      setLoadingThresholds(false);
    })
  }, [stationId])

  const handleChangeThreshold = (sensorId, measureId, key, value) => {
    if (key === "min") updateSensorThreshold(user.tenantId, sensorId, measureId, value, undefined);
    else updateSensorThreshold(user.tenantId, sensorId, measureId, undefined, value);
  }


  return (
    <div style={{ padding: "1rem", width: "100%", height: "100%" }}>
      <h2 style={{ marginBottom: "1rem" }}>{stations[stationId]?.serial ? stations[stationId]?.serial : ""}</h2>
      {stationId ?
        Object.keys(thresholds).length > 0 ?
          loadingThresholds ?
            <LoadingGif />
            :
            <div style={{ display: "flex", flexDirection: "column", gap: "3rem", paddingBottom: "2rem" }}>
              {Object.keys(thresholds).map((typeId) => (
                <div key={typeId}>
                  <h3 style={{ marginBottom: "1rem", marginTop: "1rem"}}> <strong>{handleTranslate(`SENSOR_TYPE_${sensorsType[typeId]["sensor_type"].toUpperCase()}`, true)}</strong></h3>
                  <Grid container spacing={2}>
                    {Object.keys(thresholds[typeId]).map((measureId) => (
                      <Grid key={measureId} item xs={12} sm={6} md={4} lg={3}>
                        <ThresholdCard enableUpdate={enabledActions.includes("update-thresholds")} sensorId={thresholds[typeId][measureId]?.sensorId} measureId={measureId} min={thresholds[typeId][measureId]?.min} max={thresholds[typeId][measureId]?.max}
                          onConfirmEdit={handleChangeThreshold} />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          :
          <span> {handleTranslate("NO_SENSORS_INSTALLED", true)} </span>
        :
        <SelectSomething text={handleTranslate("SELECT_STATION", true)} />
      }
    </div>
  )
}

export default ThresholdsChip