import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system';
import DashboardMap from './DashboardMap';
import { Button, Grid } from '@mui/material';
import GraphsStateChip from './GraphsStateChip';
import { useSelector } from 'react-redux';
import useMobile from 'src/app/hooks/useMobile';
import DashboardTab from './DashboardTab';
import { viewNames } from 'app/configs/routesNames';
import useActions from 'src/app/hooks/useActions';
import { readAliveSensors } from 'app/store/evocsSlice/sensors/sensorsActions';
import useLoadingDevices from 'src/app/hooks/useLoadingDevices';

const viewName = viewNames.DASHBOARD_VIEW;

const DashboardContent = () => {
  const loadingDevices = useLoadingDevices();
  const user = useSelector((state) => state.user);
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const [xs, sm, md, lg, xl] = useMobile();
  const userActions = useActions(viewName);
  const [aliveSensors, setAliveSensors] = useState();
  const [selectedStation, setSelectedStation] = useState();
  const [loadingGraphs, setLoadingGraphs] = useState(false);
  const enabledActions = useMemo(() => {
    return userActions?.map((action) => action?.name);
  }, [userActions]);

  useEffect(() => { 
    setLoadingGraphs(loadingDevices);
    if(loadingDevices || availableStations?.length === 0) return;
    let canSet = true;
    setLoadingGraphs(true);
    readAliveSensors(user.tenantId, availableStations).then((res) => {
      if(!canSet) return;
      setAliveSensors(res);
      setLoadingGraphs(false);
    });

    return () => canSet = false;
  }, [loadingDevices])


  const handleSelectStation = (stationId) => {
    setSelectedStation(stationId);
  };

  const handleClickMarker = (marker, popupContent) => {
    const stationId = popupContent.id;
    setSelectedStation(() => {
      if (availableStations.includes(stationId)) return stationId;
      else return null;
    });
  };

  const graphHeight = () => {
    if (xs || sm || md) return "400px";
    else return "400px"
  };

  return (
    <Box padding={{ xs: "1rem", sm: "2rem 5rem" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={8}>
          <GraphsStateChip style={{ height: graphHeight() }} aliveSensors={aliveSensors} selectedStation={selectedStation} onSelectStation={handleSelectStation} loading={loadingGraphs}/>
        </Grid>
        <Grid item xs={12} lg={4}>
          <DashboardMap aliveSensors={aliveSensors} selectedStation={selectedStation} onClickMarker={handleClickMarker} onClosePopup={() => handleSelectStation(null)} />
        </Grid>
       {aliveSensors &&
        <Grid item xs={12} lg={12}>
          <DashboardTab enabledActions={enabledActions} stationId={selectedStation} />
        </Grid>
        }
      </Grid>
    </Box>
  )
}


export default DashboardContent