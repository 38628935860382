import { useLayoutEffect, useMemo, useState } from "react";
import TimeIntervalSelector from "../StationHandling/TimeIntervalSelector";
import { useSelector } from "react-redux";
import TimeRangeSlider from "../StationHandling/TimeRangeSlider";
import { TIME_FORMAT, calcIntervalDates } from "../StationHandling/utils";
const timeFormat = TIME_FORMAT.COMPLETE_DATE;

const personalizzatoId = "65f011b0dbaa0fa4b9d66ebe";
const liveId = "660bc8f8e4db0b4972d2fb69";

const RangeTimeFilter_v2 = ({style, startDate, defaultStartDate, endDate, defaultEndDate, sliderStart, sliderEnd, 
  intervals, selectedInterval, defaultInterval, withDatePicker=false, onChangeInterval, onChangeSlider, withSlider=true}) => {

  const timeRangeConstants = useSelector((state) => state.configuration.constants.timeRange);
  const timeRange = useSelector((state) => state.configuration.constants.timeRange);
  const options = useMemo(() => {
    if(intervals) return intervals;
    else return Object.keys(timeRange);
  }, [intervals]);
  const [interval, setInterval] = useState(() => {
    if(defaultInterval) return defaultInterval;
    return options[0];
  });
  const [startingDate, setStartDate] = useState(defaultStartDate)
  const [endingDate, setEndDate] = useState(defaultEndDate);

  useLayoutEffect(() => {
    selectedInterval && setInterval(selectedInterval);
  }, [selectedInterval])

  useLayoutEffect(() => {
    if(!interval) return;
    const range = calcIntervalDates(timeRangeConstants[interval].timeRange);
    if(!range.start || !range.end) {
      onChangeInterval && onChangeInterval(personalizzatoId);
      return;
    }
    setStartDate(new Date(range.start));
    setEndDate(new Date(range.end));
  }, [interval])

  useLayoutEffect(() => {
    startDate && setStartDate(startDate);
    endDate && setEndDate(endDate);
  }, [startDate, endDate]);

  useLayoutEffect(() => {
    if(!startingDate || !endingDate) return;
    const start = new Date(startingDate).setSeconds(0, 0).valueOf();
    const end = new Date(endingDate).setSeconds(0, 0).valueOf();
    const intervalId = options.find((intervalId) => {
      if(interval !== liveId && intervalId === liveId) return false;
      const intervalDates = calcIntervalDates(timeRangeConstants[intervalId].timeRange);
      if(!intervalDates) return false;
      const startDate = new Date(intervalDates.start).setSeconds(0, 0).valueOf();
      const endDate = new Date(intervalDates.end).setSeconds(0, 0).valueOf();
      if(startDate === start && endDate === end) return true;
      else return false;
    });
    setInterval(intervalId || personalizzatoId);
    onChangeInterval && onChangeInterval(intervalId || personalizzatoId, startingDate.valueOf(), endingDate.valueOf());
  }, [startingDate, endingDate])

  const handleChangeInterval = (selected) => {
    setInterval(selected);
  }

  const handleChangeSlider = (start, end, value) => {
    onChangeSlider && onChangeSlider(start, end, value);
  }

  const handleChangeCalendar = (calendarStart, calendarEnd) => {
    setStartDate(new Date(calendarStart));
    setEndDate(new Date(calendarEnd));
  }
  
  
  return(
    <div style={{...style, display: "flex", gap: "1rem", alignItems: "center", flexWrap: "wrap"}}>
      <div style={{flexGrow: "1"}}>
        <TimeIntervalSelector intervals={options} value={interval} onChange={handleChangeInterval}/>
      </div>
      {withSlider &&
      <div style={{flexGrow: "1"}}>
        <TimeRangeSlider
          withDatePicker={withDatePicker}
          startDate={startingDate}
          endDate={endingDate}
          sliderStart={sliderStart}
          sliderEnd={sliderEnd}
          onChangeSlider={handleChangeSlider}
          onChangeCalendar={handleChangeCalendar}
          timeFormat={timeFormat}
        />
      </div>
      }

    </div>
  );
}

export default RangeTimeFilter_v2;