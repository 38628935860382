import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const EN = {
    HEADER : "Naval traffic",
};

const VESSELS_EN = createLanguageObject(EN, viewNames.VESSELS_VIEW);



export default VESSELS_EN;