import React from "react";
import {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { setSessionExpired } from "app/store/evocsSlice/login/loginActions";
import VideoPlayerEvocs from "src/components/SharingComponents/VideoPlayerEvocs";
import FuseMessage from "src/@fuse/core/FuseMessage";
import DynamicReportPDF from "src/components/DynamicFile/DynamicReportPDF";
import Box from "@mui/material/Box";
import {
  getStationReportInfo,
  getSensorReportInfo,
} from "app/store/evocsSlice/reports/reportsActions";
import { FaFilePdf } from "react-icons/fa6";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AxisLiveStreaming from "../SharingComponents/AxisLiveStreaming";
import { getAxisCameraImage } from "app/store/evocsSlice/dataset/datasetActions";
import AxisRecording from "../SharingComponents/AxisRecording";
import { setGenericError } from "app/store/evocsSlice/globalError/errorSlice";
import AxisLiveStreaming_V2 from "../SharingComponents/AxisLiveStreaming_V2";
import AxisLiveStreaming_V3_auth from "../SharingComponents/AxisLiveStreaming_V3_auth";
import videoMP4 from "src/app/asset/video/videoTest_1.mp4";
import videoMOV from "src/app/asset/video/videoTest.mov";
import videoMKV from "src/app/asset/video/videoTest_2.mkv";
import ShipCountryFlagIcon from "../VesselsTraffic/ShipCountryFlagIcon";
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { viewNames } from "app/configs/routesNames";
import DownloadVideoTest from "../SharingComponents/DonwloadVideoTest";
import RangeTimeFilter from "../SharingComponents/RangeTimeFilter";
import { calcGraphPoints } from "app/store/evocsSlice/dataset/datasetActions";
import axios from "axios";


import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';


const TestPageContent = () => {
  const handleClick = () => {
    // Rimuove l'header Authorization
    delete axios.defaults.headers.common['Authorization'];

    // Imposta un nuovo header Authorization
    axios.defaults.headers.common['Authorization'] = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtdWx0aXRlbmFudEB0aWxlYnl0ZXMuY29tIiwiaWF0IjoxNzIwNzA5MjIzLCJ0ZW5hbnQiOiJzcGFydGFuIiwibGFuZ3VhZ2UiOiJpdCIsImF1dGhvcml0aWVzIjpbIlNZU1RFTSJdLCJ1c2VySWQiOiJzcGFydGFuIiwiZXhwIjoxNzIwNzEwMTIzfQ.qwTA0fHIhA1Rgk4RdaK68dBveKsGqajROXjovBx9LAP88GsUaw6fK5LUiGGBOH0GoLVwcbbP0Rx13GXkwcU5sA';

    console.log('Authorization header updated!');
    calcGraphPoints(1724910184, 1724924584, [
      "666a9c07bc9f1e89bb7b1be9",
      "661f86d505c2fd875fe14916"
    ], 240);
  };

  return (
    <div style={{ width: "300px", padding: "2rem" }}>
      <Button variant="outlined" color="primary" onClick={handleClick}>
        Update Authorization Header
      </Button>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={[
            'DateTimePicker',
            'MobileDateTimePicker',
            'DesktopDateTimePicker',
            'StaticDateTimePicker',
          ]}
        >
          <DemoItem label="Desktop variant">
            <DesktopDateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
          </DemoItem>
          <DemoItem label="Mobile variant">
            <MobileDateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
          </DemoItem>
          <DemoItem label="Responsive variant">
            <DateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
          </DemoItem>
          <DemoItem label="Static variant">
            <StaticDateTimePicker defaultValue={dayjs('2022-04-17T15:30')} />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>

    </div>
  );
};


// const TestPageContent = () => {
//   const {t} = useTranslation('evocs');
//   const [downloadPdf, setDownloadPdf] = useState(false);
//   const [reportsData, setReportsData] = useState({});

//   const handleTranslate = (textId, geneal = false) => {
//     if (geneal) return (translate(t, textId));
//     return (translate(t, textId,  viewNames.SIGN_IN_VIEW));
//   };

//   const dispatch = useDispatch();
//   const handleClickExpiredSession = () => {
//     setSessionExpired(true);
//   };
//   const handleClickDonwloadPdf = () => {
//     setDownloadPdf(true);
//   };

//   const handle404 = () => {
//     setGenericError(true);
//   };

//   // return (
//   //   <></>
//   // )
//   const buttonStyle = {
//     display: "inline-block",
//     padding: "10px 20px",
//     fontSize: "16px",
//     fontWeight: "bold",
//     border: "none",
//     borderRadius: "25px" /* Bordi arrotondati */,
//     cursor: "pointer",
//     textAlign: "center",
//     textDecoration: "none",
//     transition: "background-color 0.3s",
//   };

//   const [enableTest1, setEnableTest1] = useState(false);
//   const [enableTest2, setEnableTest2] = useState(false);
//   const [enableTest3, setEnableTest3] = useState(false);

//   const handleEnableTest1 = () => {
//     setEnableTest1(!enableTest1);
//   };
//   const handleEnableTest2 = () => {
//     setEnableTest2(!enableTest2);
//   };
//   const handleEnableTest3 = () => {
//     setEnableTest3(!enableTest3);
//   };

//   const TestVideoSafari1 = ({ isEnabled }) => {
//     return (
//       <>
//         {isEnabled && (
//           <div style={{ display: "flex", gap: "1rem" }}>
//             <div>
//               <h2 style={{ marginBottom: "1rem" }}>MP4</h2>
//               <video src={videoMP4} muted autoPlay />
//             </div>
//             <div>
//               <h2 style={{ marginBottom: "1rem" }}>MOV</h2>
//               <video src={videoMOV} muted autoPlay />
//             </div>
//             <div>
//               <h2 style={{ marginBottom: "1rem" }}>MKV</h2>
//               <video src={videoMKV} muted autoPlay />
//             </div>
//           </div>
//         )}
//       </>
//     );
//   };
//   const TestVideoSafari2 = ({ isEnabled }) => {
//     const refMp4 = useRef(null);
//     const refMov = useRef(null);
//     const refMKv = useRef(null);
//     const [clicked, setClicked] = useState();

//     useEffect(() => {
//       if (clicked === undefined) return;
//       if (clicked) {
//         if (refMp4.current) refMp4.current.play();
//         if (refMov.current) refMov.current.play();
//         if (refMKv.current) refMKv.current.play();
//       } else {
//         if (refMp4.current) refMp4.current.pause();
//         if (refMov.current) refMov.current.pause();
//         if (refMKv.current) refMKv.current.pause();
//       }
//     }, [clicked]);

//     return (
//       <>
//         {isEnabled && (
//           <div style={{ textAlign: "center" }}>
//             <div style={{ marginBottom: "1rem" }}>
//               <button
//                 onClick={() => setClicked(!clicked)}
//                 style={{
//                   ...buttonStyle,
//                   color: clicked ? "#242A30" : "white",
//                   backgroundColor: clicked ? "#74C365" : "#242A30",
//                 }}
//               >
//                 {clicked ? "Pause video" : "Play video"}
//               </button>
//             </div>
//             <div
//               style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
//             >
//               <div>
//                 <h2 style={{ marginBottom: "1rem" }}>MP4</h2>
//                 <video ref={refMp4} src={videoMP4} muted />
//               </div>
//               <div>
//                 <h2 style={{ marginBottom: "1rem" }}>MOV</h2>
//                 <video ref={refMov} src={videoMOV} muted />
//               </div>
//               <div>
//                 <h2 style={{ marginBottom: "1rem" }}>MKV</h2>
//                 <video ref={refMKv} src={videoMKV} muted />
//               </div>
//             </div>
//           </div>
//         )}
//       </>
//     );
//   };
//   const TestVideoSafari3 = ({ isEnabled }) => {
//     return (
//       <>
//         {isEnabled && (
//           <div style={{ display: "flex", gap: "1rem" }}>
//             <div>
//               <h2 style={{ marginBottom: "1rem" }}>MP4</h2>
//               <video src={videoMP4} autoPlay />
//             </div>
//             <div>
//               <h2 style={{ marginBottom: "1rem" }}>MOV</h2>
//               <video src={videoMOV} autoPlay />
//             </div>
//             <div>
//               <h2 style={{ marginBottom: "1rem" }}>MKV</h2>
//               <video src={videoMKV} autoPlay />
//             </div>
//           </div>
//         )}
//       </>
//     );
//   };

//   const handleChangeLanguage = (event) => {
//     dispatch(changeLanguage('it'));
//     // dispatch(changeLanguage(lng.id));
//   };

//   return (
//     <>
//     {/* <div style={{background: "black"}}>
//     <iframe src="https://my.spline.design/worldv5-bdab0e5175b855fd42c12b89eec1168f/" allow="fullscreen"></iframe>
//     </div> */}
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//           justifyContent: "center",
//           gap: "1rem",
//         }}
//       >
//         <button
//           onClick={handleEnableTest1}
//           style={{
//             ...buttonStyle,
//             color: enableTest1 ? "#242A30" : "white",
//             backgroundColor: enableTest1 ? "#74C365" : "#242A30",
//           }}
//         >
//           START TEST 1 (muted autoplay)
//         </button>
//         <button
//           onClick={handleEnableTest2}
//           style={{
//             ...buttonStyle,
//             color: enableTest2 ? "#242A30" : "white",
//             backgroundColor: enableTest2 ? "#74C365" : "#242A30",
//           }}
//         >
//           START TEST 2 (muted)
//         </button>
//         <button
//           onClick={handleEnableTest3}
//           style={{
//             ...buttonStyle,
//             color: enableTest3 ? "#242A30" : "white",
//             backgroundColor: enableTest3 ? "#74C365" : "#242A30",
//           }}
//         >
//           START TEST 3 (autoplay)
//         </button>
//       </div>

//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//           marginTop: "2rem",
//         }}
//       >
//         <h1>{handleTranslate('TEST')}</h1>
//         {/* <TestVideoSafari1 isEnabled={enableTest1} />
//         <TestVideoSafari2 isEnabled={enableTest2} />
//         <TestVideoSafari3 isEnabled={enableTest3} />*/}
//         {/* <AxisLiveStreaming_V2 />  */}
//         {/* <AxisLiveStreaming />  */}
//         <DownloadVideoTest></DownloadVideoTest>

//       </div>
//     </>
//   );

//   // return (
//   //   <div style={{
//   //     display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
//   //     height: "85vh", width: "100%"
//   //   }}>
//   //     <h1 style={{ marginBottom: '2rem' }}>Questa è una pagina di test</h1>

//   //     {/* <></> */}

//   //     {/* ################# SESSIONE ############## * */}
//   //     {/* <button onClick={handleClickExpiredSession} style={{
//   //           textAlign: 'center',
//   //           marginTop: '2rem', border: '1px solid black', backgroundColor: 'white', borderRadius: '5rem',
//   //           width: 'fit-content', height: 'fit-content', padding: '1rem',
//   //       }}>
//   //         <h1>M'è scadut</h1>
//   //       </button> */}

//   //     {/* ################# 404 ############## * */}
//   //     {/* <button onClick={handle404} style={{
//   //           textAlign: 'center',
//   //           marginTop: '2rem', border: '1px solid black', backgroundColor: 'white', borderRadius: '5rem',
//   //           width: 'fit-content', height: 'fit-content', padding: '1rem',
//   //       }}>
//   //         <h1>Non cliccarmi...oppure</h1>
//   //       </button> */}

//   //     {/* ################# VIDEO PLAYER ##############  */}
//   //       {/* <VideoPlayerEvocs /> */}
//   //       {/* ################# TEST LIVE STREAM ##############  */}

//   //       {/* <AxisLiveStreaming/> */}
//   //       {/* <AxisLiveStreaming_V2></AxisLiveStreaming_V2> */}
//   //       {/* <AxisLiveStreaming_V3_auth></AxisLiveStreaming_V3_auth> */}
//   //       {/* <AxisRecording/> */}
//   //       {/* <AxisLiveStreaming_V4></AxisLiveStreaming_V4> */}

//   //   </div>
//   // )
// };

// const TestPageContent = () => {
//   return (
//     <div>
//       <ShipCountryFlagIcon countryCode={100}/>
//     </div>
//   )
// }

// const TestPageContent = () => {
//   return (
//     <div style={{width: "300px", padding: "2rem"}}>
//       <RangeTimeFilter />
//     </div>
//   )
// }

export default TestPageContent;
