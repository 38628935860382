import { useEffect, useMemo, useState } from "react";
import LineGraph from "./LineGraph";
import { CircularProgress, FormControl, InputLabel, Paper, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate } from "./utils";
import { TIME_FORMAT } from "./utils";
import MeasureSelect from "../SharingComponents/Inputs/MeasureSelect";
import LiveText from "../SharingComponents/LiveText";
import DownloadComponent from "../SharingComponents/DownloadComponent/DownloadComponent";
import ToggleButton from "../SharingComponents/Inputs/ToggleButton";
import AddIcon from '@mui/icons-material/Add';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { createDatasetLineGraph, createTooltipLineGraph } from "app/store/evocsSlice/dataset/datasetActions";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import NoDataMessage from "../SharingComponents/NoDataMessage";
import { getR2BySensors } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { getCorrelationMatrix } from "app/store/evocsSlice/sensors/sensorsActions";

const getSmallestValue = (values) => {
  if(!Array.isArray(values)) throw new Error("values must be array");
  let smallest = values[0];
  values.forEach((val) => {
    if(smallest > val) smallest = val
  });
  return smallest;
}

const getLargestValue = (values) => {
  if(!Array.isArray(values)) throw new Error("values must be array");
  let largest = values[0];
  values.forEach((val) => {
    if(largest < val) largest = val
  });
  return largest;
}

const MeasureSelection = ({ measuresOptions, selectedMeasure, compare, compareOptions, selectedCompare, onChangeMeasure, onChangeCompare, onChangeCompareMeasure, disabled }) => {
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const { t } = useTranslation("evocs");

  const groups = useMemo(() => {
    if(!controlUnits) return [];
    const parentIds = [...new Set(compareOptions.map((measureOption) => measureOption.sensor.deviceParent.type === "cu" ? measureOption.sensor.deviceParent.device : measureOption.sensor.sensorId))];
    let indexValue = 1;
    return parentIds.map((parentId) => {
      const groupOptions = [];
      compareOptions.forEach((measuresOption) => {
        if(measuresOption.sensor.deviceParent.device === parentId || measuresOption.sensor.sensorId === parentId) {
          groupOptions.push({groupId: parentId, index: indexValue, ...measuresOption});
          indexValue++;
        }
      })
      return {id: parentId, title: controlUnits[parentId]?.serial || sensors[parentId]?.serial, options: groupOptions}
    });

  }, [controlUnits, sensors, compareOptions])

  const handleChangeCompareMeasure = (value) => {
    const formattedValue = value === "" ? "" : Number(value);
    let item;
    groups.some((group) => {
      item = group.options.find((element) => element.index === formattedValue);
      return item;
    });
    onChangeCompareMeasure && onChangeCompareMeasure(item, formattedValue);
  }

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
 };

 const getMeasureName = (measureId) => {
    return handleTranslate(`MEASURE_${measures[measureId].measure.toUpperCase()}`, true)
 }


  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "1rem" }}>
      <MeasureSelect disabled={disabled} measuresId={measuresOptions} value={selectedMeasure} onChange={onChangeMeasure} /> 
      <ToggleButton enabled={compare} onChange={onChangeCompare}>
        {!compare ? <AddIcon /> : <CompareArrowsIcon />}
      </ToggleButton>
      {compare &&
        <FormControl sx={{ width: "100%", minWidth: "100px", maxWidth: "300px"  }}>
          <InputLabel htmlFor="grouped-measure-select">Misura</InputLabel>
          <Select native defaultChecked disabled={disabled} id="grouped-measure-select" label="Misura" value={selectedCompare?.index || ""} onChange={(e) => handleChangeCompareMeasure(e.target.value)}>
            <option aria-label="None" value="" />
            {groups.map((group) => (
              <optgroup key={group.id} label={group.title}>
                {group.options.map((measure) => (
                  <option key={measure.measureId} value={measure.index}>{getMeasureName(measure.measureId, true)}</option>
                ))}
              </optgroup>
            ))}
          </Select>
        </FormControl>
      }
      {/* {compare && <MeasureSelect measuresId={compareOptionsIds} value={selectedCompare} onChange={onChangeCompareMeasure} />} */}
    </div>

  )
}

const GraphInfo = ({loadingR2, lastDetections, r2Value}) => {

  return (
    <div style={{display: "flex", gap: "1.5rem", flexWrap: "wrap"}}>
      {lastDetections?.length > 0 &&
        <label style={{whiteSpace: "nowrap"}}>
          Ultima rilevazione: <b>{lastDetections.join(", ")}</b>
        </label>
      }
      {!loadingR2 ?
        typeof r2Value === "number" && <label style={{whiteSpace: "nowrap"}}>R2: <b>{r2Value} %</b></label>
        :
        <label style={{whiteSpace: "nowrap"}}>R2: <CircularProgress size={"15px"}/></label>
      }
    </div>
  )
}

const SensorGraph = ({
  sensorId,
  sensor,
  graphHeight = "400px",
  startDate,
  endDate,
  graphShadow = true,
  withDownloads = false,
  selectedMeas,
  compareOptions,
  isCompare,
  selectedCompare,
  userActions,
  isLive = false,
  onChangeMeasure,
  onChangeCompare,
  onChangeSelectedCompare
}) => {
  const measures = useSelector((state) => state.configuration.constants?.measure);
  const datasets = useSelector((state) => state.dataset.list);
  const [selectedMeasure, setSelectedMeasure] = useState(sensor?.measures[0]);
  const [compare, setCompare] = useState(isCompare);
  const [compareMeasuresOptions, setCompareOptions] = useState(compareOptions || []);
  const [selectedCompareMeasure, setSelectedCompare] = useState(selectedCompare || "");
  const [checkedMenu, setCheckedMenu] = useState([]);
  const [lastCheckedMenu, setLastCheckedMenu] = useState([]);
  const [loadingR2, setLoadingR2] = useState(false);
  const [loadingMatrix, setLoadingMatrix] = useState(false);
  const [r2Value, setR2Value] = useState();
  const [matrixImage, setMatrixImage] = useState();
  
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.STATIONS_VIEW);
  };

  const measuresUnit = useMemo(() => {
    const units = [];
    if (selectedMeasure) {
      units.push(measures?.[selectedMeasure].unit);
      if (selectedCompareMeasure) units.push(measures?.[selectedCompareMeasure.measureId].unit);
    }
    return units
  }, [selectedMeasure, selectedCompareMeasure])

  // const graph = useMemo(() => {
  //   const graphDatasets = [], tooltips = [], minMax=[], minMaxRange=[];;
  //   let labels = [];
  //   if (selectedMeasure) {
  //     const yAxisId = "y0";
  //     const fill = compare && selectedCompareMeasure ? false : true;
  //     const data = [];
  //     const measureDataset = datasets[sensorId]?.[selectedMeasure];
  //     if(measureDataset) {
  //       minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
  //       minMax.push({min: measureDataset.min, max: measureDataset.max});
  //     }
  //     measureDataset?.data.forEach((dataset) => {
  //       // labels.push(formatDate(new Date(dataset[0]*1000), TIME_FORMAT.DATE_AND_HOUR));
  //       labels.push(dataset[0]);
  //       data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
  //     });
  //     const graphColor = isCompare && selectedCompareMeasure ? "#92c870" : measures[selectedMeasure].color;
  //     graphDatasets.push(createDatasetLineGraph(measures[selectedMeasure].label, data, graphColor, graphColor, fill, yAxisId));
  //     tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedMeasure].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedMeasure].unit));
  //   }

  //   if (compare && selectedCompareMeasure) {
  //     const yAxisId = "y1";
  //     const data = [];
  //     const measureDataset = datasets[selectedCompareMeasure.sensor.sensorId]?.[selectedCompareMeasure.measureId];
  //     if(measureDataset) {
  //       minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
  //       minMax.push({min: measureDataset.min, max: measureDataset.max});
  //     }
        
  //     measureDataset?.data.forEach((dataset) => {
  //       labels.push(dataset[0]);
  //       data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
  //     })

  //     const graphColor = "#242a30";
  //     graphDatasets.push(createDatasetLineGraph(measures[selectedCompareMeasure.measureId].label, data, graphColor, graphColor, false, yAxisId));
  //     tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedMeasure].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedCompareMeasure.measureId].unit));
  //     labels = [...new Set(labels)].sort((a, b) => a - b);
  //   }
  //   labels = labels.map((timestamp) => formatDate(new Date(timestamp * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS));

  //   return { labels, datasets: graphDatasets, tooltips, minMax, minMaxRange };
  // }, [datasets, selectedMeasure, compare, selectedCompareMeasure, t]);

  const graph = useMemo(() => {
    const graphDatasets = [], tooltips = [], avg=[], minMax=[], minMaxRange=[];
    let labels = [];
    if(selectedMeasure) {
      const yAxisId = "y0";
      const fill = isCompare && selectedCompareMeasure ? false : true;
      const data = [];
      const measureDataset = datasets[sensorId]?.[selectedMeasure];
      measureDataset?.data.forEach((dataset) => {
        labels.push(dataset[0]);
        data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
      });
      const graphColor = isCompare && selectedCompareMeasure ? "#92c870" : measures[selectedMeasure].color;
      graphDatasets.push(createDatasetLineGraph(measures[selectedMeasure].label, data, graphColor, graphColor, fill, yAxisId));
      tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedMeasure].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedMeasure].unit));
      if(isCompare && selectedCompareMeasure) {
        const yAxisId = "y1";
        const data = [];
        const compareMeasureDataset = datasets[selectedCompareMeasure.sensor.sensorId]?.[selectedCompareMeasure.measureId];
        compareMeasureDataset?.data.forEach((dataset) => {
          labels.push(dataset[0]);
          data.push({ x: formatDate(new Date(dataset[0] * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS), y: dataset[1] });
        })
        const graphColor = "#242a30";
        graphDatasets.push(createDatasetLineGraph(measures[selectedCompareMeasure.measureId].label, data, graphColor, graphColor, false, yAxisId));
        tooltips.push(createTooltipLineGraph(handleTranslate(`MEASURE_${measures[selectedCompareMeasure.measureId].measure.toUpperCase()}`, true).toUpperCase(), `${handleTranslate("DATE", true)}: `, "", `${handleTranslate("VALUE", true)}: `, measures[selectedCompareMeasure.measureId].unit));
        labels = [...new Set(labels)].sort((a, b) => a - b);

        if(measureDataset && compareMeasureDataset) {
          avg.push(measureDataset.avg);
          avg.push(compareMeasureDataset.avg);
          minMax.push({min: measureDataset.min, max: measureDataset.max});
          minMax.push({min: compareMeasureDataset.min, max: compareMeasureDataset.max});
          if(measuresUnit[0] === measuresUnit[1]) {
            const rangeMin = getSmallestValue([measureDataset.rangeMin, compareMeasureDataset.rangeMin]);
            const rangeMax = getLargestValue([measureDataset.rangeMax, compareMeasureDataset.rangeMax]);
            minMaxRange.push({min: rangeMin, max: rangeMax});
            minMaxRange.push({min: rangeMin, max: rangeMax});
          }else {
            minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
            minMaxRange.push({min: compareMeasureDataset.rangeMin, max: compareMeasureDataset.rangeMax});
          }
        }
      }else if(measureDataset) {
        avg.push(measureDataset.avg);
        minMaxRange.push({min: measureDataset.rangeMin, max: measureDataset.rangeMax});
        minMax.push({min: measureDataset.min, max: measureDataset.max});
      }
    }
    labels = labels.map((timestamp) => formatDate(new Date(timestamp * 1000), TIME_FORMAT.DATE_AND_HOUR_SECONDS));

    return { labels, datasets: graphDatasets, tooltips, avg, minMax, minMaxRange};
  }, [datasets, selectedMeasure, isCompare, selectedCompareMeasure, t]);


  const lastDetections = useMemo(() => {
    if(!graph.datasets) return [];
    return graph.datasets.map((dataset, index) => {
      const data = dataset.data;
      return data[data.length-1]?.y ? (data[data.length-1].y + (measuresUnit[index] ? ` ${measuresUnit[index]}` : "")) : null;
    }).filter((e) => e);
  }, [graph])

  useEffect(() => {
    selectedMeas && setSelectedMeasure(selectedMeas);
  }, [selectedMeas])

  useEffect(() => {
    compareOptions && setCompareOptions(compareOptions);
  }, [compareOptions])

  useEffect(() => {
    setSelectedCompare(selectedCompare);
    setCompare(isCompare);
  }, [selectedCompare, isCompare])

  useEffect(() => {
    setLastCheckedMenu(checkedMenu);
    let canSetR2 = true, canSetMatrix = true;
    const newChecked = checkedMenu.filter((option1) => !lastCheckedMenu.find((option2) => option1.groupId === option2.groupId && option1.id === option2.id));
    const unckecked = lastCheckedMenu.filter((option1) => !checkedMenu.find((option2) => option1.groupId === option2.groupId && option1.id === option2.id));;
   
    // get R2
    if(compare && selectedCompareMeasure) {
      if(newChecked.find((option) => option.id === "r2")) {
        setLoadingR2(true);
  
        const dictSensors = selectedCompareMeasure.sensor.sensorId === sensorId ? {
          [sensorId]: [selectedMeasure, selectedCompareMeasure.measureId],
        }: {
          [sensorId]: [selectedMeasure],
          [selectedCompareMeasure.sensor.sensorId]: [selectedCompareMeasure.measureId]
        }
        getR2BySensors(dictSensors, startDate, endDate).then((res) => {
          if(!canSetR2) return;
          setLoadingR2(false);
          setR2Value(res?.r2);
        });
      }else if(unckecked.find((option) => option.id === "r2")) {
        setLoadingR2(false);
        setR2Value(null);
      }
    }

    // get Matrix
    if(newChecked.find((option) => option.id === "matrix")) {
      setLoadingMatrix(true);
      getCorrelationMatrix(sensorId, startDate, endDate).then((res) => {
        if(!canSetMatrix) return;
        setMatrixImage(res);
        setLoadingMatrix(false);
      });
    }else if(unckecked.find((option) => option.id === "matrix")) {
      setMatrixImage(null);
      setLoadingMatrix(false);
    }

    return () => {
      canSetR2 = false;
      canSetMatrix = false;
    }
  }, [checkedMenu])

  useEffect(() => {
    let canSetR2 = true, canSetMatrix = true;
    if(checkedMenu.find((option) => option.id === "r2")) {
      if(!compare || !selectedCompareMeasure) {
        setLoadingR2(false);
        setR2Value(null);
      }else {
        setLoadingR2(true);
        const dictSensors = selectedCompareMeasure.sensor.sensorId === sensorId ? {
          [sensorId]: [selectedMeasure, selectedCompareMeasure.measureId],
        }: {
          [sensorId]: [selectedMeasure],
          [selectedCompareMeasure.sensor.sensorId]: [selectedCompareMeasure.measureId]
        }
        getR2BySensors(dictSensors, startDate, endDate).then((res) => {
          if(!canSetR2) return;
          setLoadingR2(false);
          setR2Value(res?.r2);
        });
      }
    }
    if(checkedMenu.find((option) => option.id === "matrix")) {
      if(compare && selectedCompareMeasure) {
        setLoadingMatrix(false);
        setMatrixImage(null);
      }else {
        setLoadingMatrix(true);
        getCorrelationMatrix(sensorId, startDate, endDate).then((res) => {
          if(!canSetMatrix) return;
          setMatrixImage(res);
          setLoadingMatrix(false);
        });
      }
    }

    return () => {
      canSetR2 = false;
      canSetMatrix = false;
    }
  }, [selectedMeasure, compare, selectedCompareMeasure])

  const handleChangeMeasure = (measureId) => {
    setSelectedMeasure(measureId);
    onChangeMeasure && onChangeMeasure(measureId);
  };

  const handleChangeCompare = (compare) => {
    setCompare(compare);
    onChangeCompare && onChangeCompare(compare);
  };

  const handleChangeSelectedCompare = (compare) => {
    setSelectedCompare(compare);
    onChangeSelectedCompare && onChangeSelectedCompare(compare);
  };

  const handleChangeMenu = (value) => {
    setCheckedMenu(value);
  }


  return (
    <Paper sx={{ p: "1rem", boxShadow: "none" }}>
      <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", justifyContent: "start", flexWrap: "wrap", marginBottom: "1rem"}}>
        <div style={{ display: "flex", flex: .8, gap: "1rem", alignItems: "center"}}>
          <h3>{handleTranslate("SENSOR", true)}: {sensor?.serial}</h3>
          {withDownloads && (
            <DownloadComponent
              userActions={userActions}
              startDate={startDate}
              endDate={endDate}
              sensorId={sensorId}
              unitSerial={sensor?.serial}
            />
          )}
        </div>
        <div style={{display: "flex", flex: 1, gap: "1rem", alignItems: "center"}}>
          <GraphInfo loadingR2={loadingR2} lastDetections={lastDetections} r2Value={r2Value}/>
          {isLive && <LiveText style={{marginLeft: "auto"}}/>}
        </div>
      </div>
      <div style={{ display: "flex", height: graphHeight, marginBottom: "1rem" }}>
        {graph.datasets?.[0].data?.length > 0 ?
          <LineGraph
            graphShadow={graphShadow}
            datasets={graph.datasets}
            labels={graph.labels}
            tooltips={graph.tooltips}
            measuresUnit={measuresUnit}
            loadingMatrix={loadingMatrix}
            matrixImage={matrixImage}
            avg={graph.avg}
            minMax={graph.minMax}
            minMaxRange={graph.minMaxRange}
            checkedMenu={checkedMenu}
            onChangeMenu={handleChangeMenu}
          />
          :
          <NoDataMessage fullWidth/>
        }
      </div>
      <MeasureSelection
        disabled={sensor?.measures.length <= 1}
        compare={compare}
        measuresOptions={sensor?.measures}
        compareOptions={compareMeasuresOptions}
        selectedMeasure={selectedMeasure}
        selectedCompare={selectedCompareMeasure}
        onChangeMeasure={handleChangeMeasure}
        onChangeCompare={handleChangeCompare}
        onChangeCompareMeasure={handleChangeSelectedCompare} />
    </Paper>
  );
};

export default SensorGraph;
