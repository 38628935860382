import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import PersonIcon from "@mui/icons-material/Person";
import { logout } from "app/store/evocsSlice/login/loginActions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { routesNames } from "app/configs/routesNames";
import { setUserRole, setUserTenant } from "app/store/evocsSlice/user/userAction";
import { resetAllFilters } from "app/store/evocsSlice/filters/filtersActions";
import useMobile from "src/app/hooks/useMobile";
import AvatarImage from "src/components/SharingComponents/Inputs/AvatarImage";

const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";

const UserTenantList = ({ onClose }) => {
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const handleClickItem = (tenantId) => {
    const newRoleId = tenants[tenantId].roles.find((role) => role.roleName === "admin" || role.roleName === "superAdmin")._id;
    setUserRole(newRoleId);
    setUserTenant(tenantId);
    resetAllFilters();
    handleClose();
  };

  return (
    <>
      <MenuItem role="button" onClick={handleClick}>
        <ListItemIcon>
          <EngineeringIcon />
        </ListItemIcon>
        <ListItemText primary="Tenants" />
      </MenuItem>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ background: "#242a30" }}>
          {Object.keys(tenants).map((tenantId) => (
            <MenuItem
              key={tenantId}
              sx={{ color: tenantId === user.selectedTenant && "red" }}
              role="button"
              onClick={() => handleClickItem(tenantId)}
            >
              <ListItemText primary={tenants[tenantId].name} />
            </MenuItem>
          ))}
        </div>
      </Popover>
    </>
  );
};

const UserMenu = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const [userMenu, setUserMenu] = useState(null);
  const [xs] = useMobile();
  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <>
      <Button sx={{height: "fit-content"}} onClick={userMenuClick}>
        <AvatarImage disabled style={{width: "35px", height: "35px"}} value={user.proPic}/>
        {!xs && <span style={{marginLeft: "1rem", fontSize: "12px"}}>{user?.username}</span>}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ background: "#242a30" }}>
          <MenuItem
            onClick={() => navigate(routesNames.USER_PROFILE_VIEW)}
            role="button"
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </MenuItem>
          {user.tenantId === tilebytesTenantId && Object.keys(tenants).length > 1 && <UserTenantList onClose={userMenuClose}/>}
          <MenuItem onClick={() => handleLogoutClick()} role="button">
            <ListItemIcon>
              <FuseSvgIcon>heroicons-outline:lock-open</FuseSvgIcon>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </div>
      </Popover>
    </>
  );
}

export default UserMenu;
