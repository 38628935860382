import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { viewNames } from "app/configs/routesNames";
import TabsComponent from "../SharingComponents/TabsComponent";
import StationsManagement from "./StationsManagement";
import CusManagement from "./CusManagement";
import SensorsManagement from "./SensorsManagement";
import InstalledUnits from "./InstalledUnits";
import useMobile from "src/app/hooks/useMobile";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import UninstallModal from "./UninstallModal";
import MeasuresManagement from "./MeasuresManagement";
import useActions from "src/app/hooks/useActions";
import ConfigurationModals from "./ConfigurationModals";
import { getTenantCus, getTenantSensors, getTenantStations } from "app/store/evocsSlice/tenants/tenantsActions";
import LoadingGif from "../FileManager/LoadingGif";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { getStationInfo, setDevicesLoading, setStationsLoading } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import { getCusInfo, setCusLoading } from "app/store/evocsSlice/controlUnits/cuActions";
import { getSensorInfo } from "app/store/evocsSlice/sensors/sensorsActions";

const viewName = viewNames.CONFIGURATION_VIEW;


const ConfigurartionContent = () => {
  const { t } = useTranslation("evocs");
  const user = useSelector((state) => state.user);
  const userActions = useActions(viewName);
  const [xs, sm] = useMobile();
  const enabledActions = useMemo(() => {
    return userActions.map((action) => action.name)
  }, [userActions]);
  const [selectedTab, setSelectedTab] = useState("stations");
  const [selectedStations, setSelectedStations] = useState([]);
  const [selectedCus, setSelectedCus] = useState([]);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);

  const [allSelectedUnits, setAllSelectedUnits] = useState([]);
  const [draggedItem, setDraggedItem] = useState();
  const [undroppableUnits, setUndroppableUnits] = useState([]); // lista delle unità dove non è possibile droppare l'elemnto draggato (elementi trasparenti)
  const [modal, setModal] = useState();

  const [loadingDevices, setLoadingDevices] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState(user?.selectedTenant);

  const selectedTenantRef = useRef(selectedTenant);
  const userRef = useRef(user);

  useEffect(() => {
    selectedTenantRef.current = selectedTenant;
    userRef.current = user;
  }, [selectedTenant, user]);

  useEffect(() => {
    return () => {
      if(selectedTenantRef.current === userRef.current.selectedTenant) return;
      setDevicesLoading(true);
      getAllDevices([user.selectedTenant]).then(() => setDevicesLoading(false));
    }
  }, [])

  useEffect(() => {
    if (!selectedTenant) return;
    deselectAllUnits();
    getAllDevices([selectedTenant]);
  }, [selectedTenant])

  useEffect(() => {
    if(xs || sm) deselectAllUnits();
  }, [xs, sm])

  useEffect(() => {
    if (!draggedItem) { setUndroppableUnits([]); return };
    const type = draggedItem.type;
    switch (type) {
      case "cu":
        setUndroppableUnits(selectedCus.concat(selectedSensors));
        break;
      case "sensor":
        setUndroppableUnits(selectedSensors.concat(selectedMeasures));
        break;
      case "measure":
        setUndroppableUnits(selectedMeasures.concat(selectedCus).concat(selectedStations))
        break;
    }
  }, [draggedItem])

  const getAllDevices = useCallback(async (tenantIds) => {
    setLoadingDevices(true)
    const fetchData = [
      getStationInfo(tenantIds),
      getCusInfo(tenantIds),
      getSensorInfo(tenantIds),
    ];
    await Promise.all(fetchData).then(() => setLoadingDevices(false));
  }, [selectedTenant]);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };

  const handleClickUnit = useCallback((type, selected, changed) => {
    const index = allSelectedUnits.findIndex((unit) => unit.id === changed);
    switch (type) {
      case "station":
        setSelectedStations(selected);
        break;
      case "cu":
        setSelectedCus(selected);
        break;
      case "sensor":
        setSelectedSensors(selected);
        break;
      case "measure":
        setSelectedMeasures(selected);
        break;
    }

    if (index < 0)
      setAllSelectedUnits([
        { id: changed, type},
        ...allSelectedUnits
      ]);
    else
      setAllSelectedUnits(allSelectedUnits.toSpliced(index, 1));

  }, [allSelectedUnits, loadingDevices])

  const deselectUnit = useCallback((type, unitId) => {
    let newSelected = [];
    const index = allSelectedUnits.findIndex((unit) => unit.id === unitId);
    if (type === "station") {
      newSelected = selectedStations.filter((stationId) => stationId !== unitId);
      setSelectedStations(newSelected);
    }
    else if (type === "cu") {
      newSelected = selectedCus.filter((cuId) => cuId !== unitId);
      setSelectedCus(newSelected);
    }
    else if (type === "sensor") {
      newSelected = selectedSensors.filter((sensorId) => sensorId !== unitId)
      setSelectedSensors(newSelected);
    }
    else if (type === "measure") {
      newSelected = selectedMeasures.filter((measureId) => measureId !== unitId)
      setSelectedMeasures(newSelected);
    }

    setAllSelectedUnits((prev) => prev.toSpliced(index, 1));
  }, [allSelectedUnits])

  const handleClickItemMenu = useCallback(async(action, device = null, actionCallback = null) => {
    let modalType = action, modalText = null, callback = actionCallback;
    if (action === "delete") {
      switch (device.type) {
        case "station":
          if (device.list_cu.length === 0 && device.list_sensors.length === 0) {
            modalType = "warning"
            modalText = `${handleTranslate("POPUP_WARNING_DELETE_STATION")} ${device.serial}?`;
            callback = () => { actionCallback(); deselectUnit(device.type, device.id); };
          } else {
            modalType = "error";
            modalText = `${handleTranslate("POPUP_ERROR_DELETE_STATION_2")} ${device.serial}. ${handleTranslate("POPUP_ERROR_DELETE_STATION_2")}.`;
            callback = null;
          }
          break;
        case "cu":
          if (!device.deviceParent && device.list_sensors.length === 0) {
            modalType = "warning"
            modalText = `${handleTranslate("POPUP_WARNING_DELETE_CONTROL_UNIT")} ${device.serial}?`;
            callback = () => { actionCallback(); deselectUnit(device.type, device.id) };
          } else {
            modalType = "error";
            modalText = `${handleTranslate("POPUP_ERROR_DELETE_CONTROL_UNIT_1")} ${device.serial}. ${handleTranslate("POPUP_ERROR_DELETE_CONTROL_UNIT_2")}.`;
            callback = null;
          }
          break;
        case "sensor":
          if (!device.deviceParent) {
            modalType = "warning"
            modalText = `${handleTranslate("POPUP_WARNING_DELETE_SENSOR")} ${device.serial}?`;
            callback = () => { actionCallback(); deselectUnit(device.type, device.id) };
          } else {
            modalType = "error";
            modalText = `${handleTranslate("POPUP_ERROR_DELETE_SENSOR_1")} ${device.serial}. ${handleTranslate("POPUP_ERROR_DELETE_SENSOR_2")}.`;
            callback = null;
          }
          break;
      }
    } else if (action === "uninstall") {
      modalType = "warning"
      switch (device.type) {
        case "cu":
          modalText = `${handleTranslate("POPUP_WARNING_UNINSTALL_CONTROL_UNIT")} ${device.serial}?`
          break;
        case "sensor":
          modalText = `${handleTranslate("POPUP_WARNING_UNINSTALL_SENSOR")} ${device.serial}?`
          break;
        case "measure":
          modalText = `${handleTranslate("POPUP_WARNING_REMOVE_MEASURE")} ${device.label}?`
          break;
      }
    }
    setModal({ device: device, modalType, modalText, onConfirm: callback });
  }, [deselectUnit]);

  const handleDropItem = useCallback((action = null) => {
    if (!action) {
      setModal({ modalType: "error", modalText: `${handleTranslate("DEVICE_ALREADY_INSTALLED")}` });
    } else {
      action();
    }
  }, [])

  const handleSelectUnit = useCallback((type, unitId) => {
    const index = allSelectedUnits.findIndex((unit) => unit.id === unitId);
    if (index >= 0) return; // se l'unità è già selezionata
    switch (type) {
      case "cu":
        setSelectedCus((prev) => [...prev, unitId]);
        break;
      case "sensor":
        setSelectedSensors((prev) => [...prev, unitId]);
        break;
      case "measure":
        setSelectedMeasures((prev) => [...prev, unitId]);
        break;
    }

    setAllSelectedUnits((prev) => [
      { id: unitId, type},
      ...prev]);
  }, [allSelectedUnits, loadingDevices])

  const deselectAllUnits = useCallback(() => {
    setSelectedStations([]);
    setSelectedCus([]);
    setSelectedSensors([]);
    setAllSelectedUnits([]);
  }, [])


  const tabs = useMemo(() => {
    return {
      stations: {
        header: handleTranslate('STATIONS', true),
        content: <StationsManagement loading={loadingDevices} selectedTenant={selectedTenant} selectedStations={selectedStations} enabledActions={enabledActions} onChangeTenant={setSelectedTenant} onStationItemClick={(selected, changed) => handleClickUnit("station", selected, changed)}
          onClickItemMenu={handleClickItemMenu} />
      },
      controlUnits: {
        header: handleTranslate('CONTROL_UNITS', true),
        content: <CusManagement loading={loadingDevices} selectedTenant={selectedTenant} selectedCus={selectedCus} enabledActions={enabledActions} onChangeTenant={setSelectedTenant} onCuItemClick={(selected, changed) => handleClickUnit("cu", selected, changed)}
          onDragCu={setDraggedItem} onClickItemMenu={handleClickItemMenu} />
      },
      sensors: {
        header: handleTranslate('SENSORS', true),
        content: <SensorsManagement loading={loadingDevices} selectedTenant={selectedTenant} selectedSensors={selectedSensors} enabledActions={enabledActions} onChangeTenant={setSelectedTenant} onSensorItemClick={(selected, changed) => handleClickUnit("sensor", selected, changed)}
          onDragSensor={setDraggedItem} onClickItemMenu={handleClickItemMenu} />
      },
      measures: {
        header: handleTranslate('MEASURES', true),
        content: <MeasuresManagement loading={loadingDevices} enabledActions={enabledActions} onDragMeasure={setDraggedItem} onClickItemMenu={handleClickItemMenu} />
      }
    }
  }, [loadingDevices, selectedTenant, allSelectedUnits, enabledActions, t]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box height={"100%"} padding={{ xs: "1rem", sm: "2rem 5rem" }}>
        <Grid container spacing={{xs: 0, md: 2}} sx={{ width: "100%", height: "100%" }}
          display={"flex"} flexDirection={"row"} justifyContent={"center"} alignContent={"end"}>
          <Grid item xs={12} md={6}>
            <TabsComponent style={{ height: xs || sm ? "80vh" : "85vh" }} paper tabs={tabs} selectedTab={selectedTab} onChange={setSelectedTab} />
          </Grid>
          <Grid display={{xs: "none", md: "block"}} item xs={12} md={6}>
            <InstalledUnits enabledActions={enabledActions} selectedUnits={allSelectedUnits} undroppableUnits={undroppableUnits} onSelectUnit={handleSelectUnit} onDeselectUnit={deselectUnit} onDragItem={setDraggedItem} onDropItem={handleDropItem} onClickItemMenu={handleClickItemMenu} />
          </Grid>
        </Grid>
      </Box>
      <ConfigurationModals enabledActions={enabledActions} device={modal?.device} modalType={modal?.modalType} modalText={modal?.modalText} onConfirm={modal?.onConfirm} onClose={() => setModal(null)} />
      {enabledActions.includes("manage-device") && <UninstallModal isOpen={draggedItem ? true : false} onDrop={handleClickItemMenu}/> }
    </DndProvider>
  );
}

export default ConfigurartionContent;