import { useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import AvatarImage from '../SharingComponents/Inputs/AvatarImage';
import LanguageSelect from '../SharingComponents/Inputs/LanguageSelect';
import EditCard from '../SharingComponents/EditCard';
import { updateUser } from 'app/store/evocsSlice/user/userAction';


const UserProfileContent = () => {
  const user = useSelector((state) => state.user);
  const tenants = useSelector((state) => state.tenants.list);
  const tenantId = user.tenantId;
  const roleName = tenants?.[tenantId]?.roles.filter((role) => role?.["_id"] !== user.roleId).map((role) => role?.["roleName"])[0];

  const defaulInputs = useMemo(() => {
    return {
      userImage: user.proPic,
      username: user.username,
      email: user.email,
      tenantId: tenantId,
      language: user.language || "", 
      roleName : roleName,
    }
  }, [user])
  const [inputs, setInputs] = useState(defaulInputs);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: value });
    setInputsChanged(true); 
  };

  const handleCancelChanges = () => {
    setInputs(defaulInputs);
    setInputsChanged(false);
  };

  const handleSave = () => {
    updateUser(user.sqlId, inputs.language, inputs.userImage);
    setInputsChanged(false);
  };

  return (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <EditCard style={{width: "fit-content", margin: "0 auto"}} isClosable={false} disableSave={!isInputsChanged} onChangeEdit={setEdit} onCancelChanges={handleCancelChanges} onSave={handleSave}>
        <div style={{display: "flex", gap: "1rem", flexDirection: "column", alignItems: "center", margin: "0 auto"}}>
          <AvatarImage disabled={!isEdit} value={inputs.userImage} onChange={(value) => handleChangeInput("userImage", value)}/>
          <div style={{display: "flex", justifyContent: "center", gap: "1rem", flexWrap: "wrap"}}>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='username' label='Username' value={inputs.username} onChange={(e) => handleChangeInput("username", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='email' label='Email' value={inputs.email} onChange={(e) => handleChangeInput("email", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='tenant' label="Tenant" value={tenants[inputs.tenantId]?.name || ""} onChange={(e) => handleChangeInput("tenantId", e.target.value)}/>
            <TextField sx={{width: "100%", maxWidth: "300px"}} disabled id='roleName' label="Role" value={inputs.roleName || ""} onChange={(e) => handleChangeInput("roleId", e.target.value)}/>
            <LanguageSelect disabled={!isEdit} value={inputs.language} onChange={(value) => handleChangeInput("language", value)}/>
          </div>
        </div>
      </EditCard>
    </Box>
  )
};

export default UserProfileContent;