import { Box } from "@mui/system";
import StationHandlingList from "./StationHandlingList";
import StationHandlingContentHeader from "./StationHandlingContentHeader";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { viewNames } from "app/configs/routesNames";
import useFilters from "src/app/hooks/useFilters";
import { getStationMeasures } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import useLoadingDevices from "src/app/hooks/useLoadingDevices";
import LoadingGif from "../FileManager/LoadingGif";

const viewName = viewNames.STATIONS_VIEW;

const StationHandlingContent = () => { 
  const loadingDevices = useLoadingDevices();
  const availableStations = useSelector((state) => state.stationsMongo.available);
  const [stationList, setStationList] = useState();
  const filters = useFilters(viewName);
  const stationSelect = filters?.stationName;
  const measureSelect = filters?.measure;

  const stationsMeasures = useMemo(() => {
    const obj = {};
    availableStations?.forEach((stationId) => {
      obj[stationId] = getStationMeasures(stationId, true);
    });
    return obj;
  }, [loadingDevices, availableStations])

  const getStationList = useCallback(() => {
    const stations = stationSelect.selected?.length > 0 ? stationSelect.selected : availableStations;
    const selectedMeasures = measureSelect?.selected || [];
    if(selectedMeasures.length === 0) return stations;
    else return stations.filter((stationId) => selectedMeasures.every((measureId) => stationsMeasures[stationId].includes(measureId)));;
  }, [stationsMeasures, availableStations, stationSelect?.selected, measureSelect?.selected]);

  useLayoutEffect(() => {
    setStationList(getStationList());
  }, [getStationList])


  return (
    <Box padding={{xs: "1rem", sm: "2rem 5rem"}}>
      <StationHandlingContentHeader filtersInView={filters} stationsMeasures={stationsMeasures}/>
      {!loadingDevices ? 
        <StationHandlingList filtersInView={filters} list={stationList}/>
        :
        <LoadingGif />
      }
    </Box>
    )
}

export default StationHandlingContent;