import { Button, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const RecoverPassword = () => {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState(false);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  useEffect(() => {
    if (!password || password === "") setConfirm(false);
    else setConfirm(true);
  }, [password]);

  const handleSubmit = () => { };

  return (
    <Paper sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", padding: "2rem", width: "95%", maxWidth: "400px" }}>
      <form style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "2rem" }} onSubmit={handleSubmit}>
        <h2 style={{ fontWeight: "bold" }}>Cambia account password</h2>
        <p style={{ textAlign: "center" }}>Inserisci una nuova password per ciccio@gmail.com</p>
        <TextField fullWidth id='password' type='password' label="Password" autoComplete="new-password" required value={password} onChange={(e) => setPassword(e.target.value)} />
        <Button fullWidth type='submit' disabled={!confirm} className='custom-button dark'> {handleTranslate('CONFIRM', true)} </Button>
      </form>

    </Paper>
  )
}

export default RecoverPassword;