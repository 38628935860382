import { CropLandscapeOutlined } from "@mui/icons-material";
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";
import { setGenericError } from "../globalError/errorSlice";
import {
  STATION_DATA_TYPE,
  gasMoleculeStructure,
  timeRangeInterval,
} from "src/components/StationHandling/utils";
import { differenceInHours, subtractHours } from "src/utilities/utils";
import { modeStructure } from "src/components/StationHandling/utils";
import { array } from "prop-types";
import { de } from "date-fns/locale";
import { parseTwoDigitYear } from "moment";
import { last } from "lodash";

const switchGasMolecule = (value) => {
  switch (value) {
    case "GAS_B":
      return "BENZENE";
    case "GAS_E":
      return "ETILENE";
    case "GAS_T":
      return "TOLUENE";
    case "GAS_X":
      return "XYLENE";
  }
};

const orderCuModesArray = (array) => {
  const hasVOC = array.includes(STATION_DATA_TYPE.voc);
  const hasGAS = array.includes(STATION_DATA_TYPE.gas);
  const hasMETEO = array.includes(STATION_DATA_TYPE.meteo);
  const hasTILESTATION = array.includes("TILESTATION");
  const sortedArray = [];
  if (hasVOC) sortedArray.push(STATION_DATA_TYPE.voc);
  if (hasGAS) sortedArray.push(STATION_DATA_TYPE.gas);
  if (hasMETEO) sortedArray.push(STATION_DATA_TYPE.meteo);
  if (hasTILESTATION) sortedArray.push("TILESTATION");
  return sortedArray;
};

const calculateAverage = (array) => {
  const validValue = array.filter((value) => value !== null);
  if (validValue.length == 0) return null;
  const sum = validValue.reduce((acc, value) => acc + value, 0);
  return parseFloat(
    (validValue.length === 0 ? 0 : sum / validValue.length).toFixed(2)
  );
};

const calculateMin = (array) => {
  return Math.min(...array);
};

const calculateMax = (array) => {
  return Math.max(...array);
};

const getThreeHourArray = (end, data) => {
  let start = new Date(end);
  start.setTime(start.getTime() - 3 * 60 * 60 * 1000);
  start = start.getTime();
  let newData = data.filter((e) => e[0] >= start && e[0] <= end);
  newData = reduceValueForGraph(newData, newData.lenght);
  return { startThreeHour: start, dataThreeHour: newData };
  // return {startThreeHour: start, data: dataThreeHour}
};

const reduceValueForGraph = (array, len) => {
  const graphPoint = 40;
  if (len <= graphPoint) return array;
  const n = Math.floor(len / graphPoint) + 1;
  return array.filter((elem, index) => index % n == 0);
};

const getAllStation = createAsyncThunk(
  "stations/getAllStation",
  async (configStations, { dispatch, getState }) => {
    // console.log('getAllStation AsyncThunk');
    let returnData = {};
    const data = await axios
      .get(`/api/rest/station/getAllStation`)
      .then((res) => res.data.value[0] || [])
      .catch((error) => {
        console.log("Error: ", error);
      });
      // console.log("data: ", data)
    data.forEach((value) => {
      const { id, name, repositoryType, tenant, flStation, location } = value;
      returnData[value.id] = {
        id,
        name,
        repositoryType,
        tenant,
        flStation,
        location,
      };
    });
    return [configStations, returnData];
  }
);

const getLastStationPositions = createAsyncThunk(
  "stations/getLastStationPosition",
  async (payload, { dispatch, getState }) => {
    console.log("getLastStationPosition AsyncThunk");
    if (!payload) return;
    let { gps, stationId, lastPos, startingDate, endingDate } = payload;
    if (!startingDate || !endingDate){
      endingDate = new Date();
      startingDate = subtractHours(startingDate, 1);
      startingDate = startingDate.getTime();
      endingDate = endingDate.getTime();
    }

    let data = null;
    if (gps === "router") {
      const pos = await axios
        .post(`/api/rest/cu/devicePosition`, [
          startingDate,
          endingDate,
          parseInt(stationId),
        ])
        .then((res) => {
          // console.log('Getting last positions with gps = router');
          if (!res.data || !res.data.value || res.data.value.length == 0)
            return null;
          if (!lastPos) {
            let positions = res.data.value.map((value) => {
              return {
                lat: value[1],
                lon: value[2],
                time: new Date(value[3]).getTime(),
              };
            });
            return positions.sort((a, b) => b.time - a.time);
          } else {
            let lastPos = {
              lat: null,
              lon: null,
              time: null,
            };
            res.data.value.forEach((value) => {
              if (lastPos.lat === null) {
                lastPos.lat = value[1];
                lastPos.lon = value[2];
                lastPos.time = new Date(value[3]).getTime();
              } else {
                const newTime = new Date(value[3]);
                if (newTime > new Date(lastPos.time)) {
                  lastPos.lat = value[1];
                  lastPos.lon = value[2];
                  lastPos.time = newTime.getTime();
                }
              }
            });
            return lastPos;
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          return null;
        });
      data = pos;
    } else if (gps === "tilestation") {
      // console.log("getting position from tilestation");
        const body = 
          {
            stationId: parseInt(stationId),
            start: Math.floor(startingDate/1000),
            end: Math.floor(endingDate/1000),
            sorting: 'desc',
            lastPos: lastPos
          };
        if (lastPos === true) {
          body.limit = 1;
        }
        // console.log('body: ', body);
        const pos = await axios
          .post(`/api/py/call_mongodb_api`, body)
          .then((res) => {
            if (res.data.value.documents && res.data.value.documents.length > 0) {
              // console.log('Retrieving mongodb gps data');
              return res.data.value.documents.map((doc) => {
                return {
                  lat: doc.lat,
                  lon: doc.lon,
                  time: new Date(doc.date).getTime(),
                }
              });
            };
            return null;
          })
          .catch((error) => {
            console.log("Error: ", error);
            return null;
          });
        data = lastPos ? pos[0] : pos;
    }
    return data;
  }
);

export const createPdfReportFromHtml = createAsyncThunk(
  "stations/createPdfReportFromHtml",
  async (station, { dispatch, getState }) => {
    const body =
      {
        // absoluteFileName: "/home/jboss/kamjl/upload/T000001/test_report2.html",
        absoluteFileName: "/home/jboss/kamjl/upload/T000001/AUTOREPORT_20240124/auto_report20240125_stkronos.html",
        overwriteIfExist: true,
      }
    ;
    const data = await axios
      .post(`/api/rest/files/convert`, body)
      .then((res) => {
        console.log("res: ", res);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

export const apiTestDjango = createAsyncThunk(
  "stations/apiTestDjango",
  async (station, { dispatch, getState }) => {
    const data = await axios
      .get(`/api/py/test`)
      .then((res) => {
        console.log("res: ", res);
        return res;
      })
      // .catch((error) => {
      //   console.log("Error: ", error);
      // });
    return data;
  }
);

export const getStationVocDevice = createAsyncThunk(
  "stations/getStationVocDevice",
  async (pay, { dispatch, getState }) => {
    // console.log("getStationVocDevice Thunk");
    try {
      let data = await axios
        .get(`/api/rest/data/read/StationControlUnitVoc`)
        .then((res) => {
          if (res.data.value.length > 0) {
            return res.data.value;
          }
          return [];
        });
      data = data.filter((e) => e.ending === null);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getHistVocDevice = createAsyncThunk(
  "stations/getHistVocDevice",
  async (pay, { dispatch, getState }) => {
    // console.log("getHistVocDevice Thunk");
    try {
      let data = await axios
        .get(`/api/rest/data/read/StationControlUnitVoc`)
        .then((res) => {
          if (res.data.value.length > 0) {
            return res.data.value;
          }
          return [];
        });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getVocHistory = createAsyncThunk(
  "history/getVocHistory",
  async (pay, { dispatch, getState }) => {
    // console.log("getVocHistory Thunk");
    try {
      let devices = await dispatch(getHistVocDevice());
      devices = devices.payload;
      let stationHystRow = {};
      let cuHystRow = {};
      let vocStationHistory = {};
      let vocCuHistory = {};
      Object.values(devices).forEach((row) => {
        let { cuSerial, id, starting, ending, repository } = row;
        if (!repository) return;
        
        stationHystRow = {
          starting: starting,
          ending: ending,
          cuSerial: cuSerial,
        };
        // console.log("stationHystRow", stationHystRow);
        cuHystRow = {
          starting: starting,
          ending: ending,
          stationId: repository.id,
        };
        // console.log("cuHystRow", cuHystRow);

        vocStationHistory[repository.id]
          ? vocStationHistory[repository.id].push(stationHystRow)
          : (vocStationHistory[repository.id] = [stationHystRow]);
        vocCuHistory[cuSerial]
          ? vocCuHistory[cuSerial].push(stationHystRow)
          : (vocCuHistory[cuSerial] = [stationHystRow]);
      });
      // console.log('vocStationHistory', vocStationHistory);
      // console.log('vocCuHistory', vocCuHistory);
      // let stations = getState().stations.stations;
      let s = JSON.parse(JSON.stringify(getState().stations.stations));
      Object.entries(vocStationHistory).forEach(([key, value]) => {
        if (!Object.keys(s).includes(key)) return;
        // console.log(key, value);
        const temp = { [STATION_DATA_TYPE.voc]: value };
        // s[key]["history"] ? {...s[key]["history"], temp} : temp;
        s[key]["history"] = temp;
      });
      return s;
    } catch (error) {
      console.log(error);
    }
  }
);




export const getStationGasDevice = createAsyncThunk(
  "stations/getStationGasDevice",
  async (payload, { dispatch, getState }) => {
    try {
      // console.log("getStationGasDevice Thunk")
      let data = await axios
        .get(`/api/rest/data/read/StationControlUnitGas`)
        .then((res) => {
          // console.log("Response: ", res)
          if (res.data.value.length > 0) {
            return res.data.value;
          }
          return [];
        });
      data = data.filter((e) => e.ending === null);
      return data;
    } catch (error) {}
  }
);

export const getStationMeteoDevice = createAsyncThunk(
  "stations/getStationMeteoDevice",
  async (payload, { dispatch, getState }) => {
    try {
      // console.log("getStationMeteoDevice Thunk")
      let data = await axios
        .get(`/api/rest/data/read/StationControlUnitMeteo`)
        .then((res) => {
          if (res.data.value.length > 0) {
            return res.data.value;
          }
          return [];
        });
      data = data.filter((e) => e.ending === null);
      return data;
    } catch (error) {}
  }
);

export const getHistMeteoDevice = createAsyncThunk(
  "stations/getHistMeteoDevice",
  async (payload, { dispatch, getState }) => {
    try {
      let data = await axios
        .get(`/api/rest/data/read/StationControlUnitMeteo`)
        .then((res) => {
          if (res.data.value.length > 0) {
            return res.data.value;
          }
          return [];
        });
      return data;
    } catch (error) {}
  }
);

export const getMeteoHistory = createAsyncThunk(
  "history/getMeteoHistory",
  async (pay, { dispatch, getState }) => {
    try {
      let devices = await dispatch(getHistMeteoDevice());
      devices = devices.payload;
      let stationHystRow = {};
      let cuHystRow = {};
      let meteoStationHistory = {};
      let meteoCuHistory = {};
      Object.values(devices).forEach((row) => {
        let { cuSerial, id, starting, ending, repository } = row;
        if (!repository) return;

        stationHystRow = {
          starting: starting,
          ending: ending,
          cuSerial: cuSerial,
        };
        // console.log("stationHystRow", stationHystRow);
        cuHystRow = {
          starting: starting,
          ending: ending,
          stationId: repository.id,
        };
        // console.log("cuHystRow", cuHystRow);

        meteoStationHistory[repository.id]
          ? meteoStationHistory[repository.id].push(stationHystRow)
          : (meteoStationHistory[repository.id] = [stationHystRow]);
        meteoCuHistory[cuSerial]
          ? meteoCuHistory[cuSerial].push(stationHystRow)
          : (meteoCuHistory[cuSerial] = [stationHystRow]);
      });
      // console.log('meteoStationHistory', vocStationHistory);
      // console.log('meteoCuHistory', meteoCuHistory);
      // let stations = getState().stations.stations;
      let s = JSON.parse(JSON.stringify(getState().stations.stations));
      Object.entries(meteoStationHistory).forEach(([key, value]) => {
        if (!Object.keys(s).includes(key)) return;
        // console.log(key, value);
        const temp = { [STATION_DATA_TYPE.meteo]: value };
        s[key]["history"] = temp;
        // s[key]["history"] ? {...s[key]["history"], temp} : temp;
      });
      return s;
    } catch (error) {
      console.log(error);
    }
  }
);


export const getStationTileDevice = createAsyncThunk(
  "stations/getStationTileDevice",
  async () => {
    const data = axios.get("/api/py/getStationTileDevice")
    .then((res) => {
      return res.data.value;
    }).catch((error) => console.log("Error: ", error));
    return data;
  }
);

export const getVocData = createAsyncThunk(
  "stations/getVocData",
  async (payload) => {
    // console.log('getVocData AsyncThunk');
    const { startingDate, endingDate, stationId, cuSerial, intervalId } =
      payload;
    try {
      // console.log("getVocData thunk")
      const data = await axios
        .post(`/api/rest/cu/data`, [
          startingDate,
          endingDate,
          cuSerial,
          "VOC",
          null,
          false,
        ])
        .then((res) => {
          // console.log('in response getVocData');
          if (!res.data.value) return [];
          if (res.data.value[0]) {
            try {
              // return reduceValueForGraph(res.data.value[0], res.data.value[0].length)
              return res.data.value[0];
            } catch (error) {
              console.log('error', error);
              return [];
            }
          }
        });
      return [stationId, data, intervalId, startingDate, endingDate];
    } catch (error) { console.log('error:', error);}
  }
);

export const getGasData = createAsyncThunk(
  "stations/getGasData",
  async (payload) => {
    const {
      startingDate,
      endingDate,
      stationId,
      gasName,
      intervalId,
      tenant,
      flStation,
    } = payload;
    try {
      const data = await axios
        .post(`/api/rest/cu/data`, [
          startingDate,
          endingDate,
          stationId,
          gasName,
          tenant,
          flStation,
        ])
        .then((res) => {
          if (!res.data.value) return [];
          if (res.data.value[0].length > 0)
            reduceValueForGraph(res.data.value[0], res.data.value[0].length);
          // return res.data.value[0];
          return [];
        });
      return [
        stationId,
        switchGasMolecule(gasName),
        data,
        intervalId,
        startingDate,
        endingDate,
      ];
    } catch (error) {}
  }
);
// recupera i dati per la windrose
// export const getWindRoseData = createAsyncThunk(
//   "stations/getWindRoseData",
//   async (payload, { dispatch, getState }) => {
//     try {
//       // console.log("getWindRoseData thunk")
//       const {
//         startingDate,
//         endingDate,
//         stationId,
//         tenant,
//         flStation,
//         intervalId,
//       } = payload;
//       const data = await axios
//         .post(`/api/rest/cu/deviceMeteoWind`, [
//           startingDate,
//           endingDate,
//           stationId,
//           tenant,
//           flStation,
//         ])
//         .then((res) => {
//           // console.log('Nel res del meteo');
//           if (res.data.value[0]) return res.data.value[0];
//           return [];
//         });
//       // console.log('Prima del return del meteo')
//       return [stationId, data, intervalId, startingDate, endingDate];
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

export const getWindRoseData = createAsyncThunk(
  "stations/getWindRoseData",
  async (payload, { dispatch, getState }) => {
    try {
      // console.log("getWindRoseData thunk")
      const {
        startingDate,
        endingDate,
        stationId,
        tenant,
        flStation,
        intervalId,
      } = payload;

      let params = null;      
      const cuSerial = getState().stations?.stations[stationId].meteoDevice?.cuSerial;
      // console.log('cuSerial', cuSerial);
      if (cuSerial && cuSerial === "LS0220070008")
        params = 
      {
        'start_date': Math.floor(startingDate / 1000),
        'end_date': Math.floor(endingDate / 1000),
        'v_stat' : "False",
        'v_cent' : "True",
        'centralina' : cuSerial,
        }
      else
        params = 
      { 
        'start_date': Math.floor(startingDate / 1000),
        'end_date': Math.floor(endingDate / 1000),
        'v_stat' : "True",
        'station' : stationId,
        'v_cent' : "False",
      }

      const data = await axios
        .get(`/api/py/get_windrose_data/`, {params: params})
        .then((res) => {
          // console.log('Nel res di getWindRoseData');
          if (res.data[0] && 
            res.data[0].response !== "No data for the selected station in this time interval.") 
            {
            return res.data.map(obj => Object.values(obj)[0]);
          }
          return [];
        });
      // console.log('Prima del return del meteo')
      return [stationId, data, intervalId, startingDate, endingDate];
    } catch (error) {
      console.log(error);
    }
  }
);

export const getMeteoData = createAsyncThunk(
  "stations/getMeteoData",
  async (val, { dispatch, getState }) => {
    try {
      // console.log("getMeteoData thunk")
      const {
        stationId,
        end,
        field,
        flStation,
        orderBy,
        searchDatetime,
        start,
        intervalId,
        tenant,
      } = val;
      const cuSerial =
        getState().stations.stations[stationId].meteoDevice?.cuSerial;

      const data = await axios
        .get(`/api/rest/report/DeviceMeteo`, {
          params:
            {
              cuSerialNumber: stationId,
              end: end,
              field: field,
              flStation: flStation,
              orderBy: orderBy,
              searchDatetime: searchDatetime,
              start: start,
              tenant: tenant,
            },
        })
        .then((res) => {
          if ('value' in res.data) return [];
          if (res.data && res.data.values.length > 0)
            return reduceValueForGraph(res.data.values, res.data.values.length);
          // return res.data.values;
          return [];
        })
        .catch((error) => console.log("Error: ", error));

      const median = await axios
        .post("/api/rest/cu/windMedian", [start, end, cuSerial, null])
        .then((res) => {
          if (res.data.value === null) return ;
          return res.data.value[1];
        })
        .catch((error) => console.log("Error: ", error));
      return [stationId, intervalId, data, median];
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTilestationData = createAsyncThunk(
  "stations/getTilestationData",
  async (payload, { dispatch, getState }) => {
    // console.log('getTilestationData thunk');
    const {stationId, startingDate, endingDate, limit, sorting, intervalId} = payload;
    const body = 
    {
      stationId: stationId,
      // limit: limit,
      start: Math.floor(startingDate/1000),
      end: Math.floor(endingDate/1000),
      sorting: sorting
    };
    const data = await axios
      .post(`/api/py/call_mongodb_api`, body)
      .then((res) => {
        if (res?.data?.value?.documents) {
          // console.log('array di array', res.data.value.documents);
          return reduceValueForGraph(res.data.value.documents, res.data.value.documents.length);
          // return res.data.value.documents
        };
        return [];
      })
      .catch((error) => {
        throw new Error(error);
      });
    return {data, startingDate, endingDate, intervalId, stationId};
  }
);

export const addStation = createAsyncThunk(
  "stations/addStation",
  async (station, { dispatch, getState }) => {
    switch (station.Tipo) {
      case "FISSA":
        dispatch(addFixedStation(station));
        break;
      case "MOBILE":
        dispatch(addMobileStation(station));
        break;
      default:
        break;
    }
  }
);

const addFixedStation = createAsyncThunk(
  "stations/addFixedStation",
  async (station, { dispatch, getState }) => {
    const body = [
      {
        id: null,
        name: station.Nome,
        repositoryType: null,
        location: null,
        vehicleType: null,
        vehicleStatus: null,
        vehicleOwner: null,
        shipOwner: null,
        navigationType: null,
        vehicleOwnerHeadquarters: null,
        licensePlate: null,
        serialNumber: station.Seriale,
        matriculationNumber: null,
        picture: null,
        note: null,
        kboardVersion: null,
        kboardContact: null,
        kboard: false,
        statusUpdate: null,
        lastUpdate: null,
        startDate: null,
        endDate: null,
        flAvailable: true,
        tenant: null,
        repository: null,
        flStation: true,
        iconName: null,
        concurrent: true,
        uploaded: "1",
        link: "#ana-rep/null",
        labelValue: null,
      },
    ];
    const data = await axios
      .post(`/api/rest/data/update/Repository`, body)
      .then((res) => {
        return res.data.value[0];
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const addMobileStation = createAsyncThunk(
  "stations/addMobileStation",
  async (station, { dispatch, getState }) => {
    const body = [
      {
        id: null,
        name: station.Nome,
        repositoryType: null,
        location: null,
        vehicleType: null,
        vehicleStatus: null,
        vehicleOwner: null,
        shipOwner: null,
        navigationType: null,
        vehicleOwnerHeadquarters: null,
        licensePlate: null,
        serialNumber: station.Seriale,
        matriculationNumber: null,
        picture: null,
        note: null,
        kboardVersion: null,
        kboardContact: null,
        kboard: false,
        statusUpdate: null,
        lastUpdate: null,
        startDate: null,
        endDate: null,
        flAvailable: true,
        tenant: null,
        repository: null,
        flStation: false,
        iconName: null,
        concurrent: true,
        uploaded: "1",
        link: "#ana-vei/null",
        labelValue: station.Nome,
      },
    ];
    const data = await axios
      .post(`/api/rest/data/update/Vehicle`, body)
      .then((res) => {
        return res.data.value[0];
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

export const addDevice = createAsyncThunk(
  "stations/addDevice",
  async (device, { dispatch, getState }) => {
    switch (device.Tipologia) {
      case STATION_DATA_TYPE.voc:
        dispatch(addVOCDevice(device))
          .then((res) => {
            dispatch(connectDeviceToStation(device));
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
        break;
      case STATION_DATA_TYPE.gas:
        dispatch(addGASDevice(device))
          .then((res) => {
            dispatch(connectDeviceToStation(device));
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
        break;
      case STATION_DATA_TYPE.meteo:
        dispatch(addMeteoDevice(device))
          .then((res) => {
            dispatch(connectDeviceToStation(device));
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
        break;
      case "PBL":
        dispatch(addPBLDevice(device))
          .then((res) => {
            dispatch(connectDeviceToStation(device));
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
        break;
      default:
        break;
    }
  }
);

const addVOCDevice = createAsyncThunk(
  "stations/addVOCDevice",
  async (device, { dispatch, getState }) => {
    const body = [
      {
        id: null,
        name: device.Nome,
        serialNumber: device.Seriale,
        tenant: null,
        iconName: null,
        thresholdMin: null,
        thresholdMax: null,
        iconColor: null,
        deviceType: {
          confFieldToUpdate: null,
          confFieldToUpdatePhase: null,
          id: 1,
          name: "STAZIONE VOC DETECTOR",
          description: null,
          params: "VOC",
          labelValue: "STAZIONE VOC DETECTOR",
        },
        link: "#ana-dev-voc/null",
        labelValue: null,
      },
    ];
    const data = await axios
      .post(`/api/rest/data/update/DeviceVoc`, body)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const addGASDevice = createAsyncThunk(
  "stations/addGASDevice",
  async (device, { dispatch, getState }) => {
    const body = [
      {
        id: null,
        name: device.Nome,
        serialNumber: device.Seriale,
        tenant: null,
        iconName: null,
        thresholdGasB: null,
        thresholdGasE: null,
        thresholdGasT: null,
        thresholdGasX: null,
        deviceType: {
          confFieldToUpdate: null,
          confFieldToUpdatePhase: null,
          id: 1,
          name: "Pixys",
          description: null,
          params: "GAS_B,GAS_E,GAS_T,GAS_X",
          labelValue: "Pixys",
        },
        link: "#ana-dev-gas/null",
        labelValue: null,
      },
    ];
    const data = await axios
      .post(`/api/rest/data/update/DeviceGas`, body)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const addMeteoDevice = createAsyncThunk(
  "stations/addMeteoDevice",
  async (device, { dispatch, getState }) => {
    // console.log('addMeteoDevice');
    const body = [
      {
        id: null,
        name: device.Nome,
        serialNumber: device.Seriale,
        tenant: null,
        iconName: null,
        range1_min: null,
        range1_max: null,
        range2_min: null,
        range2_max: null,
        range3_min: null,
        range3_max: null,
        deviceType: {
          confFieldToUpdate: null,
          confFieldToUpdatePhase: null,
          id: 1,
          name: "STAZIONE METEO GILL",
          description: "",
          params:
            "METEO_WIND,METEO_TEMP,METEO_HUMIDITY,METEO_PRESSURE,METEO_RAINFALL,METEO_SOLAR",
          labelValue: "STAZIONE METEO GILL",
        },
        link: "#ana-dev-meteo/null",
        labelValue: null,
      },
    ];
    const data = await axios
      .post(`/api/rest/data/update/DeviceMeteo`, body)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

const addPBLDevice = createAsyncThunk(
  "stations/addPBLDevice",
  async (device) => {
    const body = [
      {
        id: null,
        name: device.Nome,
        serialNumber: device.Seriale,
        tenant: null,
        iconName: null,
        location: null,
        vehicle: null,
        flAvailable: true,
        link: "#ana-dev-pbl/null",
        labelValue: null,
      },
    ];
    const data = await axios
      .post("/api/rest/data/update/DevicePbl", body)
      .then((res) => {
        console.log("PBL: ", res);
        return res.data;
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);

export const connectDeviceToStation = createAsyncThunk(
  "stations/connectDeviceToStation",
  async (device) => {
    const body = [
      device.stationID,
      device.Tipologia,
      device.Seriale,
      new Date().getTime(),
      null,
    ];
    console.log("Body: ", body);
    const data = await axios
      .post("/api/rest/station/cu", body)
      .then((res) => {
        console.log("Response: ", res);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }
);

export const updateAllStationsData = createAsyncThunk(
  "stations/updateStationsData",
  async (payload, { dispatch, getState }) => {
    if (!payload) return;
    const { startingDate, endingDate } = payload;
    const stations = getState().stations.stations;

    for (const stationId in stations) {
      if (stations[stationId].hasOwnProperty(STATION_DATA_TYPE.voc)) {
        // console.log(stationId, " has voc")
        dispatch(
          getVocData({
            startingDate: startingDate.valueOf(),
            endingDate: endingDate.valueOf(),
            stationId: stationId,
            cuSerial: stations[stationId][vocDevice].cuSerial,
          })
        );
      }

      if (stations[stationId].hasOwnProperty(STATION_DATA_TYPE.gas)) {
        console.log(stationId, " has gas");
        const gasMeasure = ["GAS_B", "GAS_E", "GAS_X", "GAS_T"];
        for (const gasName of gasMeasure) {
          // console.log(stationId, " has gas")
          dispatch(
            getGasData([
              [
                startingDate.valueOf(),
                endingDate.valueOf(),
                stations[stationId].GASDevice.cuSerial,
                gasName,
                null, //tenant
                false, //flStation
              ],
              stationId,
            ])
          );
        }
      }

      if (stations[stationId].hasOwnProperty(STATION_DATA_TYPE.meteo)) {
        // console.log(stationId, " has meteo")
        dispatch(
          getWindRoseData([
            startingDate.valueOf(),
            endingDate.valueOf(),
            stationId,
            null,
            true,
          ])
        );

        dispatch(
          getMeteoData(
            {
              stationId: stationId,
              end: endingDate.valueOf(),
              field: "METEO",
              flStation: 1,
              orderBy: "+ID",
              searchDatetime: true,
              start: startingDate.valueOf(),
              tenant: "none",
            },
            stationId
          )
        );
      }

      if (stations[stationId].hasOwnProperty("PBL")) {
        // console.log(stationId, " has pbl")
      }
    }
  }
);

// export const updateDevice = createAsyncThunk(
//   "station/updateDevice",
//   async (device) => {
//     const data = await axios
//       .post(`/rest/data/update/Device${device.type}`, {})
//       .then((res) => {
//         console.log("Update response: ", res);
//         return res.data;
//       })
//       .catch((error) => {
//         console.log("Error: ", error);
//       });

//     return data;
//   }
// );

// export const getCuVocDevice = createAsyncThunk(
//   "configuration/getCuVocDevice",
//   async () => {
//     try {
//       // console.log("getCuVocDevice Thunk")
//       let returnData = {};
//       const data = await axios
//         .get(`/api/rest/data/read/DeviceVoc`)
//         .then((res) => {
//           // console.log(res.data.value);
//           if (res.data.value.length > 0) return res.data.value;
//           return [];
//         });
//       data.forEach((value) => {
//         const { id, serialNumber, tenant, thresholdMax, name } = value;
//         returnData[serialNumber] = {
//           id,
//           serialNumber,
//           tenant,
//           thresholdMax,
//           name,
//           hystory: null,
//         };
//       });
//       return returnData;
//     } catch (error) {}
//   }
// );

// export const getCuGasDevice = createAsyncThunk(
//   "configuration/getCuGasDevice",
//   async () => {
//     try {
//       // console.log("getCuGasDevice Thunk");
//       let returnData = {};
//       const data = await axios
//         .get(`/api/rest/data/read/DeviceGas`)
//         .then((res) => {
//           if (res.data.value[0]) {
//             return res.data.value;
//           }
//           return [];
//         });
//       data.forEach((value) => {
//         // returnData[value.id] = value;
//         const {
//           id,
//           name,
//           serialNumber,
//           tenant,
//           thresholdGasB,
//           thresholdGasE,
//           thresholdGasT,
//           thresholdGasX,
//         } = value;
//         returnData[serialNumber] = {
//           id,
//           name,
//           serialNumber,
//           tenant,
//           thresholdGasB,
//           thresholdGasE,
//           thresholdGasT,
//           thresholdGasX,
//           hystory: null,
//         };
//       });
//       return returnData;
//     } catch (error) {}
//   }
// );

// export const getCuMeteoDevice = createAsyncThunk(
//   "configuration/getCuMeteoDevice",
//   async () => {
//     try {
//       // console.log("getCuMeteoDevice Thunk");
//       let returnData = {};
//       const data = await axios
//         .get(`/api/rest/data/read/DeviceMeteo`)
//         .then((res) => {
//           if (res.data.value.length > 0) {
//             return res.data.value;
//           }
//           return [];
//         });
//       data.forEach((value) => {
//         // returnData[value.id] = value;
//         const {
//           id,
//           name,
//           serialNumber,
//           tenant,
//           range1_min,
//           range1_max,
//           range2_min,
//           range2_max,
//           range3_min,
//           range3_max,
//         } = value;
//         returnData[serialNumber] = {
//           id,
//           name,
//           serialNumber,
//           tenant,
//           range1_min,
//           range1_max,
//           range2_min,
//           range2_max,
//           range3_min,
//           range3_max,
//           hystory: null,
//         };
//       });
//       // console.log(returnData);
//       return returnData;
//     } catch (error) {}
//   }
// );

// export const getCuPblDevice = createAsyncThunk(
//   "configuration/getCuPblDevice",
//   async () => {
//     try {
//       // console.log("getCuPblDevice Thunk");
//       let returnData = {};
//       const data = await axios
//         .get(`/api/rest/data/read/DevicePbl`)
//         .then((res) => {
//           if (res.data.value.length > 0) {
//             return res.data.value;
//           }
//           return [];
//         });
//       data.forEach((value) => {
//         returnData[value.serialNumber] = value;
//       });
//       return returnData;
//     } catch (error) {}
//   }
// );

const getCUFeatures = createAsyncThunk(
  "stations/getCUFeatures",
  async (payload) => {
    const {stationId, start, end} = payload;
    const data = await axios.get(`/api/py/get_cu_features/`, {
      params:{
        start_date:Math.floor(start / 1000),
        end_date: Math.floor(end / 1000),
        station: stationId,
      }
    })
    .then((res) => {
      if (!res.data) return [];
      return res.data
    })
    .catch((error) => {
      console.log("Error: ", error);
      throw error;
    })
    
    return {stationId: stationId, features: data};
  }
);

export const setCuThreshold = createAsyncThunk(
  "configuration/setCuThreshold",
  async (payload, { dispatch, getState }) => {
    console.log('setCuThreshold', payload);
    try {
      let { cuSerial, cuType, threshold } = payload;
      if (!cuSerial || !cuType) return;
      let fieldName = 'threshold';
      switch (cuType) {
        case "BENZENE":
          cuType = STATION_DATA_TYPE.gas;
          fieldName = "threshold_gas_b";
          break;
        case "TOLUENE":
          cuType = STATION_DATA_TYPE.gas;
          fieldName = "threshold_gas_t";
          break;
        case "ETHYLBENZENE":
          cuType = STATION_DATA_TYPE.gas;
          fieldName = "threshold_gas_e";
          break;
        case "XYLENE":
          cuType = STATION_DATA_TYPE.gas;
          fieldName = "threshold_gas_x";
          break;    
        default:
          break;
      }
      const data = await axios
        .post('/api/py/setCuThreshold', {
          cuSerial: cuSerial,
          cuType: cuType,
          [fieldName]: threshold,
        })
        .then((res) => {
          console.log('res: ', res);
          if (res.status === 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          return [];
        })
        if (data) {
          return {cuSerial: cuSerial, cuType: cuType, threshold: parseFloat(threshold), fieldName:fieldName};
        } else {
          return null;
        }
    } catch (error) {
      console.log("Error: ", error);
    }
  }
);

const getPollutionRose = createAsyncThunk(
  "stations/getPollutionRose",
  async(payload) => {
    console.log('stations/getPollutionRose asyncthunk');
    const {startingDate, endingDate, stationId} = payload;
    let data = await axios.post("api/py/get_pollrose_data/", {
      start_date: Math.floor(startingDate/1000),
      end_date: Math.floor(endingDate/1000),
      stations: stationId,
    }).then((res) => {
      console.log('res: ', res);
      if (!res.status === 200 || !res.data) return null;
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error)
    });
    console.log('data', data);
    // data = Object.entries(data).map(([key, value]) => {
    //   return value;
    // });
    // console.log('data: ', data);
    return data;
  }
);
const getPolarPlots = createAsyncThunk(
  "stations/getPolarPlots",
  async(payload) => {
    // console.log('stations/getPolarPlots asyncthunk');
    const {startingDate, endingDate, stationsList} = payload;
    let data = await axios.post("api/py/get_polarplots", {
      start_date: Math.floor(startingDate/1000),
      end_date: Math.floor(endingDate/1000),
      stations: stationsList,
      engine: "python",
      stat: null
    }).then((res) => {
      if (!res.status === 200 || !res.data) return null;
      return res.data;
    }).catch((error) => {
      console.log("Error: ", error)
    });
    // data = Object.entries(data).map(([key, value]) => {
    //   return value;
    // });
    // console.log('data: ', data);
    return data;
  }
);

const readStation = createAsyncThunk (
  "stations/readStation",
  async (payload, { dispatch, getState }) => {
    const {stationId} = payload;
    const body = [
      {
        stationId: stationId
      },
    ];
    const data = await axios
      .post(`/api/py/readStation`, body)
      .then((res) => {
        console.log("res: ", res);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
);


const getStationInfo = createAsyncThunk(
  "stations/getStationInfo",
  async (payload, { dispatch, getState }) => {
    const {tenantId} = payload;
    const body = [
      {
        tenantId: tenantId
      },
    ];
    const data = await axios
      .post(`/api/py/getStationInfo`, body)
      .then((res) => {
        console.log("res: ", res);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return data;
  }
)

const stations = createSlice({
  name: "stations",
  initialState: {
    stations: null,
    polarPlots: [],
    loading: null,
    error: null,
    selectedStation: null,
    expandedStationsItems: []
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateCustomInterval: (state, action) => {
      const { stationId, updateField, customIntervalId, customInterval } =
        action.payload;

      state.stations[stationId][updateField] = {
        ...state.stations[stationId][updateField],
        [customIntervalId]: customInterval,
      };
    },
    setConfigStations: (state, action) => {
      if (!action.payload) return;
      state.stations = action.payload;
    },
    setConfigStationVocDevice: (state, action) => {
      if (!action.payload) return;
      let device = action.payload.payload[0];
      device.forEach((vocDev) => {
        state.stations[vocDev.repository.id].VOCDevice = vocDev;
      });
    },
    setStation: (state, action) => {
      const {stationId, station} = action.payload;
      state.stations[stationId] = station;
    },
    setSelectedStation: (state, action) => {
      state.selectedStation = action.payload;
    },
    getTotCU: (state, action) => {
      const stations = {...state.stations};

      const totCU = Object.keys(stations).reduce((tot, id) => {
        const tmp = Object.keys(stations[id]).reduce((s_tot, key) => {
          if(key === "vocDevice" || key === "gasDevice" || key === "meteoDevice" || key === "TILESTATIONDevice") return s_tot+1;
          return s_tot;
        }, 0);
        return tot+tmp;
      }, 0);
      state.totCU = totCU;
    },
    setExpandedStationsItems: (state, action) => {
      state.expandedStationsItems = action.payload;
    }, 
    setGpsSource: (state, action) => {
      // console.log('setGpsSource');
      if (!action.payload) return;
      const {stationId, gpsSource} = action.payload;
      state.stations[stationId].gpsSource = gpsSource;
    },

    setCuHistory: (state, action) => {
      if (!action.payload) return;
      const {cuHist} = action.payload;
      if (!cuHist) return;
      console.log('cuHist: ', cuHist);
      state.controlUnits.history = cuHist;
    },
    setStationHistory: (state, action) => {
      if (!action.payload) return;
      const {stationHist} = action.payload;
      if (!stationHist) return;
      console.log('stationHist: ', stationHist);

      Object.entries(stationHist).forEach(([key, value]) => {
        if (!state.stations[key]) return;
        state.stations[key].history = value;
      });
    },

    // getVocHistory: async (state, action) => {

    //   console.log('DEntro getVocHistory');

    //   const devices = await dispatch(getStationGasDevice(true));

    //   console.log(action.payload);

    //   let stationHystRow = {};
    //   let cuHystRow = {};
    //   let vocStationHistory = {}
    //   let vocCuHistory = {}
    //   console.log(action.payload);
    //   devices.forEach((row) => {
    //     let { cuSerial, id, starting, ending, repository } = row;
    //     // starting = new Date(starting).getTime();
    //     // ending !== null ? (ending = new Date(ending)).getTime() : (ending = null);
    //     repository = {
    //       id: repository.id,
    //       location: repository.location,
    //       flStation: repository.flStation,
    //       tenant: repository.tenant,
    //       flAvailable: repository.flAvailable
    //     };
    //     stationHystRow = {
    //       starting: starting,
    //       ending: ending,
    //       cuSerial: cuSerial,
    //     };
    //     // console.log("stationHystRow", stationHystRow);
    //     cuHystRow = {
    //       starting: starting,
    //       ending: ending,
    //       stationId: repository.id,
    //     };
    //     // console.log("cuHystRow", cuHystRow);

    //     vocStationHistory[repository.id] ? vocStationHistory[repository.id].push(stationHystRow) : vocStationHistory[repository.id] = [stationHystRow];
    //     vocCuHistory[cuSerial] ? vocCuHistory[cuSerial].push(stationHystRow) : vocCuHistory[cuSerial] = [stationHystRow];
    //   });
    //   console.log('vocStationHistory', vocStationHistory);
    //   console.log('vocCuHistory', vocCuHistory);
    //   console.log(current(state));
    //   const stations = state.stations;
    //   console.log('stations', stations);
    // Object.entries(vocStationHistory).forEach(([key, value]) => {
    //   console.log(key, value);
    //   const temp = {VOC: value};
    //   stations[key]["history"] = temp;
    // });
    // console.log('stations', stations);
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllStation.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(getAllStation.fulfilled, (state, action) => {
      // console.log("getAllStation")
      if (!action.payload) return;
      try {
        const [configStations, stations] = action.payload;
        if (configStations === false) {
          for (const key in stations) {
            stations[key].CUModes = [];
            stations[key].repositoryType === "V" ? (stations[key].gpsSource = "router") : (stations[key].gpsSource = null);
          }
          stations[94].repositoryType = "MB";
          state.stations = stations;
        }
      } catch (error) {
        console.log('Error: getAllStation fulfilled');
      }
    });
    builder.addCase(getStationVocDevice.fulfilled, (state, action) => {
      // console.log("builder getStationVocDevice");
      if (!action.payload) return;
      try {
        action.payload.forEach((vocDev) => {
          state.stations[vocDev.repository.id].vocDevice = vocDev;
          const cuArr = state.stations[vocDev.repository.id].CUModes;
          cuArr.push(STATION_DATA_TYPE.voc);
          state.stations[vocDev.repository.id].CUModes = orderCuModesArray(cuArr);
        });
      } catch (error) {
        console.log('Error: getStationVocDevice fulfilled');
      }
    });
    builder.addCase(getStationGasDevice.fulfilled, (state, action) => {
      // console.log("builder getStationGasDevice")
      if (!action.payload) return;
      try {
        action.payload.forEach((gasDev) => {
          state.stations[gasDev.repository.id].gasDevice = gasDev;
          const cuArr = state.stations[gasDev.repository.id].CUModes;
          cuArr.push(STATION_DATA_TYPE.gas);
          state.stations[gasDev.repository.id].CUModes = orderCuModesArray(cuArr);
        });
      } catch (error) {
        console.log('Error: getStationGasDevice fulfilled');
      }
    });
    builder.addCase(getStationMeteoDevice.fulfilled, (state, action) => {
      // console.log("builder getStationMeteoDevice")
      if (!action.payload) return;
      try {
        action.payload.forEach((meteoDev) => {
          state.stations[meteoDev.repository.id].meteoDevice = meteoDev;
          const cuArr = state.stations[meteoDev.repository.id].CUModes;
          cuArr.push(STATION_DATA_TYPE.meteo);
          state.stations[meteoDev.repository.id].CUModes =
            orderCuModesArray(cuArr);
        });
      } catch (error) {
        console.log('Error: getStationMeteoDevice fulfilled');
      }
    });
    builder.addCase(getStationTileDevice.fulfilled, (state, action) => {
      // console.log("builder getStationTileDevice")
      if (!action.payload) return;
      try {
        action.payload.forEach((tileDev) => {
          // console.log("getStationTileDevice fullfilled: ", tileDev);
          state.stations[tileDev.repository.id].TILESTATIONDevice  = tileDev;
          const cuArr = state.stations[tileDev.repository.id].CUModes;
          cuArr.push("TILESTATION");
          state.stations[tileDev.repository.id].CUModes = orderCuModesArray(cuArr);
        });
      } catch (error) {
        console.log('Error: getStationTileDevice fulfilled');  
      }
    });
    builder.addCase(getVocData.fulfilled, (state, action) => {
      // console.log("builder getVocData")
      if (!action.payload) return;
      try {
        const [stationId, data, intervalId, startingDate, endingDate] =
          action.payload;
        const station = state?.stations[stationId] || {}; // Otteniamo l'oggetto stazione corrente o un oggetto vuoto se non esiste
        const oldVOC = state?.stations[stationId]?.[STATION_DATA_TYPE.voc] || {};
  
        const intervalObj = {
          startingDate: startingDate,
          endingDate: endingDate,
          value: reduceValueForGraph(data, data.length),
        };
  
        const newVOC = {
          ...oldVOC,
          [intervalId !== null ? intervalId : null]: intervalObj,
        };
  
        /*console.log("Before first data")
        if (!station.VOC && intervalId !== undefined && intervalId !== null && intervalId != 0){
          const {startThreeHour, dataThreeHour} = getThreeHourArray(endingDate, data);
          threeIntervalObj = {
            startingDate: startThreeHour, 
            endingDate: endingDate, 
            value: dataThreeHour
          };
          newVOC[0] = threeIntervalObj;
        }*/
  
        state.stations = {
          ...state.stations,
          [stationId]: {
            ...station,
            [STATION_DATA_TYPE.voc]: newVOC,
          },
        };
        
      } catch (error) {
        console.log('Error: getVocData fulfilled');
      }
    });
    builder.addCase(getGasData.fulfilled, (state, action) => {
      // console.log('builder.addCase gas');
      if (!action.payload) return;
      try {
        const [stationId, gasName, data, intervalId, startingDate, endingDate] = action.payload;
        const oldGAS = state.stations[stationId][STATION_DATA_TYPE.gas] || {};
        const oldIntervalId = oldGAS[intervalId] || {};
  
        state.stations[stationId][STATION_DATA_TYPE.gas] = {
          ...oldGAS,
          [intervalId]: {
            ...oldIntervalId,
            startingDate: startingDate,
            endingDate: endingDate,
            [gasName]: data,
          },
        };
      } catch (error) {
        console.log('Error: getGasData fulfilled');
      }
    });
    builder.addCase(getWindRoseData.fulfilled, (state, action) => {
      // console.log('builder.addCase meteo');
      if (!action.payload) return;
      try {
        const [stationId, data, intervalId, startingDate, endingDate] =
          action.payload;
        const oldMeteo = state.stations[stationId][STATION_DATA_TYPE.meteo] || {};
        const oldIntervalId = oldMeteo[intervalId] || {};
  
        state.stations[stationId][STATION_DATA_TYPE.meteo] = {
          ...oldMeteo,
          [intervalId]: {
            ...oldIntervalId,
            startingDate: startingDate,
            endingDate: endingDate,
            [STATION_DATA_TYPE.wr]: data,
          },
        };
      } catch (error) {
        console.log('Error: getWindRoseData fulfilled');  
      }
    });
    builder.addCase(getMeteoData.fulfilled, (state, action) => {
      if (!action.payload) return;
      try {
        const [stationId, intervalId, data, median] = action.payload;
        const oldMeteo = state.stations[stationId][STATION_DATA_TYPE.meteo] || {};
        const oldIntervalId = oldMeteo[intervalId] || {};
        let windSpeed = [];
        let temperature = [];
        let pressure = [];
        let humidity = [];
        let windSpeedAcc = [];
        let tempAcc = [];
        let humAcc = [];
        let presAcc = [];
        let avgWindSpeed = null;
        let minWindSpeed = null;
        let maxWindSpeed = null;
        let avgTemp = null;
        let minTemp = null;
        let maxTemp = null;
        let avgHumn = null;
        let avgPres = null;
  
        data.forEach((metRaw) => {
          let [
            date,
            stationName,
            lat,
            lon,
            windMs,
            windKn,
            windNorth,
            windMag,
            temp,
            humAbs,
            humRel,
            pres,
            rainMm,
            solar,
            shiphead,
          ] = metRaw;
  
          try {
            pres = parseFloat(pres.slice(0, pres.length - 2)) * 1000;
          } catch (error) {
            pres = null;
          }
          try {
            date = new Date(date).getTime();
          } catch (error) {
            date = null;
          }
          if (date !== null) {
            if (temp) {
              temperature.push([date, temp]);
              tempAcc.push(temp);
            }
            if (humRel) {
              humidity.push([date, humRel]);
              humAcc.push(humRel);
            }
            if (pressure) {
              pressure.push([date, pres]);
              presAcc.push(pres);
            }
            if (windKn) {
              windSpeed.push([date, windKn]);
              windSpeedAcc.push(windKn);
            }
          }
        });
  
        // console.log("calculating average temp hum pres")
        avgWindSpeed = calculateAverage(windSpeedAcc);
        minWindSpeed = calculateMin(windSpeedAcc);
        maxWindSpeed = calculateMax(windSpeedAcc);
        avgTemp = calculateAverage(tempAcc);
        minTemp = calculateMin(tempAcc);
        maxTemp = calculateMax(tempAcc);
        avgHumn = calculateAverage(tempAcc);
        avgPres = calculateAverage(presAcc);
  
        state.stations[stationId][STATION_DATA_TYPE.meteo] = {
          ...oldMeteo,
          [intervalId]: {
            ...oldIntervalId,
            [`${STATION_DATA_TYPE.ws}_values`]: {
              mean: avgWindSpeed,
              min: minWindSpeed,
              max: maxWindSpeed,
              median: median? Math.round(median) : null,
            },
            [`${STATION_DATA_TYPE.temp}_values`]: { mean: avgTemp, min: minTemp, max: maxTemp },
            [`${STATION_DATA_TYPE.rh}_mean`]: avgHumn,
            [`${STATION_DATA_TYPE.pres}_mean`]: avgPres,
            [STATION_DATA_TYPE.ws]: windSpeed,
            [STATION_DATA_TYPE.temp]: temperature,
            [STATION_DATA_TYPE.rh]: humidity,
            [STATION_DATA_TYPE.pres]: pressure,
          },
        };
      } catch (error) {
       console.log('Error: getMeteoData fullfilled');
      }
    });
    builder.addCase(getTilestationData.fulfilled, (state, action) => {
      // console.log('builder getTilestationData');
      if (!action.payload) return;
      try {
        const {data, startingDate, endingDate, intervalId, stationId} = action.payload;
        const station = state?.stations[stationId] || {}; // Otteniamo l'oggetto stazione corrente o un oggetto vuoto se non esiste
        const oldTILESTATION = station.TILESTATION || {};
        const bvocData = [], co2Data = [], pm25Data = [];
        const stationData = {co2: co2Data, bvoc: bvocData, pm25: pm25Data};
        if (!data) {
          state.stations[stationId] = {
            ...station,
            TILESTATION: {
              ...oldTILESTATION,
              [intervalId]: {
                startingDate: startingDate,
                endingDate: endingDate,
                ...stationData,
              }
            }    
          }
        } else {
     
          data.forEach((element) => {
            const date = new Date(element.date).valueOf();
            bvocData.push([date, element.bvoc]);
            co2Data.push([date, element.co2]);
            pm25Data.push([date, element.pm25]);
          }); 
          // const stationData = {co2: co2Data, bvoc: bvocData, pm25: pm25Data};
    
          state.stations[stationId] = {
            ...station,
            TILESTATION: {
              ...oldTILESTATION,
              [intervalId]: {
                startingDate: startingDate,
                endingDate: endingDate,
                ...stationData
              }
            }    
          }
        }
      } catch (error) {
        console.log('Error: getTilestationData fullfilled');
      }
    });
    builder.addCase(addFixedStation.fulfilled, (state, action) => {
      if (!action.payload) return;
      try {
        state.stations[action.payload.id] = action.payload;
      } catch (error) {
        console.log('Error: addFixedStation fullfilled');
      }
    });
    builder.addCase(addMobileStation.fulfilled, (state, action) => {
      if (!action.payload) return;
      try {
        state.stations[action.payload.id] = action.payload;
      } catch (error) {
        console.log('Error: addMobileStation fullfilled');
      }
    });
    // builder.addCase(getCuVocDevice.fulfilled, (state, action) => {
    //   if (!action.payload) return;
    //   // console.log("getCuVocDevice .fulfilled");
    //   state.controlUnits = {
    //     ...state.controlUnits,
    //     [STATION_DATA_TYPE.voc]: action.payload,
    //   };
    // });
    // builder.addCase(getCuGasDevice.fulfilled, (state, action) => {
    //   if (!action.payload) return;
    //   // console.log("getCuGasDevice .fulfilled");
    //   state.controlUnits = {
    //     ...state?.controlUnits,
    //     [STATION_DATA_TYPE.gas]: action.payload,
    //   };
    // });
    // builder.addCase(getCuMeteoDevice.fulfilled, (state, action) => {
    //   if (!action.payload) return;
    //   // console.log("getCuMeteoDevice .fulfilled");
    //   state.controlUnits = {
    //     ...state?.controlUnits,
    //     [STATION_DATA_TYPE.meteo]: action.payload,
    //   };
    // });
    // builder.addCase(getCuPblDevice.fulfilled, (state, action) => {
    //   if (!action.payload) return;
    //   // console.log("getCuPblDevice .fulfilled");
    //   state.controlUnits = {
    //     ...state?.controlUnits,
    //     PBL: action.payload,
    //   };
    // });
    builder.addCase(getCUFeatures.fulfilled, (state, action) => {
      if (!action.payload) return;
      try {
        const {stationId, features} = action.payload;
        console.log("features: ", features)
        Object.keys(features).forEach((key) => {
          state.stations[stationId][`${key.toLowerCase()}Device`].features = features[key];
        });
      } catch (error) {
        console.log('Error: getCUFeatures fullfilled');
      }
    });
    builder.addCase(getVocHistory.fulfilled, (state, action) => {
      if (!action.payload) return;
      try {
        state.stations = action.payload;
      } catch (error) {
        console.log('Error: getVocHistory fullfilled');
      }
    });
    builder.addCase(getMeteoHistory.fulfilled, (state, action) => {
      if (!action.payload) return;
      try {
        state.stations = action.payload;
      } catch (error) {
        console.log('Error: getMeteoHistory fullfilled');
      }
    });
    builder.addCase(setCuThreshold.fulfilled, (state, action) => {
      if (!action.payload) return;
      try {
        const {cuSerial, cuType, threshold, fieldName} = action.payload;
        console.log('action.payload', action.payload);
        const cu = state.controlUnits[cuType][cuSerial];
        switch (cuType) {
          case STATION_DATA_TYPE.voc:
            cu.thresholdMax = threshold;
            break;
          case STATION_DATA_TYPE.gas:
            if (fieldName === "threshold_gas_b") cu.thresholdGasB = threshold;
            else if (fieldName === "threshold_gas_t") cu.thresholdGasT = threshold;
            else if (fieldName === "threshold_gas_e") cu.thresholdGasE = threshold;
            else cu.thresholdGasX = threshold;
            break;
          default:
            break;
        }
      } catch (error) {
        console.log('Error: setCuThreshold fullfilled');
      }
    });
  },
});

export const {
  setLoading,
  updateCustomInterval,
  setConfigStations,
  setConfigStationVocDevice,
  getTotCU, 
  setGpsSource,
  setCuHistory, 
  setStationHistory,
} = stations.actions;

export const stationSliceActions = {
  getAllStation,
  readStation,
  getCUFeatures,
  getPolarPlots,
  getPollutionRose,
  getLastStationPositions,
  getStationInfo,
  ...stations.actions
};

export default stations.reducer;