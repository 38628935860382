import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { viewNames } from "app/configs/routesNames";
import { deleteSensor } from "app/store/evocsSlice/sensors/sensorsActions";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import { useDrag } from "react-dnd";
import { FaPlugCircleCheck } from "react-icons/fa6";
import { FaPlugCircleXmark } from "react-icons/fa6";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import LoadingGif from "../FileManager/LoadingGif";
import useMobile from "src/app/hooks/useMobile";

const viewName = viewNames.CONFIGURATION_VIEW;

const SensorListItemMenu = ({ style, enabledActions, sensor, onClickItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  const handleClickItem = (itemAction, actionCallback = null) => {
    handleClose();
    onClickItem && onClickItem(itemAction, {...sensor, type: "sensor"}, actionCallback);
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };


  return (
    <div style={{ ...style, width: "fit-content" }} onClick={stopPropagation}>
      <IconButton sx={style} onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClickItem("config-sensor")}> {handleTranslate("CONFIGURE")} </MenuItem>
        {enabledActions.includes("manage-device") && <MenuItem sx={{ color: "red" }} onClick={() => handleClickItem("delete", () => deleteSensor(sensor.id))}> {handleTranslate("DELETE", true)} </MenuItem>}
      </Menu>
    </div>
  )
}

const SensorListItem = ({ enabledActions, disableDrag, sensor, parent, selected = false, onClick, onDrag, onClickItemMenu }) => {
  const className = !selected ? "station-item-list close" : "station-item-list selected";
  const canDrag = useMemo(() => {
    return !disableDrag && enabledActions?.includes("manage-device")
  }, [enabledActions, disableDrag])
  const [{ isDragging }, drag] = useDrag({
    type: "sensor",
    item: { unit: {...sensor, type: "sensor"}, parent },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: canDrag
  }, [sensor, canDrag]);

  useEffect(() => {
    if (!onDrag) return;
    if (isDragging) onDrag(sensor);
    else onDrag(null);
  }, [isDragging]);

  const handleClick = () => {
    if(disableDrag) 
      onClickItemMenu && onClickItemMenu("config-sensor", {...sensor, type: "sensor"});
    else onClick && onClick(sensor.id)
  }

  const isMenuEnabled = () => {
    if (!enabledActions.includes("manage-device") && !enabledActions.includes("update-info-device")) return false;
    return true;
  }

  return sensor && (
    <div ref={drag} className={className} style={{ display: "flex", padding: "1rem 0rem", alignItems: "center", borderTop: "1px solid grey", cursor: canDrag ? "grab" : "default" }} onClick={handleClick}>
      {canDrag && <DragIndicatorIcon className="drag-icon" fontSize="small" />}
      <span style={{ fontWeight: "bold" }}>{sensor.serial}</span>
      {sensor.deviceParent ?
        <FaPlugCircleCheck style={{ marginLeft: "auto", minWidth: "20px", minHeight: "20px", color: "green" }} />
        : <FaPlugCircleXmark style={{ marginLeft: "auto", minWidth: "20px", minHeight: "20px", color: "red" }} />
      }
      {isMenuEnabled() && <SensorListItemMenu enabledActions={enabledActions} sensor={sensor} onClickItem={onClickItemMenu} />}
    </div>
  );
}

const SensorsList = memo(({ enabledActions, list, selectedItems, onClickItem, onDragSensor, onClickItemMenu }) => {
  const [xs, sm] = useMobile();
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);

  const disableDrag = useMemo(() => xs || sm);

  const handleClick = (sensorId) => {
    if (!selectedItems.includes(sensorId)) onClickItem([...selectedItems, sensorId], sensorId);
    else onClickItem(selectedItems.filter((e) => e !== sensorId), sensorId);
  }

  const getSensorElement = (sensorId) => {
    return { id: sensorId, ...sensors[sensorId] }
  }

  const getParent = (sensorId) => {
    const parent = sensors[sensorId]?.deviceParent;
    if (parent) {
      const parentElement = parent.type === "station" ? stations[parent.device] : controlUnits[parent.device];
      return { id: parent.device, type: parent.type, ...parentElement }
    } else return null;
  }

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      {list?.map((sensorId) => (
        <SensorListItem key={sensorId} enabledActions={enabledActions} disableDrag={disableDrag} sensor={getSensorElement(sensorId)} parent={getParent(sensorId)} selected={selectedItems.includes(sensorId)} onClick={handleClick}
          onDrag={onDragSensor} onClickItemMenu={onClickItemMenu} />
      ))}
    </div>
  )
});


const SensorsManagement = ({loading, selectedTenant, selectedSensors, enabledActions, onChangeTenant, onSensorItemClick, onDragSensor, onClickItemMenu }) => {
  const { t } = useTranslation("evocs");
  const sensors = useSelector((state) => state.sensors.list);

  const [sensorsOptions, setSensorsOptions] = useState([]);
  const [filteredSensors, setFilteredSensors] = useState();
  const [visibleSensors, setVisibleSensors] = useState();

  useLayoutEffect(() => {
    setFilteredSensors([]);
  }, [selectedTenant])

  useLayoutEffect(() => {
    setVisibleSensors(() => {
      if (!filteredSensors || filteredSensors.length === 0) return Object.keys(sensors);
      return filteredSensors;
    });
  }, [sensors, filteredSensors])

  useEffect(() => {
    const allSensorsId = Object.keys(sensors);
    setSensorsOptions(allSensorsId.map((id) => ({ id, serial: sensors[id].serial })));
    setFilteredSensors((prev) => prev.filter((sensorId) => allSensorsId.includes(sensorId)));
  }, [sensors])

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.CONFIGURATION_VIEW);
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem", height: "100%", overflowX: "visible" }}>
      {!loading ?
      <>
        <div style={{ display: "flex", gap: "1rem"}}>
          <SensorAutocomplete isUserTenant={false} multiple limitTags={1} sensorsId={sensorsOptions} value={filteredSensors} onChange={setFilteredSensors} />
          <TenantSelect value={selectedTenant} onChange={onChangeTenant} />
          {enabledActions.includes("manage-device") && <Button sx={{ ml: "auto" }} onClick={() => onClickItemMenu("create-sensor", null)}>+ {handleTranslate("NEW_SENSOR")}</Button>}
        </div>
        <SensorsList enabledActions={enabledActions} list={visibleSensors} selectedItems={selectedSensors} onClickItem={onSensorItemClick}
          onDragSensor={onDragSensor} onClickItemMenu={onClickItemMenu} />
      </>
      :
      <LoadingGif />
      }
    </div>
  )
}

export default SensorsManagement;
