import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const IT = {
    HEADER : "Dashboard",
    PIE_LEGEND_1: 'tutti sensori della stazione sono attivi e funzionanti',
    PIE_LEGEND_2: 'i sensori della stazione sono parzialmente funzionanti',
    PIE_LEGEND_3: 'i sensori della stazione non sono funzionanti',
    PIE_LEGEND_4: 'la stazione non ha sensori installati',
    PIE_LEGEND_5: 'Un sensore è considerato non funzionante se non è in grado di spedire dati alla piattaforma',
};

const DASHBOARD_IT = createLanguageObject(IT, viewNames.DASHBOARD_VIEW);

export default DASHBOARD_IT;