import React, { useCallback } from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import ActionSelect from "../SharingComponents/Inputs/ActionSelect";
import { createTenantRole } from "app/store/evocsSlice/tenants/tenantsActions";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { getUsers } from "app/store/evocsSlice/user/userAction";
import UserSelect from "../SharingComponents/Inputs/UserSelect";
import GroupActionSelect from "../SharingComponents/Inputs/GroupActionSelect";
import { useSelector } from "react-redux";
import AlertCategorySelect from "../SharingComponents/Inputs/AlertCategorySelect";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const defaultInputs = {
  tenant: {
    value: "",
    required: true
  },
  name: {
    value: "",
    required: true
  },
  users: {
    value: [],
    required: false
  },
  description: {
    value: "",
    required: false
  },
  // categoryAlerts: {
  //   value: [],
  //   required: false
  // },
  // groupActions: {
  //   value: [],
  //   required: false
  // },
};

const ConfigurationChip = ({ onConfirm }) => {
  // const groupActions = useSelector((state) => state.configuration.constants?.actionGroup);
  // const actions = useSelector((state) => state.configuration.constants?.action);
  const [inputs, setInputs] = useState(defaultInputs);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const [tenantUsers, setTenantUsers] = useState([]);
  const { t } = useTranslation("evocs");
  
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.ADMINISTRATION_VIEW);
  };


  useEffect(() => {
    handleChangeInput("users", []);
    inputs.tenant.value !== "" && getUsers([inputs.tenant.value]).then((res) => {
      setTenantUsers(res)
    }).catch(() => setTenantUsers([]));
  }, [inputs.tenant.value]);

  // useEffect(() => {
  //   let newInputsObject = {...inputs};
  //   const removedGroupsId = Object.keys(groupActions).filter((groupId) => !inputs.groupActions.value.includes(groupId));
  //   removedGroupsId.forEach((groupId) => {
  //     delete newInputsObject[groupId];
  //   });
  //   inputs.groupActions.value.forEach((groupId) => {
  //     if(newInputsObject[groupId]) return;
  //     newInputsObject[groupId] = {
  //       value: getActionsByGroupId(groupId),
  //       required: false
  //     }
  //   });

  //   setInputs(newInputsObject);
  // }, [inputs.groupActions.value]);


  useEffect(() => {
    const disabled = Object.keys(inputs).some((key) => (inputs[key].required && (inputs[key].value === "" || inputs[key].value?.length === 0)));
    isInputsChanged && setButtonDisabled(disabled);
  }, [inputs])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: { ...inputs[key], value: value } });
    setInputsChanged(true);
  };

  const handleConfirm = () => {
    createTenantRole(inputs.tenant.value, inputs.name.value, inputs.users.value, inputs.description.value);
  };

  // const getActionsByGroupId = useCallback((groupId) => {
  //   return Object.keys(actions).filter((actionId) => actions[actionId].groupId === groupId);
  // }, [actions]);


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_ROLE")} </h2>
      <TenantSelect required value={inputs.tenant.value} onChange={(value) => handleChangeInput("tenant", value)} />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={handleTranslate("NAME", true)}
        required
        className="rounded-2xl"
        value={inputs.name.value}
        onChange={(e) => handleChangeInput("name", e.target.value)}
      />
      <UserSelect multiple users={tenantUsers} value={inputs.users.value} onChange={(value) => handleChangeInput("users", value)} />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={handleTranslate("DESCRIPTION", true)}
        className="rounded-2xl"
        value={inputs.description.value}
        onChange={(e) => handleChangeInput("description", e.target.value)}
      />
      {/* <AlertCategorySelect multiple value={inputs.categoryAlerts.value} onChange={(value) => handleChangeInput("categoryAlerts", value)}/>
      <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
        <label>Azioni attive</label>
        <GroupActionSelect multiple value={inputs.groupActions.value} onChange={(value) => handleChangeInput("groupActions", value)}/>
      </div>
      {inputs.groupActions.value.length > 0 &&
      <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
        <h3 style={{fontWeight: "bold"}}>Personalizza azioni</h3>
        {inputs.groupActions.value.map((groupId) => (
        <div key={groupId} style={{display: "flex", gap: "1rem", alignItems: "center"}}>
          <label>{groupActions[groupId].actionGroup}</label>
          <ActionSelect multiple actionsId={getActionsByGroupId(groupId)} value={inputs[groupId]?.value} onChange={(value) => handleChangeInput(groupId, value)}/>
        </div>
        ))}
      </div>
      } */}
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}


const containerStyle = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#2d3740",
  padding: "1rem",
  borderRadius: "1rem",
  height: "80vh",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column"
};


const CreateRoleModal = ({ isOpen = false, onClose, onConfirmCallback }) => {

  return (
    <Modal open={isOpen}>
      <div style={containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <ConfigurationChip />
        </div>
      </div>
    </Modal>
  )
};

export default CreateRoleModal;
