import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";

const EN = {
    HEADER : "My user profile",
};

const USER_PROFILE_EN = createLanguageObject(EN, viewNames.USER_PROFILE_VIEW);



export default USER_PROFILE_EN;