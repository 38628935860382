import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import Error404Page from "../main/404/Error404Page";
import MonthlyReports from "src/views/MonthlyReports";
import StationHandling from "src/views/StationHandling";
import SingleStationHandling from "src/views/SingleStationHandling";
import OdorReports from "src/views/OdorReports";
import SignInPage from "../../views/SignInPage";
import Configuration from "src/views/Configuration";
import {routesNames} from "./routesNames";
import Dashboard from "src/views/Dashboard";
import DailyReports from "src/views/DailyReports";
import TestPage from "src/views/TestPage";
import Administration from "src/views/Administration";
import OpenDataAir from "src/views/OpenDataAir";
import OpenDataMeteo from "src/views/OpenDataMeteo";
import UserProfile from "src/views/UserProfile";
import UserInvitationConfig from "src/components/UserInvitation/UserInvitationConfig";
import SignInConfig from "src/components/Sign-in/SignInConfig";
import SignUpConfig from "src/components/SignUp/SignUpConfig";
import RecoverPasswordConfig from "src/components/RecoverPassword/RecoverPasswordConfig";
import Alerts from "src/views/Alerts";
import Multimedia from "src/views/Multimedia";
import SingleMultimedia from "src/views/SingleMultimedia";
import VesselsTraffic from "src/views/VesselsTraffic";

const routeConfigs = [ UserInvitationConfig, SignUpConfig, SignInConfig, RecoverPasswordConfig ];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: routesNames.USER_PROFILE_VIEW,
    element: <UserProfile />
  },
  {
    path: routesNames.DASHBOARD_VIEW,
    element: <Dashboard />
  },
  {
    path: routesNames.STATIONS_VIEW,
    element: <StationHandling />,
  },
  {
    path: routesNames.SINGLE_STATION_VIEW_WITH_PARAM,
    element: <SingleStationHandling />,
  },
  {
    path: routesNames.MULTIMEDIA_VIEW,
    element: <Multimedia />,
  },
  {
    path: routesNames.SINGLE_MULTIMEDIA_VIEW,
    element: <SingleMultimedia />,
  },
  {
    path: routesNames.ODOR_REPORTS_VIEW,
    element: <OdorReports />,
  },
  {
    path: routesNames.ERROR_VIEW,
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to={routesNames.SIGN_IN_VIEW} />,
  },
  {
    path: "/",
    element: <Navigate to={routesNames.SIGN_IN_VIEW} />,
  },
  {
    path: routesNames.MONTHLY_REPORTS_VIEW,
    element: <MonthlyReports sharedType="monthly" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: routesNames.DAILY_REPORTS_VIEW,
    element: <DailyReports sharedType="daily" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: routesNames.OPEN_DATA_AIR_QUALITY_VIEW,
    element: <OpenDataAir />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: routesNames.OPEN_DATA_METEOROLOGY_VIEW,
    element: <OpenDataMeteo />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: routesNames.CONFIGURATION_VIEW,
    element: <Configuration />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: routesNames.ADMINISTRATION_VIEW,
    element: <Administration />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: routesNames.LOADING_VIEW,
    element: <FuseLoading />,
  },
  {
    path: routesNames.SIGN_IN_VIEW,
    element: <SignInPage />,
  },
  {
    path: routesNames.VESSELS_VIEW,
    element: <VesselsTraffic />,
  },
  {
    path: routesNames.TEST_PAGE_VIEW,
    element: <TestPage />,
  },
  {
    path: routesNames.ALERTS_VIEW,
    element: <Alerts />,
  },
];

export default routes;
