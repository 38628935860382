import { Button, Modal } from '@mui/material'
import React, { useLayoutEffect, useState } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";

const containerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "1rem",
  borderRadius: "1rem",
  height: "fit-content",
  width: "95%",
  maxWidth: "400px",
  display: "flex",
  flexDirection: "column",
  gap: "3rem"
};

const WarningModal = ({ style, defaultTitle = "Warning", title, isOpen, onClose, onConfirm }) => {
  const [titleText, setTitleText] = useState(defaultTitle);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  useLayoutEffect(() => {
    title && setTitleText(title);
  }, [title])

  return (
    <Modal open={isOpen}>
      <div style={{ ...style, ...containerStyle }}>
        <WarningAmberIcon sx={{ width: "80px", height: "80px", color: "darkorange", margin: "0 auto" }} fontSize='large' />
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>Sei sicuro?</h2>
        <p style={{ textAlign: "center" }}>{titleText}</p>
        <div style={{ marginLeft: "auto" }}>
          <Button onClick={onClose}> {handleTranslate('CANCEL', true)} </Button>
          <Button onClick={onConfirm}> {handleTranslate('CONFIRM', true)} </Button>
        </div>
      </div>
    </Modal>
  )
}

export default WarningModal;