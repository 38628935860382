import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Select, MenuItem, Checkbox, InputLabel, FormControl } from "@mui/material";
import { useSelector } from "react-redux";
import { calcIntervalDates } from "./utils";
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import { viewNames } from 'app/configs/routesNames';


const TimeIntervalSelector = ({ intervals, defaultValue, value, onChange, required = false, disabled = false, multiple = false, label }) => {
  const timeRange = useSelector((state) => state.configuration.constants?.timeRange);
  const options = useMemo(() => {
    if (intervals) return intervals;
    else return Object.keys(timeRange);
  }, [intervals]);
  const [selectedOption, setSelected] = useState(defaultValue ? defaultValue : multiple ? [] : "");

  useLayoutEffect(() => {
    value !== undefined && setSelected(value);
  }, [value])


  const handleChange = (selected) => {
    const dates = calcIntervalDates(selected);
    setSelected(selected);
    onChange && onChange(selected, dates);
  };


  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };

  const getTimeLabelFromTimeRangeId = (timeRangeId) => {
    if (!timeRangeId) return;
    let timeLabel = timeRange[timeRangeId].timeRange;
    if (!timeLabel) return;
    switch (timeLabel) {
      case "1h":
        if (timeRangeId === "660bc8f8e4db0b4972d2fb69") timeLabel = handleTranslate("TIMERANGE_LIVE", true);
        else timeLabel = handleTranslate("TIMERANGE_1H", true);
        break;
      case "*":
        timeLabel = handleTranslate("TIMERANGE_CUSTOM", true);
        break;
      default:
        timeLabel = handleTranslate(`TIMERANGE_${timeLabel.toUpperCase()}`, true);
        break;
    }
    return timeLabel;
  };

  if (!label) 
    label = handleTranslate("INTERVAL", true);

  return (
    <FormControl sx={{ width: "100%", minWidth: "100px", maxWidth: "300px" }}>
      <InputLabel id="interval-select-label">{required ? `${label}*` : label}</InputLabel>
      <Select
        labelId="interval-select-label"
        label={required ? `${label}*` : label}
        // label={required ? `${'porco'}*`: 'porco'}
        // label = { required ? `${handleTranslate('INTERVAL')}*`: label }
        disabled={disabled}
        value={selectedOption}
        multiple={multiple}
        renderValue={(selected) => multiple ? 
          selected.map((timeRangeId) => {
            return getTimeLabelFromTimeRangeId(timeRangeId);
            // let timeLabel = timeRange[timeRangeId].timeRange;
            // switch (timeLabel) {
            //   case "1h":
            //     if (timeRangeId === "660bc8f8e4db0b4972d2fb69") timeLabel = handleTranslate("TIMERANGE_LIVE", true);
            //     else timeLabel = handleTranslate("TIMERANGE_1H", true);
            //     break;
            //   case "*":
            //     timeLabel = handleTranslate("TIMERANGE_CUSTOM", true);
            //     break;
            //   default:
            //     timeLabel = handleTranslate(`TIMERANGE_${timeLabel.toUpperCase()}`, true);
            //     break;
            // }
            // return timeLabel;
          })
          .join(", ") : getTimeLabelFromTimeRangeId(selected)}
        onChange={(e) => handleChange(e.target.value)}
      >
        {options.map((intervalId, idx) => (
          <MenuItem key={idx} value={intervalId}>
            {multiple && <Checkbox checked={selectedOption?.indexOf(intervalId) > -1} />}
            {getTimeLabelFromTimeRangeId(intervalId)}
            {/* {timeRange[intervalId]?.label} */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};

export default TimeIntervalSelector;



// const TimeIntervalSelector = ({intervals, defaultValue, value, onChange, required=false, disabled=false, multiple=false, label="Intervallo"}) => {
//   const timeRange = useSelector((state) => state.configuration.constants?.timeRange);
//   const options = useMemo(() => {
//     if(intervals) return intervals;
//     else return Object.keys(timeRange);
//   }, [intervals]);
//   const [selectedOption, setSelected] = useState(defaultValue ? defaultValue : multiple ? [] : ""); 

//   useLayoutEffect(() => {
//     value !== undefined && setSelected(value);
//   }, [value])


//   const handleChange = (selected) => {
//     const dates = calcIntervalDates(selected);
//     setSelected(selected);
//     onChange && onChange(selected, dates);
//   };


//   const { t } = useTranslation("evocs");
//   const handleTranslate = (textId, general = false) => {
//     if (general) return translate(t, textId);
//     return translate(t, textId, viewNames.DASHBOARD_VIEW);
//   };

//   return (
//     <FormControl sx={{width: "100%", minWidth: "100px", maxWidth: "300px"}}>
//       <InputLabel id="interval-select-label">{required ? `${label}*`: label}</InputLabel>
//       <Select
//         labelId="interval-select-label"
//         label={required ? `${label}*`: label}
//         disabled={disabled}
//         value={selectedOption}
//         multiple={multiple}
//         renderValue={(selected) => multiple ? selected.map((timeRangeId) => timeRange[timeRangeId].label).join(", ") : timeRange[selected]?.label}
//         onChange={(e) => handleChange(e.target.value)}
//       >
//         {options.map((intervalId, idx) => (
//           <MenuItem key={idx} value={intervalId}>
//             {multiple && <Checkbox checked={selectedOption?.indexOf(intervalId) > -1} /> }
//             {timeRange[intervalId]?.label}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   )
// };

// export default TimeIntervalSelector;

