import { convertHexToRGBA } from "src/components/StationHandling/utils";
import store from "../..";
import { datasetSliceActions } from "./datasetSlice";
import { getApiTimestamp } from "src/utilities/utils";


export const readData = async(start, end, sensorIds=null, cuIds=null, stationIds=null) => {
    if (!start || !end) throw new Error("start and end are required");
    start = getApiTimestamp(start);
    end = getApiTimestamp(end);
    const data = await store.dispatch(datasetSliceActions.readData({
        start: start, 
        end: end, 
        sensorIds: sensorIds, 
        cuIds : cuIds, 
        stationIds: stationIds})).then(({payload, error}) => {
        if(error) return [];
        return payload;
    });
    return data;
};

export const calcGraphPoints = async(start, end, sensorIds=null, pointNumber=40) => {
    if (!start || !end) throw new Error("start & end are required");
    setLoading(true);
    const now = new Date().valueOf();
    const apiStart = start;
    const apiEnd = end;
    setLastRequest(now);
    const data = await store.dispatch(datasetSliceActions.calcGraphPoints({sensorIds, start: apiStart, end: apiEnd, pointNumber, requestStart: now})).then(({payload, error}) => {
        if(error) return [];
        // setLoading(false);
        return payload;
    });
    return data;
};

export const readGraphPoints = async(start, end, sensorIds=null, pointNumber=40) => {
    if (!start || !end) throw new Error("start & end are required");
    setLoading(true);
    const now = new Date().valueOf();
    const apiStart = getApiTimestamp(start);
    const apiEnd = getApiTimestamp(end);
    setLastRequest(now);
    const data = await store.dispatch(datasetSliceActions.readGraphPoints({sensorIds, start: apiStart, end: apiEnd, pointNumber, requestStart: now})).then(({payload, error}) => {
        if(error) return [];
        setLoading(false);
        return payload;
    });
    return data;
};

export const newGraphPoints = async(start, end, sensorIds=null, pointNumber=40) => {
    if (!start || !end) throw new Error("start & end are required");
    setLoading(true);
    const now = new Date().valueOf();
    const apiStart = getApiTimestamp(start);
    const apiEnd = getApiTimestamp(end);
    setLastRequest(now);
    const data = await store.dispatch(datasetSliceActions.newGraphPoints({sensorIds, start: apiStart, end: apiEnd, pointNumber, requestStart: now})).then(({payload, error}) => {
        if(error) return [];
        setLoading(false);
        return payload;
    });
    return data;
};

export const getWindRoseData = async(start, end, sensorIds=null) => {
    if (!start || !end) throw new Error("start and end are required");
    setWindRoseLoading(true);
    start = getApiTimestamp(start);
    end = getApiTimestamp(end);
    const data = await store.dispatch(datasetSliceActions.getWindRoseData({sensorIds, start, end})).then(({payload, error}) => {
        if(error) return [];
        return payload;
    });
    setWindRoseLoading(false);
    return data;
};

export const getMaxMinData = async (start, end, stationIds=null, sensorIds=null, ) => {
    if (!start || !end) throw new Error("start and end are required");
    start = getApiTimestamp(start);
    end = getApiTimestamp(end);
    const data = await store.dispatch(datasetSliceActions.getMinMaxAvg({stationIds, sensorIds, start, end})).then(({payload, error}) => {
        if(error) return [];
        return payload;
    });
    return data;
};

export const createDatasetLineGraph = (label, data, backgroundColor="", borderColor="", fill=true, yAxisID="y", tension=0.1, pointRadius=0) => {
    return {
        label,
        data,
        yAxisID,
        tension,
        pointRadius,
        fill,
        backgroundColor: convertHexToRGBA(backgroundColor, 0.3),
        borderColor: convertHexToRGBA(borderColor, 1),
    }
};

export const createTooltipLineGraph = (title="", preLabel="", postLabel="", preValue="", postValue="") => {
    const getFooterValue = (context) => context?.[0].raw.y?.toFixed(4).toString() || context?.[0].raw.toFixed(4).toString();

    return {
        title: () => title,
        label: (context) => preLabel + context?.label + postLabel,
        footer: (context) => preValue + getFooterValue(context) + " " + postValue
    }
};


const setLoading = (loading) => {
    store.dispatch(datasetSliceActions.setLoading(loading));
};

const setWindRoseLoading = (loading) => {
    store.dispatch(datasetSliceActions.setWindRoseLoading(loading));
};

const setLastRequest = (date) => {
    store.dispatch(datasetSliceActions.setLastRequest(date));
};