import { useCallback, useEffect, useState } from 'react';
import TableComponent from '../SharingComponents/TableComponent';

const columns = {
  flag : "Flag",
  name: "Nome",
  dest: "Destinazione",
  lat:"Latitudine",
  lon: "Longitudine",
  time: "Data",
  // navstat: "Stato",
  // heading: "heading",
  // sog: "sog",
  // ship: "ID",
  // type: "Tipo",
  mmsi: "MMSI",
  imo: "IMO"
};


const VesselsTable = ({vessels=[], onClickRow}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    vessels && setData(vessels);
  }, [vessels])


  const getFilerData = useCallback((searchText) => {
    return vessels.filter((element) => Object.keys(element).some((key) => element[key] && element[key].toString().toLowerCase().includes(searchText.toLowerCase())));
  }, [vessels]);

  const handleSearch = useCallback((value) => {
    if(!value || value === "") setData(vessels);
    else setData(getFilerData(value));
  }, [getFilerData]);

  const handleClickRow = (rowIndex) => {
    onClickRow && onClickRow(data[rowIndex], rowIndex)
  }


  return (
    <TableComponent clickable columns={columns} data={data} rowsForPage={5} onSearch={handleSearch} onClickRow={handleClickRow}/>
  )
}

export default VesselsTable