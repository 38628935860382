import { getApiTimestamp } from "src/utilities/utils";
import store from "../..";
import { sensorsSliceActions } from "../sensors/sensorSlice";
import { stationsMongoSliceActions } from "../stationsMongo/stationsMongoSlice";
import { cuSliceActions } from "./cuSlice";
import { setStationState } from "../stationsMongo/stationsMongoActions";
import { getSensorsMeasures, setSensorState } from "../sensors/sensorsActions";

export const setCusLoading = (loading=true) => {
    store.dispatch(cuSliceActions.setLoading(loading));
}

export const getCusInfo = async(tenantIds) => {
    const data = store.dispatch(cuSliceActions.getCuInfo(tenantIds)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const getCuHistory = async(cuId) => {
    const deviceDict = {[cuId]: "cu"}
    const data = await store.dispatch(cuSliceActions.getHistory(deviceDict)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};

export const downloadCuHistory = async(cuId, form="csv") => {
    const data = await store.dispatch(cuSliceActions.downloadHistory({cuId, form})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });

    return data;
};


export const createCu = async(serial, owner, stationId=null, listSensors=null, available=false) => {
    const availableNumber = available ? 1 : 0;
    const data = await store.dispatch(cuSliceActions.createCu({serial, owner, stationId, listSensors, available: availableNumber})).then(({payload, error}) => {
        if(error) return null;
        return payload;
    });
    if(stationId) store.dispatch(stationsMongoSliceActions.installCu({stationId, cuIds: [data.cuId]}))
    if(listSensors?.length > 0) {
        const startDate = data.cu.deviceParent?.start || getApiTimestamp(new Date().valueOf());
        listSensors.forEach((sensorId) => {
            store.dispatch(sensorsSliceActions.installOnDevice({sensorId, device: data.cuId, type:  "cu", start: startDate}))
        })
    }
    return data;
};
export const readCu = async(cuId) => {
    const data = await store.dispatch(cuSliceActions.createCu({cuId: cuId}));
};
// export const updateCu = async(cuId, serial, owner, available=undefined, stationId=undefined, listSensors=undefined, uninstall=undefined) => {
//     const controlUnits = store.getState().controlUnits.list;
//     const parent = controlUnits[cuId].deviceParent;
//     const startDate = getApiTimestamp(new Date());
//     const availableNumber = available ? 1 : 0;

//     const data = await store.dispatch(cuSliceActions.updateCu({cuId, serial, owner, available: availableNumber, stationId, listSensors, uninstall, operationTime: startDate})).then(({payload, error}) => {
//         if(error) return null;

//         return payload;
//     });

//     if(available !== undefined) {
//         const devices = data[cuId];
//         devices.forEach((element) => {
//             const deviceId = element._id;
//             const typeDevice = element.typeDevice;
//             delete element._id; delete element.typeDevice;
//             switch(typeDevice) {
//                 case "station":
//                     activateStation(deviceId, available);
//                     break;
//                 case "cu":
//                     activateCu(deviceId, available);
//                     break;
//                 case "sensor":
//                     activateSensor(deviceId, available);
//                     break;
//             }
//         });
//     }
//     if(uninstall) {
//         store.dispatch(stationsMongoSliceActions.uninstallCu({stationId: parent.device, cuId}));
//         store.dispatch(cuSliceActions.addUninstalledCu(cuId));
//     }
//     else if(stationId) {
//         if(parent) {
//             store.dispatch(stationsMongoSliceActions.uninstallCu({stationId: parent.device, cuId}));
//             store.dispatch(cuSliceActions.addUninstalledCu(cuId));
//         } 
//         store.dispatch(stationsMongoSliceActions.installCu({stationId, cuIds: [cuId]}));
//         store.dispatch(cuSliceActions.addInstalledCu(cuId));
//     }
    
//     if(listSensors) {
//         const uninstalled = controlUnits[cuId].list_sensors.filter((sensorId) => !listSensors.includes(sensorId));
//         store.dispatch(cuSliceActions.setListSensor({cuId, listSensor: listSensors}));
//         listSensors.forEach((sensorId) => {
//             store.dispatch(sensorsSliceActions.installOnDevice({sensorId, device: cuId, type: "cu", start: startDate}))
//         })
//         uninstalled.forEach((sensorId) => {
//             store.dispatch(sensorsSliceActions.uninstallFromParent(sensorId))
//         })
//     }
//     return data;
// };

export const updateCu = async(cuId, serial, owner, available=undefined, stationId=undefined, listSensors=undefined, uninstall=undefined) => {
    const startDate = getApiTimestamp(new Date());
    const availableNumber = available === undefined ? undefined : (available ? 1 : 0);

    const data = await store.dispatch(cuSliceActions.updateCu({cuId, serial, owner, available: availableNumber, stationId, listSensors, uninstall, operationTime: startDate})).then(({payload, error}) => {
        if(error) return null;

        return payload;
    });

    data[cuId]?.forEach((element) => {
        const deviceId = element._id;
        const typeDevice = element.typeDevice;
        delete element._id; delete element.typeDevice;
        switch(typeDevice) {
            case "station":
                setStationState(deviceId, element);
                break;
            case "cu":
                setCuState(deviceId, element);
                break;
            case "sensor":
                setSensorState(deviceId, element);
                break;
        }
    });

    return data;
};

export const setCuState = (cuId, state) => {
    store.dispatch(cuSliceActions.setCuState({id: cuId, cu: state}));
}

export const activateCu = (cuId, active=true) => {
    if(active) store.dispatch(cuSliceActions.activateCu(cuId));
    else store.dispatch(cuSliceActions.deactivateCu(cuId));
}

export const deleteCu = async(cuId) => {
    await store.dispatch(cuSliceActions.deleteCu(cuId));
};

export const installCu = async(cuIds, stationId) => {
    const startDate = getApiTimestamp(new Date());
    const data = await store.dispatch(cuSliceActions.installCu({cuIds, stationId, start: startDate})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    store.dispatch(stationsMongoSliceActions.installCu({stationId, cuIds}));

    return data;
};

export const uninstallCu = async(cuId, stationId, end) => {
    const data = await store.dispatch(cuSliceActions.uninstallCu({cuId, end: getApiTimestamp(end)})).then(({payload, error}) => { 
        if(error) throw error;
        return payload; 
    });
    store.dispatch(stationsMongoSliceActions.uninstallCu({stationId, cuId}));
    return data;
};

export const moveCu = async(cuId, sourceId, destinationId, start) => {
    const data = await store.dispatch(cuSliceActions.moveCu({cuId, destinationId, start: getApiTimestamp(start)})).then(({payload, error}) => { 
        if(error) throw error; 
        return payload;
    });
    store.dispatch(stationsMongoSliceActions.uninstallCu({stationId: sourceId, cuId}));
    store.dispatch(stationsMongoSliceActions.installCu({stationId: destinationId, cuIds: [cuId]}));
    return data;
};

export const shareSingleCu = async(cuId, tenatList) => {
    if (!cuId || !tenatList) throw new Error("Missing parameters");
    const dictShare = {[cuId]: tenatList}

    const data = await store.dispatch(cuSliceActions.createShareCu(dictShare)).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    const stations=[], cus=[], sensors=[];
    data?.forEach((element) => {
        const newElement = {...element};
        const elementId = newElement._id;
        delete newElement._id;
        delete newElement.typeDevice;
        switch(element.typeDevice) {
            case "station":
                stations.push({id: elementId, station: newElement});
                break;
            case "cu":
                cus.push({id: elementId, cu: newElement});
                break;
            case "sensor":
                sensors.push({id: elementId, sensor: newElement});
                break;
        }
    });
    if(stations.length > 0) stations.forEach((element) => store.dispatch(stationsMongoSliceActions.setStationState(element)));
    if(cus.length > 0) cus.forEach((element) => store.dispatch(cuSliceActions.setCuState(element)));
    if(sensors.length > 0) sensors.forEach((element) => store.dispatch(sensorsSliceActions.setSensorState(element)));
    return data;
};

export const updateShareCu = async(cuId, tenantsList) => {
    const data = await store.dispatch(cuSliceActions.updateShareCu({cuId, tenantsList})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    const stations=[], cus=[], sensors=[];
    data?.forEach((element) => {
        const newElement = {...element};
        const elementId = newElement._id;
        delete newElement._id;
        delete newElement.typeDevice;
        switch(element.typeDevice) {
            case "station":
                stations.push({id: elementId, station: newElement});
                break;
            case "cu":
                cus.push({id: elementId, cu: newElement});
                break;
            case "sensor":
                sensors.push({id: elementId, sensor: newElement});
                break;
        }
    });

    if(stations.length > 0) stations.forEach((element) => store.dispatch(stationsMongoSliceActions.setStationState(element)));
    if(cus.length > 0) cus.forEach((element) => store.dispatch(cuSliceActions.setCuState(element)));
    if(sensors.length > 0) sensors.forEach((element) => store.dispatch(sensorsSliceActions.setSensorState(element)));
    return data;
};


export const getCuMeasures = (cuId, onlyGraphMeasures=false) => {
    if(!cuId) throw new Error("cuId is required");
    const controlUnits = store.getState().controlUnits.list;
    let cuMeasures;
    if(Array.isArray(cuId)) {
        cuMeasures = [...new Set(cuId.map((id) => controlUnits[id] && getSensorsMeasures(controlUnits[id].list_sensors, onlyGraphMeasures)).flat())];
    }else if(controlUnits[cuId]) {
        cuMeasures = [...new Set(getSensorsMeasures(controlUnits[cuId].list_sensors, onlyGraphMeasures))];
    }
    return cuMeasures;
}

