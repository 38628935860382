import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
} from "@mui/material";
import { TIME_FORMAT, UNIT_TYPE, formatDate } from "../StationHandling/utils";
import { downloadStationHistory, getStationHistory, updateStationHistory } from "app/store/evocsSlice/stationsMongo/stationsMongoActions";
import LoadingGif from "../FileManager/LoadingGif";
import CuSelect from "../SharingComponents/Inputs/CuSelect";
import SensorAutocomplete from "../SharingComponents/Inputs/SensorAutocomplete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import DateTimeInput from "../SharingComponents/Inputs/DateTimeInput";
import DownloadComponent from "../SharingComponents/DownloadComponent/DownloadComponent";
import * as XLSX from 'xlsx';
import { useSelector } from "react-redux";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


// const EditableRow = ({controlUnits, sensors, rowElement, onChange, onDelete, onError}) => {
//   const minDate = useMemo(() => new Date(rowElement.start*1000), [rowElement.start]);
//   const deviceParent = useMemo(() => {
//     if(!rowElement.newElement) return null;
//     if(rowElement.type === "cu") return controlUnits[rowElement.device]?.deviceParent;
//     else return sensors[rowElement.device]?.deviceParent;
//   }, [rowElement.device, controlUnits, sensors]);
//   const cuOptions = useMemo(() => {
//     if(!controlUnits) return [];
//     return Object.keys(controlUnits).map((cuId) => ({id: cuId, serial: controlUnits[cuId].serial}))
//   }, [controlUnits]);

//   const sensorOptions = useMemo(() => {
//     if(!sensors) return [];
//     return Object.keys(sensors).map((sensorId) => ({id: sensorId, serial: sensors[sensorId].serial}))
//   }, [sensors]);

//   useEffect(() => {
//     if(deviceParent) {
//       if(!rowElement.end) onError("null endDate");
//       else onError(null);
//     }else onError(null);
//   }, [deviceParent, rowElement.end])


//   const handleChangeInput = (key, value) => {
//     if(key === "type" && value !== rowElement.type) {
//       onChange({...rowElement, [key]: value, device: null});
//       return;
//     }
//     if(value && (key === "start" || key === "end")) value = Math.floor(new Date(value).valueOf() / 1000);
//     onChange({...rowElement, [key]: value});
//   }

//   const getFormattedDate = useCallback((dateValue) => {
//     return dateValue ? formatDate(new Date(dateValue*1000), TIME_FORMAT.ISO_DATE_TIME) : ""
//   }, []); 

//   return(
//     <TableRow>
//       <TableCell>
//         {rowElement.type === "cu" ?
//           <CuSelect isUserTenant={false} disabled={rowElement.editLimitaion} error={rowElement.device ? false : true} cusId={cuOptions} value={rowElement.device} onChange={(value) => handleChangeInput("device", value)}/>
//           :
//           <SensorAutocomplete isUserTenant={false}  disabled={rowElement.editLimitaion} error={rowElement.device ? false : true} sensorsId={sensorOptions} value={rowElement.device} onChange={(value) => handleChangeInput("device", value)}/>
//         }
//       </TableCell>
//       <TableCell>
//         <select id="type-select" disabled={rowElement.editLimitaion} value={rowElement.type} onChange={(e) => handleChangeInput("type", e.target.value)}>
//           <option value="cu">Centralina</option>
//           <option value="sensor">Sensore</option>
//         </select>
//       </TableCell>
//       <TableCell>
//         <DateTimeInput error={rowElement.start ? false : true} value={getFormattedDate(rowElement.start)} onChange={(value) => handleChangeInput("start", value)} onError={onError}/>
//       </TableCell>
//       <TableCell>
//         <DateTimeInput error={deviceParent && !rowElement.end ? true : false} minDate={minDate} value={getFormattedDate(rowElement.end)} onChange={(value) => handleChangeInput("end", value)} onError={onError}/>
//       </TableCell>
//       <TableCell>
//         <IconButton sx={{color: "red"}} disabled={rowElement.editLimitaion} onClick={onDelete}>
//           <DeleteIcon />
//         </IconButton>
//       </TableCell>
//     </TableRow>
//   )
// }

const EditableRow = ({ rowElement, deviceParent, errors, onChange, onDelete, onError }) => {
  const { t } = useTranslation("evocs");
  const minDate = useMemo(() => {
    return new Date(rowElement.start * 1000).setSeconds(0, 0);
  }, [rowElement.start]);


  const [dateError, setDateError] = useState();

  useEffect(() => {
    console.log("rowElement: ", rowElement)
    return () => onError(null)
  }, [])

  useEffect(() => {
    const newErrors = [];
    if (!rowElement.device) newErrors.push("null device");
    if (!rowElement.start) newErrors.push("null startDate");
    if (deviceParent && !rowElement.end) newErrors.push("null endDate");
    if (dateError) newErrors.push(dateError);
    onError(newErrors.length > 0 ? newErrors : null);
  }, [rowElement, dateError])


  const handleChangeInput = (key, value) => {
    if (key === "type" && value !== rowElement.type) {
      onChange({ ...rowElement, [key]: value, device: null });
      return;
    }
    if (value && (key === "start" || key === "end")) value = Math.floor(new Date(value).valueOf() / 1000);
    onChange({ ...rowElement, [key]: value });
  };

  const getFormattedDate = useCallback((dateValue) => {
    // const date = dateValue ? formatDate(new Date(dateValue * 1000), TIME_FORMAT.ISO_DATE_TIME) : "";
    return dateValue ? new Date(dateValue * 1000) : null;
  }, []);

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  return (
    <TableRow>
      <TableCell>
        {rowElement.type === "cu" ?
          <CuSelect disabled={rowElement.editLimitaion} error={errors?.includes("null device")} value={rowElement.device} onChange={(value) => handleChangeInput("device", value)} />
          :
          <SensorAutocomplete disabled={rowElement.editLimitaion} error={errors?.includes("null device")} value={rowElement.device} onChange={(value) => handleChangeInput("device", value)} />
        }
      </TableCell>
      <TableCell>
        <select id="type-select" disabled={rowElement.editLimitaion} value={rowElement.type} onChange={(e) => handleChangeInput("type", e.target.value)}>
          <option value="cu"> {handleTranslate("CONTROL_UNIT", true)} </option>
          <option value="sensor"> {handleTranslate("SENSOR", true)} </option>
        </select>
      </TableCell>
      <TableCell>
        <DateTimeInput error={errors?.includes("null startDate")} value={getFormattedDate(rowElement.start)} onChange={(value) => handleChangeInput("start", value)} onError={setDateError} />
      </TableCell>
      <TableCell>
        <DateTimeInput error={errors?.includes("null endDate")} minDate={minDate} value={getFormattedDate(rowElement.end)} onChange={(value) => handleChangeInput("end", value)} onError={setDateError} />
      </TableCell>
      <TableCell>
        <IconButton sx={{ color: "red" }} disabled={rowElement.editLimitaion} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const HistoryTable = ({ history, disableEdit, rowsErrors, onChangeRow, onAddRow, onDeleteRow, onRowError }) => {
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const deviceSerial = (id, type) => {
    if (type === UNIT_TYPE.controlUnits) return controlUnits[id]?.serial;
    else return sensors[id]?.serial;
  };

  const deviceType = (type) => {
    if (type === UNIT_TYPE.controlUnits) return handleTranslate("CONTROL_UNIT", true);
    else return handleTranslate("SENSOR", true);
  };

  const getFormattedDate = useCallback((dateValue) => {
    return dateValue ? formatDate(new Date(dateValue * 1000), TIME_FORMAT.COMPLETE_DATE) : "Attualmente installata";
  }, []);

  const getDeviceParent = useCallback((rowElement) => {
    if (!rowElement.newElement) return null;
    if (rowElement.type === "cu") return controlUnits[rowElement.device]?.deviceParent;
    else return sensors[rowElement.device]?.deviceParent;
  }, [controlUnits, sensors]);


  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ fontWeight: "bold" }}>
            <TableCell> {handleTranslate("SERIAL", true)} </TableCell>
            <TableCell> {handleTranslate("TYPE", true)} </TableCell>
            <TableCell> {handleTranslate("START_DATE", true)} </TableCell>
            <TableCell> {handleTranslate("END_DATE", true)} </TableCell>
          </TableRow>
          {!disableEdit &&
            <TableRow>
              <TableCell colSpan={5} align="center"> <Button onClick={onAddRow}>+ {handleTranslate("ADD", true)}</Button></TableCell>
            </TableRow>
          }
        </TableHead>
        <TableBody>
          {history?.map((element, index) => disableEdit ? (
            <TableRow key={element.id}>
              <TableCell>{deviceSerial(element.device, UNIT_TYPE[element.type])}</TableCell>
              <TableCell>{deviceType(UNIT_TYPE[element.type])}</TableCell>
              <TableCell>{getFormattedDate(element.start)}</TableCell>
              <TableCell>{getFormattedDate(element.end)}</TableCell>
            </TableRow>
          ) :
            <EditableRow key={element.id} rowElement={element} deviceParent={getDeviceParent(element)} errors={rowsErrors[element.id]} onChange={(newRow) => onChangeRow(newRow, index)} onDelete={() => onDeleteRow(element, index)} onError={(errors) => onRowError(element, index, errors)} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const StationHistoryChip = ({ stationId }) => {
  const { t } = useTranslation("evocs");
  const stations = useSelector((state) => state.stationsMongo.list);
  const controlUnits = useSelector((state) => state.controlUnits.list);
  const sensors = useSelector((state) => state.sensors.list);
  const [stationHistory, setStationHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableEdit, setDisableEdit] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [isChanged, setChanged] = useState(false);

  const [deletedList, setDeletedList] = useState([]);
  const [createdList, setCreatedList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorText, setErrorText] = useState("");

  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  const hasEditLimitation = useCallback((historyElement) => {
    if (historyElement.end) return false;
    if (historyElement.type === "cu") return controlUnits[historyElement.device].deviceParent ? true : false;
    else return sensors[historyElement.device].deviceParent ? true : false;
  }, [controlUnits, sensors])


  useEffect(() => {
    if (!stationId) return;
    setLoading(true);
    getStationHistory(stationId).then((res) => {
      setStationHistory(res.map((element, index) => ({ ...element, id: index, editLimitaion: hasEditLimitation(element), newElement: false })));
      setLoading(false);
    });
  }, [stationId]);

  useEffect(() => {
    setHistory([...stationHistory]);
  }, [stationHistory])


  const getErrorText = useCallback((text) => {
    switch (text) {
      case "null device":
        return handleTranslate("HIST_ERR_1");
      case "null startDate":
        return handleTranslate("HIST_ERR_2");
      case "null endDate":
        return handleTranslate("HIST_ERR_3");
      case "minDate":
        return handleTranslate("HIST_ERR_4");
      default:
        return "";
    }
  }, []);

  useEffect(() => {
    if (isChanged) setDisableConfirm(Object.keys(errors).length > 0 ? true : false);
    else setDisableConfirm(true)
  }, [isChanged, errors])

  useEffect(() => {
    const keys = Object.keys(errors);
    const text = getErrorText(errors[keys[0]]?.[0])
    setErrorText(text)
  }, [errors])


  const createListElement = (oldElement) => {
    let newElement = { ...oldElement };
    delete newElement.id; delete newElement.editLimitaion; delete newElement.newElement;
    return newElement;
  };

  const handleChangeRow = (newRow, index) => {
    setHistory((prev) => prev.toSpliced(index, 1, newRow));
    console.log(newRow.id)
    console.log(updatedList)
    if (!newRow.newElement && !updatedList.includes(newRow.id)) setUpdatedList((prev) => [...prev, newRow.id]);
    setChanged(true);
  };

  const handleAddRow = () => {
    const newId = createdList.length > 0 ? createdList[createdList.length-1] + 1 : history[history.length-1].id + 1;
    const newHistoryElement = {
      id: newId,
      device: null,
      type: "cu",
      start: null,
      end: null,
      editLimitaion: false,
      newElement: true
    }
    setHistory((prev) => [newHistoryElement, ...prev]);
    setCreatedList((prev) => [...prev, newHistoryElement.id]);
    setChanged(true);
  };

  const handleDeleteRow = (element, index) => {
    setHistory((prev) => prev.toSpliced(index, 1));
    if (!element.newElement) {
      setDeletedList((prev) => [...prev, element.id]);
      setUpdatedList((prev) => prev.filter((id) => id !== element.id));
    }
    else setCreatedList((prev) => prev.filter((id) => id !== element.id));
    setChanged(true);
  };

  const handleCancelChanges = () => {
    setHistory([...stationHistory]);
    setChanged(false);
    setDisableEdit(true);
  };


  const handleSave = () => {
    const old = updatedList.map((id) => createListElement(stationHistory.find((oldElement) => oldElement.id === id)));
    const created = createdList.map((id) => createListElement(history.find((element) => element.id === id)));
    const deleted = deletedList.map((id) => createListElement(stationHistory.find((element) => element.id === id)));
    const updated = updatedList.map((id) => createListElement(history.find((element) => element.id === id)));
    console.log("history: ", history);
    console.log("createdList: ", created);
    console.log("deletedList: ", deleted);
    console.log("updatedList: ", updated);
    console.log("oldList: ", old);
    updateStationHistory(stationId, old, updated, deleted, created).then((res) => {
      console.log("RES: ", res)
      // setStationHistory(history);
    })
    setChanged(false);
    setDisableEdit(true);
  };

  const handleRowError = (row, index, rowErrors) => {
    if (rowErrors) setErrors((prev) => ({ ...prev, [row.id]: rowErrors }));
    else {
      setErrors((prev) => {
        let newErrors = { ...prev };
        delete newErrors[row.id];
        return newErrors;
      });
    }
  };

  const handleDownloadFile = (fileType) => {
    downloadStationHistory(stationId, fileType).then((res) => {
      if (fileType === "csv") {
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          const url = URL.createObjectURL(blob);
          link.style.display = 'none';
          link.href = url;
          // // the filename you want
          link.download = `History ${stations[stationId].serial}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      } else {
        // Crea un nuovo foglio di lavoro
        const worksheet = XLSX.utils.json_to_sheet(res.data);
        // Crea un nuovo libro di lavoro
        const workbook = XLSX.utils.book_new();

        // Aggiungi il foglio di lavoro al libro di lavoro
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Foglio1');

        // Crea un file XLSX e salva
        XLSX.writeFile(workbook, `History ${stations[stationId].serial}.xlsx`);
      }
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem", paddingBottom: "7rem" }}>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <h2 style={{ fontWeight: "bold" }}> {handleTranslate("STATION", true)}: {stations[stationId]?.serial}</h2>
        <DownloadComponent fromTable onDownloadFile={handleDownloadFile} />
        {disableEdit && <IconButton onClick={() => setDisableEdit(false)}><EditIcon /></IconButton>}
      </div>
      {loading ? <LoadingGif />
        : <HistoryTable history={history} disableEdit={disableEdit} rowsErrors={errors} onChangeRow={handleChangeRow} onAddRow={handleAddRow} onDeleteRow={handleDeleteRow} onRowError={handleRowError} />
      }
      {!disableEdit &&
        <div style={{ position: "absolute", padding: "1rem 2rem", left: 0, bottom: 0, width: "100%", display: "flex", alignItems: "center", gap: ".5rem", background: "white", zIndex: 10 }}>
          <Button sx={{ color: "red" }} onClick={handleCancelChanges}> {handleTranslate("CANCEL", true)} </Button>
          <Button disabled={disableConfirm} onClick={handleSave}> {handleTranslate("SAVE", true)} </Button>
          {errorText !== "" && <label style={{ color: "red" }}> {handleTranslate("ERROR", true)}: {errorText}</label>}
        </div>
      }
    </div>
  );
}

export default StationHistoryChip