import { getApiTimestamp } from "src/utilities/utils";
import store from "../..";
import { multimediaSliceActions } from "./multimediaSlice";

export const setLoadingRecordingList = (loading=true) => {
    store.dispatch(multimediaSliceActions.setLoadingRecordingList(loading));
}

export const setLoadingImageList = (loading=true) => {
    store.dispatch(multimediaSliceActions.setLoadingImageList(loading));
}

export const setLoadingRecord = (recordingId) => {
    store.dispatch(multimediaSliceActions.setLoadingRecord(recordingId));
}

export const setLoadingRecordExport = (recordingId) => {
    store.dispatch(multimediaSliceActions.setLoadingRecordExport(recordingId));
}

export const setLoadingRecordDelete = (recordingId) => {
    store.dispatch(multimediaSliceActions.setLoadingRecordDelete(recordingId));
}

export const getMongoFile = async(fileId, fileIds, typeFile, resize=0) => {
    if(!fileId) throw new Error("fileId is required");
    const data = await store.dispatch(multimediaSliceActions.getMongoFile({fileId, fileIds, typeFile, resize})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
}

export const getMongoFileInfo = async(userId, tenantId, sensorIds, startDate, endDate, typeFile) => {
    const data = await store.dispatch(multimediaSliceActions.getMongoFileInfo({userId, tenantId, sensorIds, startDate: getApiTimestamp(startDate), endDate: getApiTimestamp(endDate), typeFile})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
}

export const getAxisCameraLiveStream =  async (camera='1', possibileCameras=['quad', '1', '2', '3', '4']) => {
    if (!camera || !possibileCameras.includes(camera)) throw new Error("Wrong camera name");
    const stream = await store.dispatch(multimediaSliceActions.getAxisCameraLiveStream('quad'));
    return stream;
};

export const getAxisRecordingVideo = async(diskid, recordingid) => {
    if (!diskid || !recordingid) throw new Error("diskid and recordingid are required");
    // const data = await store.dispatch(multimediaSliceActions.getAxisRecordingVideo({diskid, recordingid})).then(({payload, error}) => {
    //     if(error) throw error;

    //     return payload;
    // })
    // console.log('seconda res');
    // return data;
};

export const getAxisRecordingInfo = async(recordingid=null) => {
    const data = await store.dispatch(multimediaSliceActions.getAxisRecordingInfo({recordingid})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
}

export const getAxisCameraAuthHeader = async (camera='1', possibileCameras=['quad', '1', '2', '3', '4']) => {
    if (!camera || !possibileCameras.includes(camera)) throw new Error("Wrong camera name");
    const header = await store.dispatch(multimediaSliceActions.getAxisCameraAuthHeader(camera)).then(({payload, error}) => {
        if(error) return [];
        return payload;
    });
    return header;
};

export const getAxisCameraImage = (camera='1', resolution='640x360', possibileCameras=['quad', '1', '2', '3', '4']) => {
    if (!camera || !possibileCameras.includes(camera)) throw new Error("Wrong camera name");
    const data = store.dispatch(multimediaSliceActions.getAxisCameraImage({camera, resolution})).then(({payload, error}) => {
        if(error) return [];
        return payload;
    });
    return data;
};

export const startAxisRecording = (camera=undefined, discid=undefined, resolution=undefined, videocodec="h264") => {
    const data = store.dispatch(multimediaSliceActions.startRecording({camera, discid, resolution, videocodec})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const stopAxisRecording = (profile) => {
    const data = store.dispatch(multimediaSliceActions.stopRecording({profile})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};

export const deleteAxisRecording = (recordingid) => {
    const data = store.dispatch(multimediaSliceActions.deleteRecording({recordingid})).then(({payload, error}) => {
        if(error) throw error;
        return payload;
    });
    return data;
};