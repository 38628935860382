import { Paper } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckIcon from '@mui/icons-material/Check';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const SingleThreshold = ({ enableUpdate = false, withUpdateIcons = true, placeholder = "", value, defaultValue = undefined, locked, defaultLocked = true, onChange, onConfirmEdit }) => {
  const [inputClassName, setInputClassName] = useState("input-container");
  const [val, setValue] = useState(defaultValue);
  const [lock, setLock] = useState(defaultLocked);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };

  useLayoutEffect(() => {
    setValue(value);
  }, [value])

  useLayoutEffect(() => {
    locked !== undefined && setLock(locked);
  }, [locked])

  useLayoutEffect(() => {
    withUpdateIcons && !enableUpdate && setLock(true);
  }, [enableUpdate])

  const handleInputFocus = () => {
    setInputClassName("input-container focus");
  };

  const handleInputBlur = () => {
    setInputClassName("input-container");
  };

  const handleChangeValue = (newValue) => {
    let numberValue = Number(newValue);
    if(!newValue || newValue === "") {
      newValue = null;
      numberValue = null;
    }
    setValue(newValue);
    onChange && onChange(numberValue);
  };

  const handleConfirm = () => {
    let numberValue = Number(val);
    if(!val) numberValue = null;
    setLock(true);
    onConfirmEdit && onConfirmEdit(numberValue);
  };

  return (
    <div className="threshold">
      <label style={{ width: "50px" }}>{placeholder}</label>
      {enableUpdate ?
        <>
          <div className={inputClassName}>
            <input type="number" disabled={lock} value={val || ""} onChange={(e) => handleChangeValue(e.target.value)} onFocus={handleInputFocus} onBlur={handleInputBlur} />
            {withUpdateIcons && (lock ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />)}
          </div>
          {withUpdateIcons && lock ? <button onClick={() => setLock(false)}><EditIcon fontSize="small" /></button>
            :
            <div style={{ whiteSpace: "nowrap" }}>
              {withUpdateIcons && <button onClick={handleConfirm}><CheckIcon fontSize="small" /></button>}
              <button onClick={() => handleChangeValue(null)}><RestartAltIcon fontSize="small" /></button>
            </div>
          }
        </>
        :
        <span>{val || handleTranslate("NO_THRESHOLDS", true)}</span>
      }
    </div>
  );
};


const ThresholdCard = ({ enableUpdate, withUpdateIcons = true, defaultLocked = true, sensorId, measureId, min, max, onChange, onConfirmEdit }) => {
  const measures = useSelector((state) => state.configuration.constants.measure);

  const handleChange = (key, value) => {
    onChange && onChange(measureId, key, value);
    return;
  };

  const handleConfirmEdit = (key, value) => {
    onConfirmEdit && onConfirmEdit(sensorId, measureId, key, value);
    return;
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };


  return (
    <Paper sx={{ padding: "1rem" }}>
      <h3 style={{ marginBottom: "1rem" }}>{handleTranslate(`MEASURE_${measures[measureId]?.["measure"].toUpperCase()}`, true)}</h3>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <SingleThreshold enableUpdate={enableUpdate} withUpdateIcons={withUpdateIcons} defaultLocked={defaultLocked} placeholder="Min" value={min}
          onChange={(value) => handleChange("min", value)} onConfirmEdit={(value) => handleConfirmEdit("min", value)} />
        <SingleThreshold enableUpdate={enableUpdate} withUpdateIcons={withUpdateIcons} defaultLocked={defaultLocked} placeholder="Max" value={max}
          onChange={(value) => handleChange("max", value)} onConfirmEdit={(value) => handleConfirmEdit("max", value)} />
      </div>
    </Paper>
  )
}

export default ThresholdCard