import { viewNames } from "app/configs/routesNames";
import { createLanguageObject } from "src/utilities/utils";


const EN = {
    HEADER : "Configuration",
    STATION_CREATION_SUCCESS: "Station created successfully.",
    CONTROL_UNIT_CREATION_SUCCESS: "Control unit created successfully.",
    SENSOR_CREATION_SUCCESS: "Sensor created successfully.",
    STATION_UPDATE_SUCCESS: "Station updated successfully.",
    CONTROL_UNIT_UPDATE_SUCCESS: "Control unit updated successfully.",
    SENSOR_UPDATE_SUCCESS: "Sensor updated successfully.",
    EXIT_WARNING : "If you exit without saving your changes will be lost.",
    NO_INSTALLED_DEVICE: "No installed device",
    INSTALLATION_DATE: "Installation date",
    CONFIGURE: "Configure",
    INSTALL : "Install",
    UNINSTALL : "Uninstall",
    REMOVE: "Remove",
    POPUP_WARNING_DELETE_STATION: "Are you sure you want to delete the station",
    POPUP_ERROR_DELETE_STATION_1: "Unable to delete the station",
    POPUP_ERROR_DELETE_STATION_2: "There are units installed on it",
    POPUP_WARNING_DELETE_CONTROL_UNIT: "Are you sure you want to delete the control unit",
    POPUP_ERROR_DELETE_CONTROL_UNIT_1: "Unable to delete the control unit",
    POPUP_ERROR_DELETE_CONTROL_UNIT_2: "The control unit is installed on a station or there are sensors installed on it",
    POPUP_WARNING_UNINSTALL_CONTROL_UNIT: "Are you sure you want to uninstall the control unit",
    POPUP_WARNING_DELETE_SENSOR: "Are you sure you want to delete the sensor",
    POPUP_ERROR_DELETE_SENSOR_1: "Unable to delete the sensor",
    POPUP_ERROR_DELETE_SENSOR_2: "The sensor is installed on a unit",
    POPUP_WARNING_UNINSTALL_SENSOR: "Are you sure you want to uninstall the sensor",
    POPUP_WARNING_REMOVE_MEASURE: "Are you sure you want to remove the measure",
    DEVICE_ALREADY_INSTALLED: "This device is already installed on this unit",
    NEW_STATION: "New station",
    NEW_CONTOROL_UNIT: "New control unit",
    NEW_SENSOR: "New sensor",
    NEW_MEASURE: "New measure",
    ADD_ON: "Add on",
    SELECT_UNIT: "Select a unit",
};

const CONFIGURATION_EN = createLanguageObject(EN, viewNames.CONFIGURATION_VIEW);


export default CONFIGURATION_EN;