import { viewNames } from "app/configs/routesNames";
import { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { getNationFlag, getPortTraffic, getVessels } from "app/store/evocsSlice/vessels/vesselsActions";
import VesselsTrafficGraph from "./VesselsTrafficGraph";
import VesselsTrafficTable from "./VesselsTrafficTable";
import VesselsTrafficMap from "./VesselsTrafficMap";
import LoadingGif from "../FileManager/LoadingGif";

const viewName = viewNames.VESSELS_VIEW;


const VesselsTrafficContent = () => {
  const [loading, setLoading] = useState(true);
  const [mapCenter, setMapCenter] = useState(null);
  const [vessels, setVessels] = useState();
  const [portTraffic, setPortTraffic] = useState();
  const [selectedVessel, setSelectedVessel] = useState();

  const getVesselsAndFlag = useCallback(async(startDate, endDate) => {
    const data = await getVessels(startDate, endDate, null, null, true);
    const allNationsCode = [...new Set(data.map((element) => element.mmsi.toString().slice(0, 3)))];
    const flags = await getNationFlag(allNationsCode);

    return data.map((element) => {
      const flag = flags.find((e) => String(e.nationCode) === element.mmsi.toString().slice(0, 3));
      let newElement = { ...element };
      newElement.flag = (
        <img
          src={"data:image/png;base64," + flag.icon}
          alt={"flag image"}
          width={20}
          height={20}
        />
      )
      return newElement;
    });
  }, []);
 

  useEffect(() => {
    setLoading(true);
    const endDate = new Date().valueOf();
    const startDate = endDate - 3600000*4;
    const fetchData = [
      getPortTraffic(startDate, endDate).then((res) => {
        setPortTraffic(res);
      }),
      getVesselsAndFlag(startDate, endDate).then((res) => {
        setVessels(res);
      })
    ];
    Promise.all(fetchData).then(() => setLoading(false));
  }, [])



  const handleClickRow = (row, rowIndex) => {
    setMapCenter([row.lat, row.lon]);
    setSelectedVessel(vessels.findIndex((e) => e.name === row.name));
  }


  return(
    <Box padding={{ xs: "1rem", sm: "2rem 5rem"}}>
      {!loading ? 
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <VesselsTrafficMap vessels={vessels} center={mapCenter} selectedMarker={selectedVessel}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <VesselsTrafficGraph portTraffic={portTraffic}/>
        </Grid>
         <Grid item xs={12}>
         { vessels && <VesselsTrafficTable vessels={vessels} onClickRow={handleClickRow}/>}
        </Grid>
      </Grid>
      :
      <LoadingGif />
      }
    </Box>
  )
}

export default VesselsTrafficContent;