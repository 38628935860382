import { createSlice } from "@reduxjs/toolkit";
import _ from "@lodash";

const errorSlice = createSlice({
  name: "errorSlice",
  initialState: {
    globalError: false,
  },
  reducers: {
    setGenericError: (state, action) => {
      console.log('setGenericError: ', state.globalError);
      state.globalError = true;
    },
    clearGenericError: (state) => {
      state.globalError = false;
    },
  },
});

export const { setGenericError, clearGenericError } = errorSlice.actions;
export default errorSlice.reducer;
export const errorSliceActions ={
  ...errorSlice.actions
};