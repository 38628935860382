import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const UserSelect = ({ style, users, value, defaultValue, onChange, required = false, disabled = false, multiple = false, mongo = false, limitTags }) => {
  const options = useMemo(() => {
    return users ? users.map((element) => element.id) : [];
  }, [users]);
  const [val, setValue] = useState(defaultValue ? defaultValue : multiple ? [] : null)

  useLayoutEffect(() => {
    value !== undefined && setValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange && onChange(newValue)
  };

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };


  return (
    <Autocomplete
      id="user-autocomplete"
      sx={{ ...style, width: "100%", minWidth: "100px", maxWidth: "300px" }}
      limitTags={limitTags}
      disabled={disabled}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      onChange={(_, selected) => handleChange(selected)}
      options={options}
      value={val}
      getOptionLabel={(option) => users.find((element) => element.id === option)?.email}
      renderInput={(params) => <TextField {...params} label={required ? `${handleTranslate("USERS", true)}*` : handleTranslate("USERS", true)} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && <Checkbox checked={selected} />}
          {users.find((element) => element.id === option)?.email}
        </li>
      )}
    />
  )
};

export default UserSelect;