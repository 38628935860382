import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"
import CuSelect from "./CuSelect";
import StationSelect from "./StationSelect";
import { useEffect, useState } from "react";
import { viewNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";


const SensorParentSelect = ({ stationOptions, cuOptions, defaultStation, defaultCu, defaultType, stationValue, cuValue, typeValue, onChangeStation, onChangeCu, onChangeType, disabled = false, isUserTenant = true }) => {
  const [selectedStation, setSelectedStation] = useState(defaultStation ? defaultStation : stationValue !== undefined ? stationValue : null);
  const [selectedCu, setSelectedCu] = useState(defaultCu ? defaultCu : cuValue !== undefined ? cuValue : null);
  const [selectedType, setSelectedType] = useState(defaultType ? defaultType : typeValue !== undefined && typeValue);
  const [visibleFilter, setVisibleFilter] = useState(false);

  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, viewNames.DASHBOARD_VIEW);
  };



  useEffect(() => {
    stationValue !== undefined && setSelectedStation(stationValue);
  }, [stationValue]);

  useEffect(() => {
    cuValue !== undefined && setSelectedCu(cuValue);
  }, [cuValue]);

  useEffect(() => {
    typeValue !== undefined && setSelectedType(typeValue);
  }, [typeValue]);

  useEffect(() => {
    if (selectedType) setVisibleFilter(true);
    else setVisibleFilter(false);
  }, [selectedType])

  const handleChangeSelectedStation = (selected) => {
    setSelectedStation(selected);
    onChangeStation && onChangeStation(selected);
  };

  const handleChangeSelectedCu = (selected) => {
    setSelectedCu(selected);
    onChangeCu && onChangeCu(selected);
  };

  const handleChangeSelectedType = (selected) => {
    setSelectedType(selected);
    onChangeType && onChangeType(selected);
  };


  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Grid container>
        <Grid item>
          <FormControl disabled={disabled} sx={{ mb: "1rem" }}>
            <RadioGroup value={selectedType} onChange={(e) => handleChangeSelectedType(e.target.value)}>
              <FormControlLabel value={"station"} control={<Radio />} label={handleTranslate("STATION", true)} />
              <FormControlLabel value={"cu"} control={<Radio />} label={handleTranslate("CONTROL_UNIT", true)} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center", width: "100%", maxWidth: "300px" }} item>
          {visibleFilter && (selectedType === "cu" ?
            <CuSelect isUserTenant={isUserTenant} disabled={disabled} cusId={cuOptions} value={selectedCu} onChange={(value) => handleChangeSelectedCu(value)} />
            :
            <StationSelect isUserTenant={isUserTenant} disabled={disabled} stationsId={stationOptions} value={selectedStation} onChange={(value) => handleChangeSelectedStation(value)} />
          )}
        </Grid>
      </Grid>


    </div>
  );
}

export default SensorParentSelect