import React from "react";
import {
  Modal,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { inviteUser } from "app/store/evocsSlice/user/userAction";
import LanguageSelect from "../SharingComponents/Inputs/LanguageSelect";
import TenantSelect from "../SharingComponents/Inputs/TenantSelect";
import { useSelector } from "react-redux";
import { modalsComponentNames } from 'app/configs/routesNames';
import { useTranslation } from 'react-i18next';
import { translate } from "src/utilities/utils";
import RoleSelect from "../SharingComponents/Inputs/RoleSelect";


const tilebytesTenantId = "6554a3a2af4b91cdd6da3285";

const defaultInputs = {
  tenant: {
    value: "",
    required: true
  },
  email: {
    value: "",
    required: true
  },
  username: {
    value: "",
    required: true
  },
  language: {
    value: "",
    required: true
  },
  roleId: {
    value: "",
    required: true
  },
};

const ConfigurationChip = ({ onConfirm }) => {
  const user = useSelector((state) => state.user);
  const [inputs, setInputs] = useState({
    ...defaultInputs,
    tenant: {
      value: user.selectedTenant,
      required: true
    }
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isInputsChanged, setInputsChanged] = useState(false);
  const { t } = useTranslation("evocs");
  const handleTranslate = (textId, general = false) => {
    if (general) return translate(t, textId);
    return translate(t, textId, modalsComponentNames.MODALS);
  };
  


  useEffect(() => {
    const disabled = Object.keys(inputs).some((key) => (inputs[key].required && (inputs[key].value === "" || inputs[key].value?.length === 0)));
    isInputsChanged && setButtonDisabled(disabled);
  }, [inputs])

  const handleChangeInput = (key, value) => {
    setInputs({ ...inputs, [key]: { ...inputs[key], value: value } });
    setInputsChanged(true);
  };

  const handleConfirm = () => {
    inviteUser(inputs.email.value, inputs.username.value, inputs.tenant.value, inputs.language.value, inputs.roleId.value).then((res) => {
      onConfirm && onConfirm();
    })
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem", padding: "2rem" }}>
      <h2 style={{ fontWeight: "bold" }}> {handleTranslate("NEW_USER")} </h2>
      {user.selectedTenant === tilebytesTenantId && <TenantSelect value={inputs.tenant.value} onChange={(value) => handleChangeInput("tenant", value)} />}
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={"Email"}
        required={inputs.email.required}
        className="rounded-2xl"
        value={inputs.email.value}
        onChange={(e) => handleChangeInput("email", e.target.value)}
      />
      <TextField
        sx={{ width: "100%", maxWidth: "300px" }}
        label={"Username"}
        required={inputs.username.required}
        className="rounded-2xl"
        value={inputs.username.value}
        onChange={(e) => handleChangeInput("username", e.target.value)}
      />
      <LanguageSelect required value={inputs.language.value} onChange={(value) => handleChangeInput("language", value)} />
      <RoleSelect value={inputs.roleId.value} onChange={(value) => handleChangeInput("roleId", value)} tenantId={inputs.tenant.value} />
      <Button sx={{ width: "fit-content", margin: "0 auto", border: "1px solid black" }} disabled={buttonDisabled} onClick={handleConfirm}> {handleTranslate('CONFIRM', true)} </Button>
    </div>
  );
}


const containerStyle = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  background: "#2d3740",
  padding: "1rem",
  borderRadius: "1rem",
  height: "80vh",
  width: "95%",
  maxWidth: "800px",
  flexDirection: "column"
};


const CreateUserModal = ({ isOpen = false, onClose, onConfirm }) => {

  return (
    <Modal open={isOpen}>
      <div style={containerStyle}>
        <div style={{ display: "flex" }}>
          <IconButton sx={{ marginLeft: "auto" }} onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div style={{ background: "white", borderRadius: "0 1rem 1rem 1rem", height: "100%", overflowY: "auto" }}>
          <ConfigurationChip onConfirm={onConfirm} />
        </div>
      </div>
    </Modal>
  )
};

export default CreateUserModal;
