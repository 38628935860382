import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import defaultThumb from 'src/app/asset/images/TilebytesCopertina.png';

function AxisLiveStreaming({ camera = '1' }) {
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <video
          // src={videoUrl}
          // src='/api/py/stream/getAxisCameraLiveStream?camera=1&&resolution=640x360'
          // src='http://127.0.0.1:8000/api/getAxisCameraLiveStream?camera=1'
          src='/api/py/stream/getMongoFile?fileId=6697b682c0f02e2d284cc2b6&typeFile=video'
          controls
          style={{ width: '100%', height: '100%' }}
          poster={defaultThumb}
        />
      )}
    </Box>
  );
}

export default AxisLiveStreaming;
